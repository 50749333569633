import { React, useState, useEffect } from "react";
import { useAdminContext } from "../../../context/adminContext";
import { ImageUrl } from "../../../utils/BaseUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {
  adminGetAllIngredients,
  adminApproveIngredientById,
  adminGetIngredientById,
  adminRejectIngredientById,
} from "../../../utils/ApiEndpointFunctions";
 import { getIn } from "formik";
import { debounce } from "lodash";

const AllIngredients = () => {
  const { adminDetail } = useAdminContext();
  const [allEssential, setAllEssential] = useState(null);
  const [removedata, setRemoveData] = useState(null);
  const [essentialSpin, setEssentialSpin] = useState(true);
  const [loader, setLoader] = useState(false);
  const [allIngredients, setAllIngredients] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [nutrientsState, setNutrientsState] = useState(null);
  const [miniralsState, setMiniralsState] = useState(null);
  const [vitaminsState, setVitaminsState] = useState(null);
  // const [ingredientsDetails, setIngredientsDetails] = useState({})
  // const [currentPage, setCurrentPage] = useState(1);
  let data = useLocation();
  console.log(data,"sadfasdfasdfasdfgfgbfgb")

  const navigate = useNavigate();
  const limit = 10;
  let currentPage = "";
  let filter = "approved";

  const handlePageClick = (event) => { 
    currentPage = event.selected + 1;
    setSelectedPage(event.selected + 1);
    getIngredients(currentPage);
    // searchIngredients();
  };


  const getIngredients = async (page = "1") => {
    console.log("we are in ")
    try {
      setLoader(true);
      const res = await adminGetAllIngredients(limit, page, search, filter);

      const ingrediatntsData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      // ingrediatntsData?.map((ingrediatntData)=> console.log(ingrediatntData, "kala"))
      // let tempNut = res?.data?.data?.proximates?.nutrients.filter((el)=> el.value !== 0 || "")
      // setNutrientsState(tempNut);
      setTotalCount(totalLength);
       setPage(Math.ceil(totalLength / limit));
      setAllIngredients(ingrediatntsData);
      setLoader(false);
    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  };

  // const approvedIngredient = async (id, name, status="") => {
  //   const confirm = window.confirm(
  //     `Are You Sure, You want to approve Ingredient with this Name: ${name}`
  //   );

  //   if (confirm === true) {
  //     try {
  //       setLoader(true);
  //       const res = await adminApproveIngredientById(id);

  //       setLoader(false);
  //       if (res?.status === 200 && res?.data?.status === 1) {
  //         toast.success(res?.data?.message);

  //         setLoader(false);
  //       }
  //     } catch (error) {

  //       toast.error(error.message);
  //       setLoader(false);
  //     }
  //   }
  // };

  // const rejectedIngredient = async (id, name) => {
  //   const confirm = window.confirm(
  //     `Are You Sure, You want to reject Ingredient with this Name: ${name}`
  //   );

  //   if (confirm === true) {
  //     try {
  //       setLoader(true);
  //       const res = await adminRejectIngredientById(id);
  //       // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
  //       //  setAllIngredients(res?.data?.data);
  //       setLoader(false);
  //       if (res?.status === 200 && res?.data?.status === 1) {
  //         getIngredients();
  //         toast.success(res?.data?.message);
  //         //  localStorage.setItem("token", res?.data?.token);
  //         //  navigate("/dashboard")
  //         setLoader(false);
  //       }
  //     } catch (error) {
  //       // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
  //       toast.error(error.message);
  //       setLoader(false);
  //     }
  //   }
  // };

  const debouncedgetIngredients = debounce(getIngredients, 50);
  useEffect(() => {
    if (search === "") {
 
      debouncedgetIngredients();
    }
    // const data = JSON.stringify({ x: 5, y: 6 });
    // console.log(data, "users effect data");
    // const data1 = JSON.parse(data);
    // console.log(data1, "users effect data1");
  }, [search]);

  // searchIngredients

  let search1 = "";

  const handleChange = (event) => {
    console.log(
      event.target.name,
      "name................................................//////////"
    );

    if (event.target.name === "searchField") {
      console.log(
        event.target.value,
        "value................................................//////////"
      );
      search1 = event.target.value;
      search1 = search1.split(" ").join("");
      console.log(search1, "searchField");
      setSearch(search1);
      // searchIngredients(search1)
    }
  };

  const debouncedOnChange = debounce(handleChange, 800);


  const searchIngredients = async () => {
    // console.log(search, "serch1 from search function state...........////")

    try {
      setLoader(true);
      const res = await adminGetAllIngredients(
        limit,
        selectedPage,
        search,
        filter
      );
      console.log(res?.data?.data, "search inng data......////");
      const ingrediatntsData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setAllIngredients(ingrediatntsData);
      setLoader(false);
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  const getIngredientsDetailes = async (idPass) => {
    try {
      setLoader(true);
      const res = await adminGetIngredientById(idPass);
      console.log(res?.data?.data, "dtr");
      // setIngredientsDetails(res?.data?.data);
      let tempNut = res?.data?.data?.proximates?.nutrients;
      tempNut = tempNut.filter((el) => el.value !== 0 || "");
      setNutrientsState(tempNut);

      let tempMin = res?.data?.data?.proximates?.minerals;
      tempMin = tempMin.filter((el) => el.value !== 0 || "");
      setMiniralsState(tempMin);

      let tempVit = res?.data?.data?.proximates?.vitamins;
      tempVit = tempVit.filter((el) => el.value !== 0 || "");
      setVitaminsState(tempVit);
      setLoader(false);
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  useEffect(() => {
     
    if (search) {
       
        searchIngredients();
       
    }
 
  }, [search, selectedPage]);

  return (
    <>
      <div className="card">
        <div
          className=" d-flex justify-content-end my-3"
          style={{ margin: "" }}
        >
          <input
            className="form-search me-3 "
            type="text"
            name="searchField"
            id=""
            placeholder="Search by name"
            onChange={debouncedOnChange}
          />

          {/* <div className="dropdown show">
            <div className="dropdown me-3">
              <button
                className="btn btn-second dropdown-toggle"
                style={{ padding: "11px 20px" }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Filter
              </button>
              <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                <li>
                  <Link className="dropdown-item">Locum </Link>
                </li>
                <li>
                  <Link className="dropdown-item">Locum</Link>
                </li>
              </ul>
            </div>
          </div> */}
          <Link
            className="btn btn-second"
            type="button"
            aria-expanded="false"
            to="/ingredients/approved-ingredients/add-ingredient"
          >
            Add Ingredient
          </Link>
        </div>

        
        <div className="tab-content">
          <div
            className=" tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
            tabIndex="0"
          >
            <div className="table-responsive card">
              <table className="table table-hover table align-middle  card-table table-striped-columns">
                <thead>
                  <tr>
                    <th>
                      <span className="list-sort text-muted">S.No</span>
                    </th>
                    <th>
                      <span className="list-sort text-muted">Image</span>
                    </th>
                    <th style={{ width: "18%" }}>
                      <span className="list-sort text-muted">Name</span>
                    </th>
                    <th>
                      <span className="list-sort text-muted">Created Date</span>
                    </th>
                    <th>
                      <a className="list-sort text-muted" href>
                        Status
                      </a>
                    </th>
                    <th>
                      <a className="list-sort text-muted" href>
                        Created by
                      </a>
                    </th>
                    <th>
                      <span className="list-sort text-muted">View</span>
                    </th>
                    <th style={{ textAlign: "end" }}>
                      <span className="list-sort text-muted">Action</span>
                    </th>
                  </tr>
                </thead>

                {loader ? (
                  <div style={{ marginTop: "5px", marginLeft: "700px" }}>
                    <Spinwheel />
                  </div>
                ) : (
                  <tbody>
                    {allIngredients.length > 0 ? (
                      allIngredients?.map((ingredient, index) => (
                        <tr className="">
                          <td scope="row table-hover ">{index + 1}</td>
                          <td>
                            <img
                              src={
                                ingredient?.thumbnailImage === "" ||
                                ingredient?.thumbnailImage === "null"
                                  ? "/images/recipe2.png"
                                  : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${ingredient?.thumbnailImage}`
                              }
                              alt={ingredient?.name}
                              height={35}
                              width={35}
                              className="rounded-circle me-2"
                            />
                          </td>
                          <td
                            style={{ color: "#609FFF" }}
                            onClick={() => {navigate(`/ingredients/approved-ingredients/ingredient-details/${ingredient?.id}`,{state: {filter}})}}
                          >
                            {" "}
                            <Link>{ingredient?.name}</Link>
                          </td>
                          <td>{moment(ingredient?.createdAt).format("LL")}</td>
                          {/* <td>{ingredient?.isApproved === true ?  <span style={{ color: "green", fontWeight: "600" }}>Approved</span>: ingredient?.isApproved === false ? <span style={{ color: "red", fontWeight: "600" }}>Pending</span> : ("")}</td> */}
                          <td>{ingredient?.ingredientStatus}</td>
                          <td>{ingredient?.usersDetails?.email}</td>
                          <td>
                            <button
                              className="btn btn-dark btn-sm py-1 px-2 border rounded"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              onClick={() =>
                                getIngredientsDetailes(ingredient?.id)
                              }
                            >
                              Chart
                            </button>
                          </td>
                          <td style={{textAlign: "end"}}>                            
                            <Link className="btn btn-light btn-sm py-1 px-2 border rounded" to={`/ingredients/approved-ingredients/edit-ingredient/${ingredient?.id}`}>
                              Edit
                            </Link>                                
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                      <td   align="center" colspan="6" >
                        {/* <h1>Oppss..!</h1>   No, user available to show. */}
                       <img src="/images/noData.png" style={{width:"200px"}} alt="" width="50%"/> 
                      </td>
                    </tr>
                    )}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        {totalCount > 10 ? (
          <div className="d-flex justify-content-center">
            <ReactPaginate
              nextLabel="Next >"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              marginPagesDisplayed={1}
              pageCount={page}
              previousLabel="< Previous"
              pageClassName="page-item"
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakLabel="..."
              breakClassName="page-item"
              breakLinkClassName="page-link"
              containerClassName="pagination"
              activeClassName={"active"}
              // forcePage={selectedPage}
            />
          </div>
        ) : (
          ""
        )}
        {/* Pagination  */}
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div
          className="modal-dialog  modal-dialog-centered"
          style={{ maxWidth: "440px", width: "100%" }}
        >
          <div
            className="modal-content "
            style={{ borderRadius: "0px", border: "1px solid #000" }}
          >
            <div className="modal-header-list">
              <h1
                className="modal-title-list  mb-0 py-3 "
                id="exampleModalLabel"
              >
                Nutritional Information
              </h1>
            </div>
            <div className="modal-body pb-0">
              <div className=" heading d-flex justify-content-between align-items-center">
                <h6>Nutrition</h6>
                <h6>Per 100g</h6>
              </div>
              <hr className="m-0 bottom-line" />
              {nutrientsState?.map((nutrient) => (
                <div className="row pt-3">
                  <div className="col-5">
                    <h6 className="fs-5 fw-bold">{nutrient?.name}</h6>
                  </div>
                  <div className="col-3">
                    <p className="fs-5">{nutrient?.unit}</p>
                  </div>
                  <div className="col-4">
                    <h6 className="text-center fs-5 fw-bold">
                      {nutrient?.value}
                    </h6>
                  </div>
                </div>
              ))}
              {}
              {/* <div className="row">
                <div className="col-2 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-escape"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                    <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                  </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Fat of which</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">g</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-2 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-escape"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                    <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                  </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Proteint</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">g</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div> */}
              <hr className="my-2 bottom-line" />
              <div className=" heading d-flex justify-content-between align-items-center">
                <h6>Minerals</h6>
                <h6>Per 100g</h6>
              </div>
              {/* <hr className="my-2 bottom-line" /> */}
              {miniralsState?.map((miniral) => (
                <div className="row">
                  {/* <div className="col-2 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> 
                </div> */}
                  <div className="col-5">
                    <h6 className="fs-5 fw-bold">{miniral?.name}</h6>
                  </div>
                  <div className="col-3">
                    <p className="fs-5">{miniral?.unit}</p>
                  </div>
                  <div className="col-4">
                    <h6 className="text-center fs-5 fw-bold">
                      {miniral?.value}
                    </h6>
                  </div>
                </div>
              ))}
              {/* <div className="row">
                <div className="col-2 text-center">
                 
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold"></h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">{miniral?.unit}</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">{miniral?.value}</h6>
                </div>
              </div> */}
              <hr className="my-2 bottom-line" />
              <div className=" heading d-flex justify-content-between align-items-center">
                <h6>Vitamins</h6>
                <h6>Per 100g</h6>
              </div>
              {/* <hr className="my-2 bottom-line" /> */}
              {vitaminsState?.map((vitamin) => (
                <div className="row">
                  {/* <div className="col-2 text-center">
                   <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> 
                </div> */}
                  <div className="col-5">
                    <h6 className="fs-5 fw-bold">{vitamin?.name}</h6>
                  </div>
                  <div className="col-3">
                    <p className="fs-5">{vitamin?.unit}</p>
                  </div>
                  <div className="col-4">
                    <h6 className="text-center fs-5 fw-bold">
                      {vitamin?.value}
                    </h6>
                  </div>
                </div>
              ))}
              {/* <div className="row">
                <div className="col-2 text-center">
               
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div> */}
              {/* <div className="row">
                <div className="col-2 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div> */}

            </div>
            <hr className="my-2 bottom-line" />
            <p className="p-3 text-dark m-0 card-bottom">
            % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br />
            A ratio value of 1 is used for volume to weight conversion during some analysis.<br />
            Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0.
          </p>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
    </>
  );
};

export default AllIngredients;

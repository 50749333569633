import * as Yup from 'yup'

// admin sign in
export const SignInInitaialValue = {
  email: "",
  password: "",
  checkbox: false
}

export const SignInValidationSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email format')
    .required('Email is required'),
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  // checkbox:Yup.bool().oneOf([true], 'must be checked').required()
})

// forget password

export const forgetPasswordIntial = {
  email: "",
  otp: undefined
}

export const forgetPasswordSchema = Yup.object({
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  otp: Yup.number("Otp should be number").required("Otp is required"),
})

// otp validation 

export const optIntialValue = {
  email: ""
}

export const otpValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email")
    .required("Email is required")
})

// update admin profile

export const updateAdminIntialValue = {
  profileImage: "",
  name: "",
  // role: "",
  email: "",
  phoneNumber: undefined
}

export const updateAdminProfileSchema = Yup.object({
  profileImage: Yup.string(),
  name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(25, 'Name must not exceed 25 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  // role: Yup.string().required('Required'),
  email: Yup.string().email().required('Email is required'),
  phoneNumber: Yup.string()
    .required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number')
})

// Essential number validation

export const AddEssentialIntialValue = {
  logo: "",
  name: "",
  // lastName:"",
  phone: ""
}

export const essentialValidation = Yup.object({
  logo: Yup.string(),
  name: Yup.string().required('Name is required')
    .min(3, 'Name must be at least 3 characters')
    .max(25, 'Name must not exceed 25 characters')
    .matches(/^[a-zA-Z\s]+$/, 'Name must only contain letters and spaces'),
  // lastName: Yup.string().required("Required"),
  phone: Yup.string().required('Phone number is required')
    .matches(/^\+?[1-9]\d{1,15}$/, 'Invalid phone number')
})

// user Edit Validation

export const userEditIntialValue = {
  firstName: "",
  lastName: "",
  homeAddress: "",
  workAddress: "",
  email: "",
  phoneNumber: ""
}

export const userEditValidation = Yup.object({
  firstName: Yup.string().required("Firstname is required"),
  lastName: Yup.string().required("Lastname is required"),
  homeAddress: Yup.string().required("Home Address is required"),
  workAddress: Yup.string().required("Work Address is required"),
  email: Yup.string().email().required('Email is required'),
  phoneNumber: Yup.number().required('Phone Number is required'),
})

// reset Password validation

export const resetpasswordInitial = {
  password: "",
  confirmPassword: ""
}

export const resetPasswordValidation = Yup.object({
  password: Yup.string().required("Password is required").min(8, 'Password should be minimum 8 character'),
  confirmPassword: Yup.string()
    .required('Confirm Password is required')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
})

// add Embasseies validation

export const addEmbassiesIntialValues = {
  imagelogo: "",
  myDocumentId: "",
  name: "",
  Address: ""
}

export const addEmbassiesValidation = Yup.object({
  // image: Yup.string().required("Required"),
  myDocumentId: Yup.string().required("Categore is required"),
  name: Yup.string().required("Office Name is required"),
  Address: Yup.string().required("Address is required")
})



// Calorie Fiders 

export const adminInitial = {
  email: "",
  password: ""
}

export const adminValidationSchema = Yup.object({
  email: Yup.string().email().required('Email is required'),
  password: Yup.string().required("Password is required")
  // .min(8, 'Password should be minimum 8 character'),
})


export const adminIngredientInitial = {
  name: "",
  ingredientQuantity: "100",
  ingredientUnit: "g",
  caloriePerUnit: "",
  referenceLink: [],
  referenceImages: [],
  frontImage: "",
  backImage: "",
  thumbnailImage: "",
  proximates: {},
  brandName:""
}
const URL = /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i
export const adminIngredientSchema = Yup.object({
  name: Yup.string().required('Name is required'),
  ingredientQuantity: Yup.string() ,
  ingredientUnit: Yup.string() ,
  // referenceLink: Yup.string().required('Reference Link is required').matches(URL, 'Enter a valid url'),
  // referenceLink: Yup.string().matches(URL, 'Enter a valid url'),
  caloriePerUnit: Yup.string(),
  referenceImages: Yup.array() ,
  frontImage: Yup.string() ,
  backImage: Yup.string() ,
  thumbnailImage: Yup.string() ,
  proximates: Yup.object()

})


export const createRecepieInitalValue = {
  name: "",
  note: "",
  // servingSize: {
  //   data: "",
  //   quantityUnit: "g"
  // },
  servingSize:"",
  noOfPortion:1,
  thumbnailImage: "",
  ingredientIds: [],
  isPublished: true,
}

export const createRecepieSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  // note: Yup.string().max(1000, "Notes here Max 1000 words."),
  note: Yup.string().test('maxWords', 'Notes here can have a maximum of 1000 words.', function(value){
    if (!value) {
      return true; // If the value is empty, consider it valid
    }
    const maxWords = 1000;
    const wordCount = value.split(/\s+/).length;
    return wordCount <= maxWords;
  }),

  noOfPortion: Yup.string().test('oneOfTwo', 'You must enter either no of portion or serving size', function(value) {
    const servingSize = this.parent.servingSize;
    return Boolean(value) || Boolean(servingSize);
  }),
  servingSize: Yup
   .string()
   .test('oneOfTwo', 'You must enter either no of portion or serving size', function (value) {
     const noOfPortion = this.parent.noOfPortion;
     return value || noOfPortion;
   })
   .test('minServingSize', 'Serving size cannot be smaller than 10g', function (value) {
     if (!value) return true; // Allow empty values
     const parsedValue = parseInt(value, 10);
     return !isNaN(parsedValue) && parsedValue > 9;
   }),
  // servingSize: Yup.object({
  //   data:  Yup.string().matches(/^[0-9]+$/, "Must be only digits").required("Serving Size is required"),
  //   quantityUnit: Yup.string().required("Quantity unit is required")
  // }),
  thumbnailImage: Yup.string(),
  isPublished: Yup.boolean(),


})


export const updateIngredientInitialValue = {
  id: "",
  ingredientIds: {
    id: "",
    quantity : "",
    
    unit: "g",
    notes: "",
    value:"",
    thumbnailImage:""
  }


}

export const updateIngredientSchema = Yup.object({
  id: Yup.string(),
  ingredientIds: Yup.object({
    id: Yup.string().required("Ingredeint is required"),
    value: Yup.string() ,
    quantity : Yup.string().matches(/^[0-9]+$/, "Must be only digits").required("Serving Size is required"),
    unit: Yup.string(),
    notes: Yup.string().required("Notes is required").max(500, "Notes here Max 1000 words."),
    thumbnailImage: Yup.string()
  })


})
export const updateRecipeIngredientInitialValue = {
  id: "",
  ingredientIds: {
    id: "",
    quantity : "",
    unit: "ml",
    notes: "",
    value:"",
    thumbnailImage:""
  }


}

export const updateRecipeIngredientSchema = Yup.object({
  id: Yup.string(),
  ingredientIds: Yup.object({
    id: Yup.string().required("Ingredeint is required"),
    value: Yup.string() ,
    quantity : Yup.string().required("Serving Size is required"),
    unit: Yup.string(),
    notes: Yup.string().required("Notes is required").max(500, "Notes here Max 1000 words."),
    thumbnailImage: Yup.string()
  })


})

export const profileInitialValue = Yup.object({

  firstName:"",
  lastName: "",
  profilePic: ""
})

export const IngredientSchema = Yup.object({

  firstName: Yup.string(),
  lastName: Yup.string(),
  profilePic: Yup.string()
})




export const reportInitialValue =  {

  reportType:"users",
  startDate: "",
  endDate: ""
} 
export const reportSchema = Yup.object({

  reportType: Yup.string().required("Please select report type"),
  startDate: Yup.string().required("Please select start date"),
  endDate: Yup.string().required("Please select end data")
})
import React, { useState, useEffect } from "react";
import Sidebar from "../../../Layout/Sidebar";
import Select from "react-select";

import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AddEssentialIntialValue,
  createRecepieInitalValue,
  createRecepieSchema,
  essentialValidation,
  updateIngredientInitialValue,
  updateIngredientSchema,
} from "../../common/Validation";
import { useFormik } from "formik";
import {
  addEssential,
  adminGetAllIngredients,
  adminGetIngredientById,
  createRecepieIngredient,
  downloadRecipeApi,
  uploadImage,
} from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../context/adminContext";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import { adminGetRecipeById } from "../../../utils/ApiEndpointFunctions";
import BreadCrumb from "../../common/BreadCrumb";

const AddIngredients = () => {
  // const { adminDetail, } = useAdminContext()
  // const [signInWheel, setSignInWheel] = useState(false)
  const [loader, setLoader] = useState(false);
  const [recipeDetails, setRecipeDetails] = useState({});
  const [chartDetails, setChartDetails] = useState([]);
  const [caloriePer, setCaloriePer] = useState()
  const [getAllIngredents, setGetAllIngredents] = useState();
  const [handleInputChange, setHandleInputChange] = useState("");
  const [isRecipe, setIsRecipe] = useState(true)
  const [isView, setIsView] = useState(false)
  const [borderId, setBorderId] = useState()
  const [render, setRender] = useState(true)
  const [allName, setAllName] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState({ imageName: "", fileSize: "" });
  const [downloadeLoader,setDownloadLoader] = useState(false)


  // const [selectedOption, setSelectedOption] = useState(null);
  // const [unit,setUnit] = useState('l')
  // const [quantity,setquantity] = useState('')
  // const [notes,setNotes] = useState('')

  const [finalIngredientsArray, setFinalIngredientsArray] = useState([]);

  const { id } = useParams();

  const formik = useFormik({
    initialValues: updateIngredientInitialValue,
    onSubmit: updateRecipe,
    validationSchema: updateIngredientSchema,
  });

  const updateRecipeByAdmin = async() =>{
    try {
      
      document.getElementById('close_btn').click()
      setLoader(true);
      // let ingredientIds = RecipeFormik?.values?.ingredientIds?.map(el=>JSON.stringify(el))
      const payload = {...RecipeFormik?.values,id:recipeDetails?.id,noOfPortion:RecipeFormik?.values?.noOfPortion?Number(RecipeFormik?.values?.noOfPortion):0}
      const res = await createRecepieIngredient(payload);
      if(res?.status === 200){
        toast.success("Recipe Updated Successfully");
        getRecipeDetails();
        setLoader(false)

      }
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        icon: "❗",
      });
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      setLoader(false)
      toast.error(error.message);
      setLoader(false);
    }
  }

  const RecipeFormik = useFormik({
    initialValues: {
      name: "",
      note: "",
      id: "",
      isPublished: false,
      thumbnailImage: "",
      servingSize: "",
      ingredientIds: [],
      noOfPortion:""
    },
    onSubmit: updateRecipeByAdmin,
    validationSchema: createRecepieSchema

  })

  const handleChange = (e) => {
    const {name,value} = e.target;
    console.log(name,value,'name value')
    if(name === 'noOfPortion'){
      RecipeFormik.setFieldValue('noOfPortion', value);
      RecipeFormik.setFieldValue('servingSize', '');
    }else{
      RecipeFormik.setFieldValue('noOfPortion', '');
      RecipeFormik.setFieldValue('servingSize', value);
    }
  };

  const handleIngredientChart = async (id, p) => {

    let d = document.getElementById(`${borderId}`)?.removeAttribute("class");
    document.getElementById(`${borderId}`)?.setAttribute("class", `patato-mash mt-3 food-div`);

    setBorderId(p)
    document
      .getElementById(p)
      .setAttribute("class", ` patato-mash mt-3 borderCard food-div`);
    try {
      const res = await adminGetIngredientById(id);
      if (res.status === 200 || res.status === 201) {
        setIsRecipe(false)
        // setIsView(true);
        setCaloriePer(res?.data?.data?.caloriePerServing)
        // console.log(res?.data?.data?.proximates , "responseeeee")
        setChartDetails(res.data.data.proximates);
      }
    } catch (err) {
      console.log(err)
    }
  }

  // console.log(isView ,"DDJDJJDDJDDJD")
  const getRecipeDetails = async () => {
    try {
      setLoader(true);
      const res = await adminGetRecipeById(id);

      setRecipeDetails(res?.data?.data);
      setChartDetails(res?.data?.data?.proximatesDetails)
      setCaloriePer(res?.data?.data?.caloriePerServing)
      // formik.setFieldValue("name",res?.data?.data?.name)
      // console.log(res.data.data?.ingredientDetails,"12121212")
      // formik.setFieldValue("note",res?.data?.data?.note)
      setFinalIngredientsArray(res.data.data?.ingredientDetails);
      setLoader(false);
    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  };

  const handleremoveIngredient = (id) => {
    // console.log(id,"didididididi")
    const isDelete = window.confirm("Are You Sure ?")
    if(!isDelete) return;
    finalIngredientsArray?.splice(id, 1);
    updateRecipeByAdmin()
    // console.log(finalIngredientsArray,"arryaayy",id)
    setRender(!render)
    setIsView(true)
  }
  // console.log(recipeDetails,"resiiipiiii")
  const getAllIngredients = async () => {
    const res = await adminGetAllIngredients(
      100,
      1,
      handleInputChange,
      "approved"
    );

    const options = res.data?.data.map((item) => ({
      value: item.name,
      label: item.name,
      id: item.id,
    }));

    setGetAllIngredents(options);
  };

  useEffect(() => {
    getAllIngredients();
  }, [handleInputChange]);

  useEffect(() => {
    getRecipeDetails();
  }, []);

  useEffect(()=>{
    RecipeFormik.setFieldValue("servingSize",recipeDetails?.servingSize)
    RecipeFormik.setFieldValue("noOfPortion",recipeDetails?.noOfPortion)
    RecipeFormik.setFieldValue("note",recipeDetails?.note)
    RecipeFormik.setFieldValue("name",recipeDetails?.name)
    RecipeFormik.setFieldValue("thumbnailImage",recipeDetails?.thumbnailImage)
    RecipeFormik.setFieldValue("ingredientIds",recipeDetails?.ingredientDetails)
  },[recipeDetails])

  const [isBlockVisible, setBlockVisible] = useState(false);

  const toggleBlockVisibility = (e) => {
    e.preventDefault()
    setIsRecipe(true)
    // setBlockVisible(!isBlockVisible);
    // getRecipeDetails();
    setChartDetails(recipeDetails?.proximatesDetails)
  };

  // console.log(formik);
  function updateRecipe() {
    setIsView(true)
    setFinalIngredientsArray([
      ...finalIngredientsArray,
      {
        id: formik.values.ingredientIds.id,
        quantity: formik.values.ingredientIds.quantity,
        quantityUnit: formik.values.ingredientIds.unit,
        notes: formik.values.ingredientIds.notes,
        name: formik.values.ingredientIds.value,
        image: formik.values.ingredientIds.thumbnailImage,
      },
    ]);

    let docs = document.getElementById("addIngredientModal");
    docs.click();
    formik.setFieldValue("ingredientIds.id", "");

    formik.setFieldValue("ingredientIds.value", "");
    formik.setFieldValue("ingredientIds.quantity", "");
    formik.setFieldValue("ingredientIds.unit", "g");
    formik.setFieldValue("ingredientIds.notes", "");
  }

  const updateIngredient = async () => {
    const res = await createRecepieIngredient({
      id,
      ingredientIds: finalIngredientsArray,
    });
    if (res.status == 200) {
      alert("Ingredient added successfully");

      getRecipeDetails()
      setIsView(false)
    }
  };

  const checkIngredients = () => {
    let d = Boolean(recipeDetails?.proximatesDetails?.nutrients?.length) || Boolean(recipeDetails?.proximatesDetails?.vitamins?.length) || Boolean(recipeDetails?.proximatesDetails?.minerals?.length)
    //  console.log(d,"DKDKDKKDDKKDKDKDD") 
    return d;
  }

  const selectHandleChange = (e) => {
    // console.log(e);
    formik.setFieldValue("ingredientIds.id", e.id);
    formik.setFieldValue("ingredientIds.value", e.value);
  };
  // console.log(finalIngredientsArray,"finalueaarr")
  const onFileChange = async (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    setImage((e) => {
      return { ...e, imageName: file.name };
    });
    const type = file.type.split("/")[1];
    const imageType = ["jpeg", "jpg", "png"];
    const validImageType = imageType.includes(type);
    if (!validImageType) {
      alert("upload a valid image : jpeg, png, jpg");
      return;
    } else {
      if (file.size > 1000001) {
        alert("image size is more than 1 MB");

        return;
      } else {
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("type", "recipe");
        const response = await uploadImage("/users/fileUpload", formdata);
        const data = response.data;
        RecipeFormik.setFieldValue("thumbnailImage", data.data);
        setImage((e) => {
          return { ...e, fileSize: data.fileSize };
        });
        // //to set the banner image in the next div
        // let fr = new FileReader();
        // fr.readAsDataURL(file);
        // fr.onload = function () {
        //   setBannerData(fr.result);
        // };
      }
    }
  };

  const handleDownloadRecipeChart = async() =>{
      if(downloadeLoader) return;
  
      setDownloadLoader(true);
      try {
        const res = await downloadRecipeApi(id, "");
        if (res.status === 200 || res.status === 201) {
          setDownloadLoader(false);
          window.open(`${ImageUrl + "/" + res.data.data}`, "_blank");
        }
      } catch (err) {
        setDownloadLoader(false)
      }
  
  }

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        {/* HEADER */}

        {/* / .header */}
        {/* CARDS */}
        <div className="container-fluid  mb-5">
          <div className="row">
            <BreadCrumb status={true} />
            {/* <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/recipes">
                    <i className="fe fe-home"></i> Recipes
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link
                    to={`/recipes/details/${id}`}
                    className="breadcrumb-item active"
                  >
                    Recipe Details
                  </Link>
                </li>
                
              </ol>
            </nav> */}
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="report  m-0">Recipe Information</h2>
              {/* <div>
                                <button type='button' className='btn btn-second me-3' style={{ padding: "10px 15px" }} onClick={() => { navigate("/addessential") }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-pencil-square  text-white" viewBox="0 0 16 16">
                                        <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                                        <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                                    </svg>
                                </button>
                                <button type="submit" className="btn-custom w-auto mt-2">APPROVED</button>
                                <button type="submit" className="btn-custom-ops w-auto mt-2 bg-transparent ms-3">REJECT</button>
                            </div> */}
            </div>
            <hr style={{ marginTop: "15px" }} />
            {loader ? (
              <div style={{ marginTop: "5px", marginLeft: "700px" }}>
                <Spinwheel />
              </div>
            ) : (
              <div className="row">
                <div className="col-xl-5">
                  <input
                    type="file"
                    name="myImage"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    value=""
                  />
                  {/* <input
                        className="form-control"
                        
                        id="name"
                        type="text"
                        name="name"
                        value={formik?.values?.name}
                        onChange={formik.handleChange}
                        placeholder="Breakfast Recipe 1"
                      /> */}
                  {/* <span > */}
                  <div className="d-flex align-items-center mb-3">
                    <p className="m-0 me-3">Name: {recipeDetails?.name}</p>
                    {recipeDetails?.isPublished ? <span className="badge rounded-pill" style={{ background: "#91c788" }}><b>Public</b></span> : ""}
                    {!recipeDetails?.userId && <button className="btn btn-danger ms-4 btn-sm"  data-bs-toggle="modal" data-bs-target="#updateRecipe"> Edit</button>}
                    {recipeDetails?.isPublished ? "" : <span className="badge rounded-pill bg-light"><b>Private</b></span>}
                    {recipeDetails?.isShared ? <span className="badge rounded-pill bg-primary"><b>Community</b></span> : ""}
                    {/* </span> */}
                  </div>

                  <p >Created by :  {recipeDetails?.usersDetails?.userName || recipeDetails?.usersDetails?.email}</p>
                 { recipeDetails?.servingSize ?<p >Portion size : {recipeDetails?.servingSize ? recipeDetails?.servingSize + " g": ''} </p>
                 : <p >No of Portion : {recipeDetails?.noOfPortion} </p>}
                  {/* <p >
                    <span>Note : </span> <span>{recipeDetails?.note}</span>
                  </p> */}
                     {recipeDetails?.note && 
                      //  <p className="p-14 mb-0 pe-5 ">
                      //      <td>
                      //     Note :
                      //     </td>{" "}
                      //     <td>
                      //     <p className="ms-2" dangerouslySetInnerHTML={{ __html: recipeDetails?.note.replace(/\n/g, '<br />') }} />
                      //     </td>
                      //   </p>
                      <div className="mb-3 fw-semibold d-flex " style={{ color: "#212529" }}>
            
                      <span style={{width:"8%"}}> Note : </span> 
                       
                   <span style={{width:"92%"}} dangerouslySetInnerHTML={{ __html:recipeDetails?.note.replace(/\n/g, '<br />') }} />
                     
                   </div>
                        }
                  <img
                    // src="/images/recipe2.png"
                    // src={`https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${recipeDetails?.thumbnailImage}`}

                    src={
                      recipeDetails?.thumbnailImage === "" ||
                        recipeDetails?.thumbnailImage === "null" ||
                        recipeDetails?.thumbnailImage ===
                        "https://www.linkedin.com/"
                        ? "/images/recipe2.png"
                        : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${recipeDetails?.thumbnailImage}`
                    }
                    alt=""
                    width="320"
                    height="160"
                    className="img-thumbnail d-block mb-2"
                  />
                  <small>
                    You can search and 'add ingredients ' to your meal from the
                    section given below
                  </small>
                  {/* Added Ingredients list  */}
                  {/* Added Ingredients list  */}
                  {/* {console.log(finalIngredientsArray,"finalIngredientsArrayfinalIngredientsArray")} */}
                  {finalIngredientsArray?.map((item, index) => (
                    <div className="patato-mash mt-3 " id={`card${index}`} onClick={() => handleIngredientChart(item.id, `card${index}`)}>

                      <div className="d-flex mt-3 mb-2 align-items-center" >
                        <div>
                          <img
                            src={`${(!item.thumbnailImage || item.thumbnailImage == "null") ?  "/images/recipe2.png" : encodeURI(`${ImageUrl}` +"/"+ item.thumbnailImage)}`}
                            alt=""
                            width="30"
                            height="30"
                            className="rounded-circle me-3"
                          />
                        </div>
                        <div className="d-flex w-100 justify-content-between">
                          <h3 className="mb-0">{item.name} <span className="ms-3" style={{ fontSize: "14px" }}>{item.quantity + " g"}</span></h3>

                          <h3 onClick={() => handleremoveIngredient(index)}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                            <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                          </svg>
                          </h3>
                        </div>
                      </div>
                      {item?.notes && <div className="mb-3 fw-semibold d-flex " style={{ color: "#212529" }}>

                        <span >Note: &nbsp; </span>

                        <span style={{ width: "90%" }} dangerouslySetInnerHTML={{ __html: item?.notes.replace(/\n/g, '<br />') }} />

                      </div>}
                      {/* <p className="mb-0">{item.notes}</p> */}
                    </div>
                  ))}
                  {/* end */}
                  <div className="d-flex flex-wrap gap-3 mt-4 mb-4">
                    {
                      checkIngredients() && <>   <button
                        type="button"
                        className="btn btn-second"
                        onClick={toggleBlockVisibility}
                      >
                        View Chart
                      </button>

                      </>
                    }
                  {RecipeFormik?.values?.ingredientIds?.length ?<div
                        className="btn btn-second"
                        onClick={handleDownloadRecipeChart}
                        disabled={downloadeLoader}
                      >
                        {downloadeLoader?  
                        <div style={{ marginRight: "5px"}}> <Spinwheel color="black !important" /></div> 
                        :"Download Chart " 
                      }
                      </div>:null}
                    <button
                      className="btn btn-second"
                      data-bs-toggle="modal"
                      data-bs-target="#addIngredientModal"
                    >
                    Add ingredient
                    </button>

                    {
                      isView && <div
                        className="btn btn-second"
                        onClick={updateIngredient}
                      >
                        Continue
                      </div>

                    }

                      

                  </div>
                </div>
                <div className="col-xl-1"></div>
                {
                  checkIngredients() || isView ? 
                  <div className="col-xl-6 pt-3">
                    <div
                      style={{
                        padding: "5px",
                        boxShadow: "0px 3px 6px #00000029",
                        display: "block",
                        borderRadius: "12px 12px 0 0",
                        border: "1px solid #000",
                        maxWidth: "600px",
                        width: "100%"
                      }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th colSpan={6} style={{ backgroundColor: "#000" }}>
                              <h1
                                style={{
                                  color: "#fff",
                                  textAlign: "center",
                                  paddingTop: "15px",
                                }}
                              >
                                Nutritional Information
                              </h1>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{ height: "10px" }}></td>
                          </tr>
                          {/* <tr>
                          <td colspan="3">
                            <strong>Nutrients</strong>
                          </td>
                          <td colspan="3" align="right">
                            <strong>(199g)</strong>
                          </td>
                        </tr> */}
                          {/* <tr>
                          <td colSpan={6}>
                            <hr style={{ border: "6px solid #000" }} />
                          </td>
                        </tr> */}
                          <tr>
                            <td colSpan={3}>
                              <h3 style={{ margin: "0 auto" }}>
                                Calories per serving
                              </h3>
                            </td>
                            <td colSpan={3}>
                              <h1
                                style={{
                                  margin: "0 auto",
                                  textAlign: "end",
                                  paddingRight: "10px",
                                }}
                              >
                                {caloriePer}
                              </h1>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6}>
                              <hr className="bottom-line" />
                            </td>
                          </tr>
                          <tr>
                            <td style={{ width: "22%" }}>
                              <b>Nutrients</b>
                            </td>
                            <td> <b> Unit </b></td>
                            <td>
                              <b>per 100g</b>
                            </td>

                            {
                              isRecipe && <>
                                <td>
                                  <b>per {recipeDetails?.totalQuantity}</b>
                                </td>
                                <td>
                                  <b>serving</b>
                                </td>
                                <td align="center">
                                  <b>NRV</b>
                                </td>
                              </>
                            }

                          </tr>
                          <tr>
                            <td colSpan={6}>
                              <hr className="bottom-line" />
                            </td>
                          </tr>
                          {Boolean(
                            chartDetails?.nutrients?.length
                          ) &&
                            chartDetails?.nutrients
                              ?.filter((l) => l.value !== 0 || l.value !== null)
                              ?.map((v) => {
                                return (
                                  <tr>
                                    <td>
                                      {v.name}
                                    </td>
                                    <td>{v.unit}</td>
                                    <td>  {v?.hundredValue ? v?.hundredValue?.toString()?.split(".")?.length > 1 ? Number(v?.hundredValue)?.toFixed(2) : v?.hundredValue : v?.value?.toString()?.split(".")?.length > 1 ? Number(v?.value)?.toFixed(2) : v?.value}</td>

                                    {
                                      isRecipe && <>

                                        <td>{v?.value?.toString()?.split(".")?.length > 1 ? Number(v.value)?.toFixed(2) : v?.value}</td>
                                        <td> {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}</td>
                                        <td>{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>
                                      </>
                                    }
                                  </tr>
                                );
                              })}

                          {Boolean(
                            chartDetails?.vitamins?.length
                          ) &&
                            chartDetails?.vitamins
                              ?.filter((l) => l.value !== 0 || l.value !== null)
                              ?.map((v) => {
                                return (
                                  <tr>
                                    <td>
                                      {v.name}
                                    </td>
                                    <td>{v.unit}</td>
                                    <td>  {v?.hundredValue ? v?.hundredValue?.toString()?.split(".")?.length > 1 ? Number(v?.hundredValue)?.toFixed(2) : v?.hundredValue : v?.value?.toString()?.split(".")?.length > 1 ? Number(v?.value)?.toFixed(2) : v?.value}</td>

                                    {
                                      isRecipe && <>

                                        <td>{v?.value?.toString()?.split(".")?.length > 1 ? Number(v.value)?.toFixed(2) : v?.value}</td>
                                        <td> {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}</td>
                                        <td>{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>
                                      </>
                                    }
                                  </tr>
                                );
                              })}
                          {Boolean(
                            chartDetails?.minerals?.length
                          ) &&
                            chartDetails?.minerals
                              ?.filter((l) => l.value !== 0 || l.value !== null)
                              ?.map((v) => {
                                return (
                                  <tr>
                                    <td>
                                      {v.name}
                                    </td>
                                    <td>{v.unit}</td>
                                    <td>  {v?.hundredValue ? v?.hundredValue?.toString()?.split(".")?.length > 1 ? Number(v?.hundredValue)?.toFixed(2) : v?.hundredValue : v?.value?.toString()?.split(".")?.length > 1 ? Number(v?.value)?.toFixed(2) : v?.value}</td>

                                    {
                                      isRecipe && <>

                                        <td>{v?.value?.toString()?.split(".")?.length > 1 ? Number(v.value)?.toFixed(2) : v?.value}</td>
                                        <td> {v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}</td>
                                        <td>{v?.nrvValue > 0 ? v?.nrvValue : ""}</td>
                                      </>
                                    }
                                  </tr>
                                );
                              })}
                          {/* <tr>
                            <td />
                            <td>Kcal</td>
                            <td>71</td>
                            <td>142</td>
                            <td>7%</td>
                            <td>2000</td>
                          </tr> */}
                          <tr>
                            <td colSpan={6}>
                              <hr className="bottom-line" />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={6}>
                              <small>
                              {/* <p
                  className=" text-dark m-0 card-bottom p-10 pt-3 p-1"
                  style={{ borderTop: "3px solid black" }}
                > */}
                  % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br/>
                  A ratio value of 1 is used for volume to weight conversion during some analysis.<br/>
                  Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0.
                {/* </p> */}
                              </small>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                    : ""}

                {/* <div className="col-xl-4" style={{ paddingTop: "1.2rem" }}>
                  <div className="patato-mash-report">
                    <div className="pb-3 pt-5 px-4">
                      <div className="d-flex  mb-2 align-items-center">
                        <div>
                          <img
                            src="/images/break.jpg"
                            alt=""
                            width="30"
                            height="30"
                            className="rounded-circle me-3"
                          />
                        </div>
                        <div>
                          <h3 className="mb-0">Potato Mash with Butter </h3>
                        </div>
                      </div>
                      <p className="mb-0">
                        Note : Lorem ipsum dolor sit amet, consectetur
                        adipiscing elit, sed do
                      </p>
                    </div>
                    <div className="card-body p-0">
                      <div className="modal-header-list">
                        <h1
                          className="modal-title-list  mb-0 py-3 "
                          id="exampleModalLabel"
                        >
                          Nutritional Information
                        </h1>
                      </div>
                      <div className=" p-2">
                        <div className=" heading d-flex justify-content-around align-items-center">
                          <h4>Nutrition</h4>
                          <h4>Per 100g</h4>
                        </div>
                        <hr className="m-0 bottom-line" />
                        <div className="row pt-3">
                          <div className="col-2 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-escape"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                              <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                            </svg>
                          </div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">KJ</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-escape"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                              <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                            </svg>
                          </div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">
                              Fat of which
                            </h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">g</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 text-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              className="bi bi-escape"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                              <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                            </svg>
                          </div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">
                              Proteint
                            </h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">g</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <hr className="my-2 bottom-line" />
                        <div className="row">
                          <div className="col-2 text-center"></div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">Kcal</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 text-center"></div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">g</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <hr className="my-2 bottom-line" />
                        <div className="row">
                          <div className="col-2 text-center"></div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">Kcal</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 text-center"></div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">KJ</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 text-center"></div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">KJ</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-2 text-center"></div>
                          <div className="col-3">
                            <h6 className="text-center fs-5 fw-bold">Energy</h6>
                          </div>
                          <div className="col-2">
                            <p className="fs-5">KJ</p>
                          </div>
                          <div className="col-5">
                            <h6 className="text-center fs-5 fw-bold">295</h6>
                          </div>
                        </div>
                        <hr className="my-2 bottom-line" />
                        <p className=" text-dark m-0 card-bottom">
                          * Present daily values are based on a 2,000 calorie
                          diet. Your daily values may be higher or lower
                          depanding on your Energy
                        </p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            )}
          </div>
        </div>
        {/* <button className="btn btn-second mt-4 mx-4" data-bs-toggle="modal" data-bs-target="#addIngredientModal"  >Add ingrident</button> */}
      </div>

      {/* modal */}
      {
        <div
          className="modal fade"
          id="addIngredientModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
        >
          <form>
            <div
              className="modal-dialog modal-dialog-centered "
              style={{ width: "390px" }}
            >
              <div className="modal-content">
                <div>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px" }}></button>
                </div>
                <div className="modal-body">
                  <h4 className="text-center">Add Ingredients </h4>
                  {/* <div className="d-flex justify-content-between">
                      <label className="upload">
                        <span> Upload Recipe image Here</span>
                        <input
                          type="file"
                          name="myImage"
                          accept="image/png, image/gif, image/jpeg"
                          style={{ display: "none" }}
                          onChange={onFileChange}
                        />
                      </label>
                    </div> */}
                  {/* <div className="d-flex justify-content-between after-upload-img mt-2">
                      <div className="me-3">



                        <img
                          src={`${encodeURI(
                            `${ImageUrl}` + formik.values.ingredientIds.thumbnailImage
                          )}`}
                          width="44"
                          height="44"
                          alt=""
                          className="rounded"
                        />
                      </div>
                      <div style={{ textAlign: "left", width: "100%" }}>
                        <h4 className="mb-0">{image?.imageName || "Image Name Here"}</h4>
                        <h5>{image?.fileSize || "Image size"}</h5>
                      </div>
                      <div>
                        <a href="">X</a>
                      </div>
                    </div> */}
                  <div className="position-relative mt-4">
                    <Select
                      className="form-control"
                      id="select-ingredent"
                      placeholder="Ingredeints "
                      isSearchable={true}
                      defaultValue={"a"}
                      name="ingredientIds.id"
                      value={{
                        label: formik.values?.ingredientIds?.value,
                        value: formik.values?.ingredientIds?.value,
                      }}
                      onChange={(e) => selectHandleChange(e)}
                      onInputChange={setHandleInputChange}
                      options={getAllIngredents}
                    />

                    {/* <select
                    className="form-control"
                    type="text"
                    name="name"
                    // value={modalData.id}
                    // onChange={handleIngredeintSelect}
                    // value={createRecepieFormik?.values.name}

                    id="name"
                    placeholder="Breakfast Recipe 1"
                  > */}
                    {/* <option value="" hidden>
                      -
                    </option> */}
                    {/* {ingredientData?.map((v) => {
                      return <option value={v.id}>{v.name}</option>;
                    })} */}
                    {/* </select> */}
                    <label htmlFor="name" className="input-label">
                      Select Ingredient
                    </label>

                    {formik.touched.ingredientIds?.id &&
                      formik.errors.ingredientIds?.id ? (
                      <div className="req-error">
                        {formik.errors.ingredientIds?.id}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  <div className="row mt-4">
                    <div className="col-5">
                      <input
                        className="form-control"
                        type="text"
                        name="ingredientIds.unit"
                        id=""
                        // onChange={(e)=>{
                        //   formik.setFieldValue("servingSize.quantityUnit",e.target.value)
                        // }} 
                        disabled
                        placeholder="g"
                      />
                      {/* <select
                        className="form-select"
                        value={formik.values.ingredientIds.unit}
                        onChange={formik.handleChange}
                        name="ingredientIds.unit"
                        id=""
                      >
                        <option value="ml">ml</option>
                        <option value="g">g</option>
                      </select> */}
                    </div>
                    <div className="col-7">
                      <input
                        className="form-control"
                        type="text"
                        name="ingredientIds.quantity"
                        value={formik.values.ingredientIds.quantity}
                        onKeyPress={(event) => {
                          if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        onChange={formik.handleChange}
                        // onChange={(e) =>
                        //   setModalData({ ...modalData, quantity: e.target.value })
                        // }
                        //   onChange={(e) => {
                        //     handleChange(e);
                        //   }}
                        //   value={createRecepieFormik.values.servingSize.data || ""}
                        id=""
                        placeholder="Serving size"
                      />
                      {formik.touched.ingredientIds?.quantity &&
                        formik.errors.ingredientIds?.quantity ? (
                        <div className="req-error">
                          {formik.errors.ingredientIds?.quantity}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <label className="form-label mt-3" htmlFor="add">
                    Add recipe note.
                  </label>
                  <textarea
                    className="form-control"
                    name="ingredientIds.notes"
                    value={formik.values.ingredientIds.notes}
                    onChange={formik.handleChange}
                    //   onChange={(e) => {
                    //     globalHandleChange(e, createRecepieFormik);
                    //   }}
                    //   value={createRecepieFormik.values.note || ""}
                    // value={modalData.notes}
                    // onChange={(e) => setModalData({ ...modalData, notes: e.target.value })}
                    // onChange={handleNote}
                    id=""
                    cols="30"
                    rows="3"
                    placeholder="Enter Notes here Max 500 words."
                  ></textarea>
                  {formik.touched.ingredientIds?.notes &&
                    formik.errors.ingredientIds?.notes ? (
                    <div className="req-error">
                      {formik.errors.ingredientIds?.notes}
                    </div>
                  ) : (
                    ""
                  )}
                  <div className="text-center">
                    <button
                      className="btn_custome mt-4"
                      type="button"
                      onClick={formik.handleSubmit}
                    >
                      Add Ingredient
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      } 
      <div
          className="modal fade"
          id="updateRecipe"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="false"
        >
        <form onSubmit={RecipeFormik.handleSubmit}>
            <div className="modal-dialog newrecipe">
                  <div className="modal-content">
                  <div>
                  <button type="button" id="close_btn" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ fontSize: "20px" }}></button>
                </div>
                    <div className="modal-body">
                      <div className="text-center">
                        <img src="/images/NoPath@2x.png" width="45" alt="" />
                      </div>
                      <h4 className="text-center">Update recipe</h4>
                      <div className="d-flex justify-content-between">
                        <label className="upload">
                          <span style={{ cursor: "pointer" }}> Upload Recipe image Here</span>
                          <input
                            type="file"
                            name="myImage"
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                            onChange={onFileChange}
                          />
                        </label>
                      </div>
                      <div className="d-flex justify-content-between after-upload-img mt-2">
                        <div className="me-3">



                          <img
                            src={`${RecipeFormik.values.thumbnailImage ? encodeURI(`${ImageUrl}` +"/"+ RecipeFormik.values.thumbnailImage) : "/images/recipe2.png"}`}
                            width="44"
                            height="44"
                            alt=""
                            className="rounded"
                          />
                        </div>
                        <div style={{ textAlign: "left", width: "100%" }}>
                          <h4 className="mb-0">{image?.imageName || "Image Name Here"}</h4>
                          <h5>{image?.fileSize || "Image size"}</h5>
                        </div>
                        {/* <div>
                          <a href="">X</a>
                        </div> */}
                      </div>
                      <div className="input-lab-top mt-4">
                        <input
                          className="form-control"

                          id="name"
                          type="text"
                          name="name"
                          value={RecipeFormik?.values?.name}
                          onChange={RecipeFormik.handleChange}
                          placeholder="Breakfast Recipe 1"
                        />
                        <label htmlFor="name" className="input-label">
                          Enter Recipe Name
                        </label>
                        {RecipeFormik.touched.name &&
                          RecipeFormik.errors.name ? (
                          <div className="req-error">
                            {RecipeFormik.errors.name}
                          </div>
                        ) : null}

                      </div>

                      <div className="row mt-4 align-items-end justify-content-around ">
                        <div className="col-4">
                          <label class="form-label mt-3" for="add">No. of Portion</label>
                          <input class="form-control px-2" name="noOfPortion" type="text" value={RecipeFormik?.values?.noOfPortion || ""} id="" placeholder="Portion No." onChange={handleChange}></input>
{/*     
                          {RecipeFormik.touched.servingSize
                            ?
                             (
                            <div className="req-error">
                              {RecipeFormik.errors.servingSize}
                            </div>
                          ) : null} */}
                        </div>
                       <div className="col-auto pb-3"> or</div>
                        <div className="col-4">
                          <label class="form-label mt-3" for="add">Portion Size</label>
                          <input class="form-control px-2" name="servingSize" type="text" value={RecipeFormik?.values?.servingSize}  id="" placeholder="Add Weight"  onChange={handleChange}/>
                         
                        </div>
                        <div class="col-2 d-flex align-items-end"><span class="fw-bolder pb-3">grams</span></div>
                       {Boolean((RecipeFormik.touched.servingSize && RecipeFormik.errors.servingSize) || (RecipeFormik.touched.noOfPortion && RecipeFormik.errors.noOfPortion)) && <div className="req-error">
                              {RecipeFormik.errors.servingSize || RecipeFormik.errors.noOfPortion}
                        </div>}
                      </div>
                      <p className="form-label mt-3" htmlFor="add">
                        Add recipe note.
                      </p>
                      <div>
                        <textarea
                          className="form-control"
                          placeholder="Enter Notes here Max 1000 words."
                          name="note"
                          id=""
                          value={RecipeFormik.values.note}
                          onChange={RecipeFormik.handleChange}
                          cols="30"
                          rows="3"
                        >

                        </textarea>
                        {/* <div className="pt-2"><input type="checkbox" name="" id="" value="false" /> Share this recipe publicly on The CalorieFinder</div> */}
                        {RecipeFormik.touched.note &&
                          RecipeFormik.errors.note ? (
                          <div className="req-error">
                            {RecipeFormik.errors.note}
                          </div>
                        ) : null}
                      </div>


                      <div className="text-center" >
                        <button className="btn_custome mt-4" type='submit'>
                          Update Recipe
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
                </form>
                </div>
    </>
  );
};

export default AddIngredients;

import React, { useState, useEffect } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AddEssentialIntialValue,
  essentialValidation,
} from "../../common/Validation";
import { useFormik } from "formik";
import {
  addEssential,
  adminApproveIngredientById,
  adminRejectIngredientById,
  uploadImage,
} from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../context/adminContext";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import { adminGetIngredientById } from "../../../utils/ApiEndpointFunctions";
import { useLocation } from "react-router-dom";
import BreadCrumb from "../../common/BreadCrumb";
 
export default function IngredientDetails() {
  // const { adminDetail, } = useAdminContext()
  // const [signInWheel, setSignInWheel] = useState(false)
  const [loader, setLoader] = useState(false);
  const [ingredientDetails, setIngredientsDetails] = useState({});
  const [referenceLinkData,setReferenceLinkData] = useState([])

  let { state } = useLocation();
  const navigate = useNavigate();
  let location = useLocation()
  let path = location.pathname

  const { id } = useParams();

  console.log(referenceLinkData, "111111111111");
  const getIngredientDetails = async () => {
    try {
      setLoader(true);
      const res = await adminGetIngredientById(id);

      // console.log(location.state,"asdfasfdasdfasdf")
      setIngredientsDetails(res?.data?.data);
     console.log(typeof res?.data?.data.referenceLink,"22222222222")
     if(
      typeof res?.data?.data.referenceLink !== 'object'
     ){
      setReferenceLinkData([res?.data?.data.referenceLink])

    }else{
       setReferenceLinkData(res?.data?.data.referenceLink)

     }
      setLoader(false);
    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  };
  const approvedIngredient = async (id) => {
    const confirm = window.confirm(
      `Are You Sure, You want to approve Ingredient `
    );

    if (confirm === true) {
      try {
        setLoader(true);
        const res = await adminApproveIngredientById(id);
        // getPendingIngredients();
        // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
        //  setAllIngredients(res?.data?.data);
        setLoader(false);
        if (res?.status === 200 && res?.data?.status === 1) {
          toast.success(res?.data?.message);
          //  localStorage.setItem("token", res?.data?.token);
          //  navigate("/dashboard")
          setLoader(false);
        }
      } catch (error) {
        // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
        toast.error(error.message);
        setLoader(false);
      }
    }
  };
  const rejectedIngredient = async (id, name) => {
    const confirm = window.confirm(
      `Are You Sure, You want to reject Ingredient `
    );

    if (confirm === true) {
      try {
        setLoader(true);
        const res = await adminRejectIngredientById(id);
        // getPendingIngredients();
        // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
        //  setAllIngredients(res?.data?.data);
        setLoader(false);
        if (res?.status === 200 && res?.data?.status === 1) {
          toast.success(res?.data?.message);
          //  localStorage.setItem("token", res?.data?.token);
          //  navigate("/dashboard")
          setLoader(false);
        }
      } catch (error) {
        // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
        toast.error(error.message);
        setLoader(false);
      }
    }
  };

  useEffect(() => {
    getIngredientDetails();

    // const data = JSON.stringify({ x: 5, y: 6 });
    // console.log(data, "users effect data");
    // const data1 = JSON.parse(data);
    // console.log(data1, "users effect data1");
  }, []);

  return (
    <>
      {/* <Sidebar /> */}

      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        
        {/* <div className="header bg-white">
          <div className="container-fluid">
             
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  
                  <h6 className="header-pretitle">Overview</h6>
                   
                  <h1 className="header-title">Ingredient Details</h1>
                </div>
                <div className="col-auto">
                   
                  <div className="dropdown">
                    <button
                      className="btn btn-second dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="/images/person-circle.svg"
                        alt=""
                        height={24}
                        width={24}
                        className="rounded-circle me-2"
                      />
                    </button>
                    <ul className="dropdown-menu text-white">
                      <li>
                        <Link className="dropdown-item" to={"/profiledetails"}>
                          Profile
                        </Link>
                      </li> */}
                      {/* <li>
                                                <a className="dropdown-item" href="#">
                                                    Another action
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    Something else here
                                                </a>
                                            </li> */}
                    {/* </ul>
                  </div>
                </div>
              </div> 
              
            </div> 
            
          </div>
        </div>  */}
        {/* / .header */}
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <BreadCrumb/>
            {/* <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/ingredients/approved-ingredients">
                    <i className="fe fe-home"></i> Ingredients
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/users" className="breadcrumb-item">
                    Pending Ingredients
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Ingredients Details
                </li>
              </ol>
            </nav> */}
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="report  m-0">Ingredient Information</h2>
              {path.includes("rejected") ? ("") :(
              <Link to={path ===`/ingredients/approved-ingredients/ingredient-details/${id}` ? `/ingredients/approved-ingredients/edit-ingredient/${id}` : `/ingredients/approved-ingredients` || path === `/ingredients/pending-ingredients/ingredient-details/${id}`?`/ingredients/pending-ingredients/edit-ingredient/${id}` : `/ingredients/pending-ingredients` || path === `/ingredients/rejected-ingredients/ingredient-details/${id}` ? `/ingredients/rejected-ingredients/edit-ingredient/${id}`: `/ingredients/rejected-ingredients`} class="btn btn-second">
                Edit
              </Link>
)}
            </div>
            <hr style={{ marginTop: "15px" }} />
            {loader ? (
              <div style={{ marginTop: "5px", marginLeft: "700px" }}>
                <Spinwheel />
              </div>
            ) : (
              <div className="row">
                <div className="col-lg-4" style={{ zIndex: "1" }}>
                  <form action="">
                    <label htmlFor="first" className="form-label text-muted">
                    Ingredient Name
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        type="text"
                        id="first"
                        style={{fontWeight: '600', fontSize: '1rem' , border: "none"}}
                        value={ingredientDetails?.name}
                        placeholder="Food Label Information"
                        name="name"
                        disabled
                      />
                    </div>
                    <br />
                    <label htmlFor="first" className="form-label text-muted">
                      Calorie Per Unit
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        style={{fontWeight: '600', fontSize: '1rem', border: "none"}}
                        type="num"
                        id="first"
                        placeholder="grams"
                        name="phone"
                        value={ingredientDetails?.caloriePerUnit}
                        disabled
                      />
                    </div>
                     
                    <br />
                    <label htmlFor="first" className="form-label text-muted">
                    Created By
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        style={{fontWeight: '600', fontSize: '1rem', border: "none"}}
                        type="num"
                        id="first"
                        placeholder="grams"
                        name="phone"
                        value={ingredientDetails?.usersDetails?.userName || ingredientDetails?.usersDetails?.email }
                        disabled
                      />
                    </div>
                    <br />
                    <label htmlFor="first" className="form-label text-muted">
                    Create on
                    </label>
                    <div className="position-relative">
                      <input
                        className="form-control"
                        style={{fontWeight: '600', fontSize: '1rem', border: "none"}}
                        type="num"
                        id="first"
                        placeholder="grams"
                        name="phone"
                        value={ingredientDetails?.createdAt?.split("T")[0]?.split("-").reverse().join("/")}
                        disabled
                      />
                    </div>
                    <br/>
                    <label htmlFor="first" className="form-label text-muted">
                    Reference Brand or Brand Name
                    </label>
                    <div className="position-relative">
                    <input
                        className="form-control"
                        style={{fontWeight: '600', fontSize: '1rem', border: "none"}}
                        type="num"
                        id="first"
                        placeholder=""
                        name="phone"
                        value={ingredientDetails?.brandName}
                        disabled
                      />
                    </div>
                    <br/>
                    <label htmlFor="first" className="form-label text-muted">
                    Reference Link
                    </label>
                    {
                  referenceLinkData&&referenceLinkData ?.map((v)=>{
                    return <>
                      <div className="position-relative">
                    <input
                    className="form-control"
                    style={{fontWeight: '600', fontSize: '1rem', border: "none"}}
                    type="num"
                    id="first"
                    //  placeholder="grams"
                    name="phone"
                    value={v}
                    disabled
                  />
                  </div>
                    </>
                  })
                }





                    {/* <div className="position-relative">
                      <input
                        className="form-control"
                        style={{fontWeight: '600', fontSize: '1rem', border: "none"}}
                        type="num"
                        id="first"
                        placeholder="grams"
                        name="phone"
                        value={ingredientDetails?.referenceLink}
                        disabled
                      />
                    </div> */}
                    
                    <br />
                    <label htmlFor="first" className="form-label text-muted">
                      Refrence Images
                    </label>

                    {ingredientDetails?.referenceImages &&
                      (ingredientDetails?.referenceImages).map(
                        (image, index) => (
                          <div
                            key={`21123dd3d3---${index}`}
                            className="d-flex justify-content-between align-items-center"
                          >
                            <div
                              className="position-relative"
                              style={{ marginTop: "10px" }}
                            >
                              <img
                                src={
                                  image === "" ||
                                  image === "null" ||
                                  image === "https://www.linkedin.com/"
                                    ? "/images/logo.png"
                                    : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${image}`
                                }
                                width="200px"
                                height="200px"
                                alt="Selected file"
                              />
                            </div>
                          </div>
                        )
                      )}
                    <br />
                    <label htmlFor="first" className="form-label">
                      Thumbnail Image
                    </label>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        <img
                          src={
                            ingredientDetails?.thumbnailImage === "" ||
                            ingredientDetails?.thumbnailImage === "null" ||
                            ingredientDetails?.thumbnailImage === "https://www.linkedin.com/"
                              ? "/images/recipe2.png"
                              : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${ingredientDetails?.thumbnailImage}`
                          }
                          width="200px"
                          height="200px"
                          alt="Selected file"
                        />
                      </div>
                    </div>
                    <br />
                    { ingredientDetails?.frontImage && <><label htmlFor="first" className="form-label">
                      Front Image
                    </label>
 <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        <img
                          src={
                            ingredientDetails?.frontImage === "" ||
                            ingredientDetails?.frontImage === "null" ||
                            ingredientDetails?.frontImage ===
                              "https://www.linkedin.com/"
                              ? "/images/recipe2.png"
                              : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${ingredientDetails?.frontImage}`
                          }
                          width="44px"
                          height="44px"
                          alt="Selected file"
                        />
                      </div>
                    </div></>}
                    <br />
{ingredientDetails?.backImage && <><label htmlFor="first" className="form-label">
                      Back Image
                    </label>

                    <div className="d-flex justify-content-between align-items-center">
                      <div className="position-relative">
                        {
                          <img
                            src={
                              ingredientDetails?.backImage === "" ||
                              ingredientDetails?.backImage === "null" ||
                              ingredientDetails?.backImage ===
                                "https://www.linkedin.com/"
                                ? "/images/recipe2.png"
                                : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${ingredientDetails?.backImage}`
                            }
                            width="44px"
                            height="44px"
                            alt="Selected file"
                          />
                        }
                      </div>
                    </div></>}
                    <br />
                    {/* <label htmlFor="first" className="form-label">Refrence/proof (paste link here)</label>
                                    <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="proof" name="phone" value="" disabled />
                                    </div>
                                    <br />
                                    <label htmlFor="first" className="form-label">Notes</label>
                                    <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="Lorem" name="phone" value="" disabled />
                                    </div>
                                    <br />
                                    <label htmlFor="first" className="form-label">Source code</label>
                                    <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="Lorem" name="phone" value="" disabled />
                                    </div>
                                    <br /> */}
                    {/* <label htmlFor="first" className="form-label">Food group</label>
                                    <div className="input-group mb-3">

                                        <select className="form-select" id="inputGroupSelect01" disabled >
                                            <option selected>Lorem</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>
                                    </div> */}
                    {/* <button type="submit" className="btn-custom w-auto mt-2">{signInWheel ? <Spinwheel sizeclassName="spin-small" /> : "Add"}</button> */}
                  </form>
                  <br />
                </div>
                <div className="col-lg-2"></div>
                <div className="col-lg-6 pt-3">
                  {/* <label htmlFor="first" className="form-label">Allergens</label>
                    <div className="input-group mb-4">

                        <select className="form-select" id="inputGroupSelect01" disabled >
                            <option selected>Lorem</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div> */}
                  <div
                    className="card "
                    style={{ maxWidth: "500px", height: "0", width: "100%" }}
                  >
                    <div className="card-body p-0" style={{border: "1px solid #000" ,boxShadow: "1px 5px 10px #00000061"}}>
                      <div className="modal-header-list">
                        <h1
                          className="modal-title-list  mb-0 py-3 "
                          id="exampleModalLabel"
                        >
                          Nutritional Information
                        </h1>
                      </div>
                      <div className=" p-2">
                        <div className=" heading d-flex justify-content-between align-items-center">
                          <h4>
                            <b>Nutrition</b>
                          </h4>
                          <h4>
                            <b>Per 100g</b>
                          </h4>
                        </div>
                        <hr className="m-0 bottom-line" />
                        {ingredientDetails &&
                          ingredientDetails?.proximates?.nutrients?.filter(l=>l.value !== 0 )?.map(
                            (item, index) => (
                              <div
                                key={`sakdjfsjfa-${index}`}
                                className="row pt-3"
                              >
                                <div className="col-5">
                                  <h6 className="fs-5 fw-bold">{item?.name}</h6>
                                </div>
                                <div className="col-3">
                                  <p className="fs-5">{item.unit}</p>
                                </div>
                                <div className="col-4">
                                  <h6 className="text-center fs-5 fw-bold">
                                    {item.value}
                                  </h6>
                                </div>
                              </div>
                            )
                          )}

                        <hr className="my-2 bottom-line" />
                        <div className=" heading d-flex justify-content-between align-items-center">
                          <h4>
                            <b>Minerals</b>
                          </h4>
                          <h4>
                            <b>Per 100g</b>
                          </h4>
                        </div>
                        {ingredientDetails &&
                          ingredientDetails?.proximates?.minerals?.filter(l=>l.value !== 0 )?.map(
                            (item, index) => (
                              <div key={`sakdjfsjfa-${index}`} className="row">
                                <div className="col-5">
                                  <h6 className="fs-5 fw-bold">
                                    {item.name}
                                  </h6>
                                </div>
                                <div className="col-3">
                                  <p className="fs-5">{item.unit}</p>
                                </div>
                                <div className="col-4">
                                  <h6 className="text-center fs-5 fw-bold">
                                    {item.value}
                                  </h6>
                                </div>
                              </div>
                            )
                          )}

                        <hr className="my-2 bottom-line" />
                        <div className=" heading d-flex justify-content-between align-items-center">
                          <h4>
                            <b>Vitamins</b>
                          </h4>
                          <h4>
                            <b>Per 100g</b>
                          </h4>
                        </div>
                        {ingredientDetails &&
                          ingredientDetails?.proximates?.vitamins?.filter(l=>l.value !== 0 )?.map(
                            (item, index) => (
                              <div className="row">
                                <div className="col-5">
                                  <h6 className="fs-5 fw-bold">
                                    {item.name}
                                  </h6>
                                </div>
                                <div className="col-3">
                                  <p className="fs-5">{item.unit}</p>
                                </div>
                                <div className="col-4">
                                  <h6 className="text-center fs-5 fw-bold">
                                    {item.value}
                                  </h6>
                                </div>
                              </div>
                            )
                          )}

                        <hr className="my-2 bottom-line" />
                        <p className=" text-dark m-0 card-bottom">
                          * Present daily values are based on a 2,000 calorie
                          diet. Your daily values may be higher or lower
                          depanding on your Energy
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export const  getToken = () => localStorage.getItem("token")
// export const getToken = () => "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InNhamlkLmtoYW5AdGVhbS5jbGlmZmV4LmNvbSIsImFnZW5jeUlkIjoiNjUiLCJhZ2VuY3lOYW1lIjoiQ2xpZmZleCIsInJvbGUiOiJBRE1JTiIsIm1mYSI6Im9wdGlvbmFsIiwiaWF0IjoxNjg0MTQ3MzEyLCJleHAiOjE2ODQ0MDY1MTJ9.JEaDZ_4hJzW_pLQEd_PhoWVwQIInr6uG-Q8e_FTh0Xw"
export const setToken = (token) => localStorage.setItem("token", token)
export const removeToken = () => localStorage.removeItem("token")
export const isAuth = () => Boolean(getToken())


const planColors = {
	business_silver: { primary: '#FD3468', secondary: '#FD34681A' },
	business_gold: { primary: '#7B30FB', secondary: '#7B30FB1A' },
	business_platinum: { primary: '#1E95A3', secondary: '#1E95A31A' },
	business_unlimited: { primary: '#EF7C0F', secondary: ' #EF7C0F1A' },
	personal_premium: { primary: '#42C442', secondary: '#42C44221' },
	personal: { primary: '#42C442', secondary: '#42C44221' },
}

export const getPlanColor = (name)=>{
    if(!name) return
    const plan = name.replace(" ","_")
    return planColors[plan] ? planColors[plan]?.primary : '#42C442'
}

export const capitalizeWords=(str)=> {
    if(!str) return
    const words = str.split(' ');
  
    const capitalizedWords = words.map(word => {
      if (word.length > 0) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      } else {
        return word; 
      }
    });
  
    return capitalizedWords.join(' ');
  }

  export const openImageInNewTab = (image) => {

    const imageUrl = `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${image}`
    window.open(imageUrl, '_blank');
  };
  


export const calculateNextPageStart = (page,limit,index) => {
  console.log(page,limit,'ye hai limit')
  if(!page || !limit) return
    return ((page - 1) * limit + 1)+index ;
}

import React from 'react'
// import { NavLink } from 'react-bootstrap';
import { Outlet ,NavLink} from 'react-router-dom';
import BreadCrumb from '../../common/BreadCrumb';

const UserLink = () => {
  return (
    <div className="main-content">
      
            {/* <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i>  Users
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Users
                </li>
              </ol>
            </nav> */}
 <div className="container-fluid">
          <div className="row"> 
          <BreadCrumb />
            <div className="d-flex justify-content-between  flex-wrap">
              <ul
                className="nav nav-pills"
                id="pills-tab"
                role="tablist"
                style={{ zIndex: "1" }}
              >
                <NavLink
                  className="nav-link"
                  exact
                  
                  activeClassName="active"
                  to="/users/active-users"
                  
                >
                  Active Users 
                </NavLink>

                <NavLink
                  className="nav-link"
                  exact
                   
                  activeClassName="active"
                  to="/users/deleted-users"
                  
                >
                  Deleted Users
                </NavLink>
              </ul>
              <div style={{ display: "-webkit-inline-box" }}></div>
            </div>
            <Outlet/>
            </div>
            </div>
    </div>
  )
}

export default UserLink

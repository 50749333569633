import { API_BASE_URL } from "./BaseUrl"
import { postApiWithoutToken, getApiWithToken, putApiWithToken, postApiWithToken, deleteApi, putApiWithoutToken, getApiWithoutToken, imgUploadApi} from "./ApiInterfaces"
import axios from "axios";

export const signIN = async (values) => {
    const res = await postApiWithoutToken("/admin/signin", values)
    return res;
}

export const getAdminDetails = async () => {
    const res = await getApiWithToken("/admin/getAdminDetails")
    return res;
}

export const adminProfileUpdate = async (values) => {
    const res = await putApiWithToken("/admin/updateAdminProfile", values)
    return res;
}

export const forgetPassword = async (values) => {
    const res = await postApiWithoutToken("/admin/forgetPassword", values)
    return res;
}


export const variedOtp = async (values) => {
    const res = await putApiWithoutToken("/admin/otpVerification", values)
    return res;
}

export const uploadImage = async (path, data) => {
    const token = localStorage.getItem("token");
    return axios.post(API_BASE_URL + path, data, {
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
        },
    });
}

export const addEssential = async (values) => {
    const res = await postApiWithToken("/admin/addEssentialNumbers", values)
    return res
}

export const editEssential = async (values) => {
    const res = await putApiWithToken('/admin/updateEssentialNumbers', values)
    return res;
}

export const getEssential = async (limit,page,search="") => {
    const res = await getApiWithToken(`/admin/getAllEssentialNumbers?name=${search}&page=${page}&limit=${limit}`);
    return res;
}

export const deleteEssentail = async (values) => {
    const res = await deleteApi('/admin/deleteEssentialNumbers',{_id:values})
    return res;
}

export const getAllSosUsers = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/getAllUserSos?page=${page}&limit=${limit}&search=${search}`);
    return res;
}

export const resetPassword = async (values) => {

    return axios.put(API_BASE_URL + "/admin/resetPassword", {password:values?.password}, {
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${values?.token}`,
        },
    });
    
}

export const getAllUsers = async (limit, page, search = "") => {
    const res = await getApiWithToken(`/admin/getAllUser?page=${page}&limit=${limit}&search=${search}`);
    return res;
}


export const getAllDocuments = async () => {
    const res = await getApiWithToken("/admin/getAllDocuments")
    return res
}

export const addOfficesAndEmbassies = async (values) => {
    const res = await postApiWithToken('/admin/addofficesAndEmbassies',values)
    return res;
}



// Calorie Finders APS'S



// Admin Get All Users
export const adminGetAllUserList  = async (limit, page, search = "", filter) => {
    const res = await getApiWithToken(`/admin/getAllUserList?search=${search}&page=${page}&limit=${limit}&filter=${filter}`)
    return res;
}
export const adminGetAllDeletedUserList  = async (limit, page, search = "", filter) => {
    const res = await getApiWithToken(`/admin/getAllDeletedUserList?search=${search}&page=${page}&limit=${limit}&filter=${filter}`)
    return res;
}
export const adminDeleteUser  = async (data) => {
    const res = await deleteApi(`/admin/activeDeactiveUserById?id=${data.id}&status=${data.status}`)
    return res;
}


// Adimin Login 
export const loginAdmin = async (values) => {
    const res = await postApiWithoutToken("/admin/adminLogin", values)
    return res;
}

// Admin Get All Ingredients

export const adminGetAllIngredients = async (limit, selectedPage, search = "", filter) => {
     
    // const res = await getApiWithToken(`/admin/getAllIngredients?search=${search}`)
    const res = await getApiWithToken(`/admin/getAllIngredentsCreatedByAllUser?limit=${limit}&page=${selectedPage}&search=${search}&filter=${filter}`)
    return res;
}

export const adminDeleteIngredient = async (id) => {
    const res = await deleteApi(`/admin/deleteIngredientByAdmin?id=${id}`)
    return res;
}

export const adminGetAllUsersPendingIngredients = async (limit, selectedPage, search = "", filter) => {
    
    const res = await getApiWithToken(`/admin/getAllUsersPendingIngredients?search=${search}&page=${selectedPage}&limit=${limit}&filter=${filter}`)
    return res;
}

export const adminGetIngredientById = async (id) => {
    const res = await getApiWithToken(`/admin/getIngredientsById?id=${id}`)
    return res;
}

export const adminApproveIngredientById = async (id) => {
    const res = await putApiWithToken(`/admin/approveIngredient?id=${id}&status=approved`)
    return res;
}
export const adminRejectIngredientById = async (id) => {
    const res = await putApiWithToken(`/admin/approveIngredient?id=${id}&status=rejected`)
    return res;
}

export const adminAddIngredient = async (values) => {
    const res = await postApiWithToken("/admin/addIngredientsByAdmin", values)
    return res
}

export const adminEditIngredient = async (values) => {
    const res = await putApiWithToken("/admin/updateIngredients", values)
    return res
}

export const adminUploadImages = async (values) => {
    const res = await imgUploadApi("/users/uploadMultiFile", values)
    return res
}




// Admin Recipes

// Admin Get All Recipes

export const adminGetAllRecipes = async (limit, selectedPage, search = "",filter) => {
    // console.log(search, "from Api function,,,,,,,,,,,,,,,,,,,,,.....................////////////////////")
    const res = await getApiWithToken(`/admin/getAllReceipesCreatedByAllUser?limit=${limit}&page=${selectedPage}&search=${search}&filter=all&recipeStatus=${filter}`)
    return res;
}

export const adminGetRecipeById = async (id) => {
    const res = await getApiWithToken(`/admin/getRecipeById?id=${id}`)
    return res;
}

export const adminApproveRecipetById = async (id) => {
    const res = await putApiWithToken(`/admin/approveRecipe?id=${id}`)
    return res;
}



export const createRecepie = async (values)=>{
    return await postApiWithToken("/admin/createRecipes", values)

}
export const createRecepieIngredient = async (values)=>{
    return await putApiWithToken("/admin/updateRecipesByAdmin", values)
}


// Admin Get All Proximates


export const adminGetAllProximates  = async () => {
    const res = await getApiWithToken(`/admin/getAllProximates`)
    return res;
}


export const showAndHideVitamisAndMinerals = async(values)=>{
    const res = await putApiWithToken("/admin/enableOrDisableAllProximates", values)
    return res;

}

export const getshowAndHideVitamisAndMinerals = async(values)=>{
    const res = await getApiWithToken("/admin/getAllProximateStatus")
    return res;

}

// Admin Get Dashboard Data

export const adminGetDashboardData  = async () => {
    const res = await getApiWithToken(`/admin/getAllDashboardData?from=&to=`)
    return res;
}

export const adminGetDashboardBarChartData  = async (year = "") => {
    const res = await getApiWithToken(`/admin/getUsersCountMonthWise?year=${year}`)
    return res;
}
export const adminGenerateReport  = async (type,from,to) => {
    const res = await getApiWithToken(`/admin/generateReports?startDate=${from}&endDate=${to}&reportType=${type}`)
    return res;
}


//download chart
export const downloadRecipeApi =(recipeId="",ingredeintId="")=>{
    return getApiWithToken(`/admin/generateRecipesAndIngredientsPdf?recipeId=${recipeId}&ingredientId=${ingredeintId}`)
 }



import React, { useState, useEffect } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { createRecepie, uploadImage } from "../../../utils/ApiEndpointFunctions";
import { ImageUrl } from "../../../utils/BaseUrl";
import { useFormik } from "formik";
import { createRecepieInitalValue, createRecepieSchema } from "../../common/Validation";
import BreadCrumb from "../../common/BreadCrumb";
import toast from "react-hot-toast";

export default function AddRecipe() {

  const [image, setImage] = useState({ imageName: "", fileSize: "" })
  const navigate = useNavigate()


  const onFileChange = async (e) => {

    let file = e.target.files[0];
    if (!file) {
      return
    }
    setImage((e) => {
      return { ...e, imageName: file.name }
    });
    const type = file.type.split("/")[1];
    const imageType = ["jpeg", "jpg", "png"];
    const validImageType = imageType.includes(type);
    if (!validImageType) {
      alert("upload a valid image : jpeg, png, jpg");
      return;
    } else {
      if (file.size > 1000001) {
        alert("image size is more than 1 MB")

        return;
      } else {
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("type", "recipe")
        const response = await uploadImage('/users/fileUpload', formdata);
        const data = response.data;

        formik.setFieldValue("thumbnailImage", data.data)
        setImage((e) => {
          return { ...e, fileSize: data.fileSize }
        });
        // //to set the banner image in the next div
        // let fr = new FileReader();
        // fr.readAsDataURL(file);
        // fr.onload = function () {
        //   setBannerData(fr.result);
        // };

      }


    }

  };

  const handleChange = (e) => {
    const {name,value} = e.target;

    if(name === 'noOfPortion'){
      formik.setFieldValue('noOfPortion', value);
      formik.setFieldValue('servingSize', '');
    }else{
      formik.setFieldValue('noOfPortion', '');
      formik.setFieldValue('servingSize', value);
    }
  };

  const submitRecepie = async () => {
    try{
      const payload = {...formik.values,noOfPortion:formik.values.noOfPortion ? Number(formik.values.noOfPortion):0}
      const res = await createRecepie(payload)
      console.log(res, "resres")
      if (res.status == 200) {
        toast.success(res?.data?.message, { id: "001" });
        navigate(`/recipes/recipe-details/${res.data.data.id}`)
      }
  }catch(err){
    toast.error(err?.response?.data?.message, {
      icon: "❗",
    });
  }
  }
  const formik = useFormik({
    initialValues: createRecepieInitalValue,
    onSubmit: submitRecepie,
    validationSchema: createRecepieSchema

  })

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content vh-100 vh_md-auto">
        {/* HEADER */}

        {/* / .header */}
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <BreadCrumb status={true} />
            <div className="d-flex align-items-center justify-content-between">
              <h2 className="report  m-0">Create Recipe</h2>
            </div>
            <hr style={{ marginTop: "15px" }} />
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-4">
                <div className="modal-dialog newrecipe">
                  <div className="modal-content">
                    <div className="modal-body">
                      <div className="text-center">
                        <img src="/images/NoPath@2x.png" width="45" alt="" />
                      </div>
                      <h4 className="text-center">Create a new recipe</h4>
                      <div className="d-flex justify-content-between">
                        <label className="upload">
                          <span style={{ cursor: "pointer" }}> Upload Recipe image Here</span>
                          <input
                            type="file"
                            name="myImage"
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                            onChange={onFileChange}
                          />
                        </label>
                      </div>
                      <div className="d-flex justify-content-between after-upload-img mt-2">
                        <div className="me-3">



                          <img
                            src={formik.values.thumbnailImage ? `${encodeURI(
                              `${ImageUrl}/${formik.values.thumbnailImage}`
                            )}` : "/images/recipe2.png"}
                            width="44"
                            height="44"
                            alt=""
                            className="rounded"
                          />
                        </div>
                        <div style={{ textAlign: "left", width: "100%" }}>
                          <h4 className="mb-0">{image?.imageName || "Image Name Here"}</h4>
                          <h5>{image?.fileSize || "Image size"}</h5>
                        </div>
                        <div>
                          <a href="">X</a>
                        </div>
                      </div>
                      <div className="input-lab-top mt-4">
                        <input
                          className="form-control"

                          id="name"
                          type="text"
                          name="name"
                          value={formik?.values?.name}
                          onChange={formik.handleChange}
                          placeholder="Breakfast Recipe 1"
                        />
                        <label htmlFor="name" className="input-label">
                          Enter Recipe Name
                        </label>
                        {formik.touched.name &&
                          formik.errors.name ? (
                          <div className="req-error">
                            {formik.errors.name}
                          </div>
                        ) : null}

                      </div>

                      <div className="row mt-4 align-items-end justify-content-around ">
                        <div className="col-4">
                          <label class="form-label mt-3" for="add">No. of Portion</label>
                          <input class="form-control px-2" name="noOfPortion" type="text" id="" value={formik?.values?.noOfPortion}                       
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                      }} onChange={(e) => handleChange(e)} placeholder="Portion No."></input>
                          {/* <input
                          className="form-control"
                          type="text"
                          name="quantityUnit"
                          id=""
                          onChange={(e)=>{
                            formik.setFieldValue("servingSize.quantityUnit",e.target.value)
                          }} 
                          disabled
                          placeholder="g"
                          /> */}


                          {/* <select className="form-select" onChange={(e)=>{
                          formik.setFieldValue("servingSize.quantityUnit",e.target.value)
                          }} name="quantityUnit" id="">
                          <option value="ml">ml</option>
                          <option value="g">g</option>
                           </select> */}
                          {/* {formik.touched.servingSize
                            ?
                             ( */}
                            {/* <div className="req-error">
                              {formik.errors.servingSize || formik.errors.noOfPortion}
                            </div> */}
                           {/* ) : null} */}
                        </div>
                       <div className="col-auto pb-3"> or</div>
                        <div className="col-4">
                          {/* <input
                            className="form-control"
                            type="text"
                            name="data"
                            id=""
                            onChange={(e) => {
                              formik.setFieldValue("servingSize.data", e.target.value)
                            }}
                            placeholder="Serving size"
                          />
                          {formik.touched.servingSize?.data &&
                            formik.errors.servingSize?.data ? (
                            <div className="req-error">
                              {formik.errors.servingSize?.data}
                            </div>
                          ) : null} */}

                          <label class="form-label mt-3" for="add">Portion Size</label>
                          <input class="form-control px-2" name="servingSize" type="text" value={formik.values.servingSize} id="" placeholder="Add Weight"   onChange={(e) => {
                              handleChange(e)
                            }}
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            />
                         
                        </div>
                        <div class="col-2 d-flex align-items-end"><span class="fw-bolder pb-3">grams</span></div>
                        <div className="req-error">
                              {formik.errors.servingSize || formik.errors.noOfPortion}
                        </div>
                      </div>
                      {/* {formik.touched.servingSize?.data &&
                            formik.errors.servingSize?.data ? (
                            <div className="req-error">
                              {formik.errors.servingSize?.data}
                            </div>
                          ) : null}  */}

                      {/* <div className="row mt-4">
                      <label htmlFor="" className="mb-3">Add Ingredient</label>
                      <div className="col-5">
                        <select className="form-select" name="" id="">
                          <option value="">1</option>
                          <option value="">2</option>
                        </select>
                      </div>
                      <div className="col-7 d-flex">
                        <input
                          className="form-control"
                          type="text"
                          name=""
                          id=""
                        />
                        <button className="btn btn-success ms-2">+</button>
                      </div>
                    </div> */}
                      <p className="form-label mt-3" htmlFor="add">
                        Add recipe note.
                      </p>
                      <div>
                        <textarea
                          className="form-control"
                          placeholder="Enter Notes here Max 1000 words."
                          name="note"
                          id=""
                          value={formik.values.note}
                          onChange={formik.handleChange}
                          cols="30"
                          rows="3"
                        >

                        </textarea>
                        {/* <div className="pt-2"><input type="checkbox" name="" id="" value="false" /> Share this recipe publicly on The CalorieFinder</div> */}
                        {formik.touched.note &&
                          formik.errors.note ? (
                          <div className="req-error">
                            {formik.errors.note}
                          </div>
                        ) : null}
                      </div>


                      <div className="text-center" >
                        <button className="btn_custome mt-4" type='submit'>
                          Create Recipe
                        </button>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </div>
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { PieChart } from "react-minimal-pie-chart";
import Sidebar from "../../../Layout/Sidebar";
import { adminGetDashboardBarChartData, adminGetDashboardData } from "../../../utils/ApiEndpointFunctions";
import { Link } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import { ImageUrl } from "../../../utils/BaseUrl";
import { toast } from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";

const data = [
    {name: "Jan", users: 0 },
    {name: "Feb",users: 0 },
    {name: "Mar", users: 0 },
    {name: "Apr",users: 0 },
    {name: "May",users: 0 },
    {name: "Jun",users: 0 },
    {name: "Jul",users: 0 },
    {name: "Aug",users: 0 },
    {name: "Sep",users: 0 },
    {name: "Oct",users: 0 },
    {name: "Nov",users: 0 },
    {name: "Dec",users: 0 }
];

export default function Dashboard() {
  const { adminDetail } = useAdminContext();
  const [ dashBoardData, setDashboardData ] = useState(null);
  const [loader, setLoader] = useState(false);
  const [barChartdata,setBarChartData] = useState(data)
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [yearList, setYearList] = useState([])


  const getUsers = async () => {

    try{
 
             setLoader(true)
             const res = await adminGetDashboardData();
             console.log(res?.data, "dashBoard data serssssssssssssssssssss.....//");
             setDashboardData(res?.data);
             setLoader(false)
             // if (res?.status === 200 && res?.data?.status === 1) {
             //     toast.success("User Login successfully.")
             //     localStorage.setItem("token", res?.data?.token);
             //     navigate("/dashboard")
             //     setLoader(false)
             // } 
            
         }catch(error){
             console.log(error, "eroor jhwdwdidfdhbchdbchdebdhcbudihccudhu");
             toast.error(error.message)
             setLoader(false)
         }
     
     }

     const getBarGraphData = async (year) => {
      try{
               const res = await adminGetDashboardBarChartData(year || "");
               if(res && res.status == 200){
                setBarChartData(res.data.data)
                setSelectedYear(res.data.year)
                setYearList(res.data.yearList)
               }
              
           }catch(error){
               toast.error(error.message)
           }
       
       }
 
 
     useEffect(()=>{
         getUsers();
         getBarGraphData();
     }, [])
 

  return (
    <>
      <div className="main-content">
        
        {/* / .header */}
        {/* CARDS */}
        {loader === true ?  (<div style={{textAlign:"center"}}><Spinwheel /></div>) : (
        <div className="container-fluid">
          
          <div className="row">
            <div className="col-12 col-lg-6 col-xl">
              {/* Value  */}
              <div className="card-bg">
                <div className="card-body t-uvv">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      {/* Title */}
                      <h6 className="text-uppercase text-muted mb-2">
                        TOTAL INGREDIENTS
                      </h6>
                      {/* Heading */}
                      <span className="h2 mb-0">{dashBoardData?.TotalIngredients}</span>
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h2 fe text-muted mb-0">
                        <img src="images/ingredient.png" alt="" width="25" />
                      </span>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl">
              {/* Hours */}
              <div className="card-bg">
                <div className="card-body t-uvv">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      {/* Title */}
                      <h6 className="text-uppercase text-muted mb-2">
                        TOTAL RECIPES
                      </h6>
                      {/* Heading */}
                      <span className="h2 mb-0">{dashBoardData?.TotalRecipes}</span>
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h2 fe text-muted mb-0">
                        <img src="images/recipe-book.png" alt="" width="25" />
                      </span>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl">
              {/* Exit */}
              <div className="card-bg">
                <div className="card-body t-uvv">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      {/* Title */}
                      <h6 className="text-uppercase text-muted mb-2">
                        PERSONAL USERS
                      </h6>
                      {/* Heading */}
                      <span className="h2 mb-0">{dashBoardData?.TotalPersonalUsers}</span>
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h2 fe text-muted mb-0">
                        <img src="images/group.png" alt="" width="25" />
                      </span>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 col-xl">
              {/* Exit */}
              <div className="card-bg">
                <div className="card-body t-uvv">
                  <div className="row align-items-center gx-0">
                    <div className="col">
                      {/* Title */}
                      <h6 className="text-uppercase text-muted mb-2">
                        BUSINESS USERS
                      </h6>
                      {/* Heading */}
                      <span className="h2 mb-0">{dashBoardData?.TotalBusinessUsers}</span>
                    </div>
                    <div className="col-auto">
                      {/* Icon */}
                      <span className="h2 fe text-muted mb-0">
                        <img src="images/team.png" alt="" width="25" />
                      </span>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                </div>
              </div>
            </div>
          </div>
          {/* / .row */}
          <div className="row">
            {/* <h2 className="report mb-0">Reports</h2> */}
            {/* Header */}
            <div className="header">
              <div className="header-body">
                <div className="row align-items-center">
                  <div className="col">
                        {/* <h2 className="report">
                          Graph
                        </h2> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Tab content */}
            <div className="tab-content">
              <div
                className="tab-pane fade show active"
                id="contactsListPane"
                role="tabpanel"
                aria-labelledby="contactsListTab"
              ></div>
              <div
                className="tab-pane fade"
                id="contactsCardsPane"
                role="tabpanel"
                aria-labelledby="contactsCardsTab"
              >
                {/* Cards */}
                <div
                  data-list='{"valueNames": ["item-name", "item-title", "item-email", "item-phone", "item-score", "item-company"], "page": 9, "pagination": {"paginationClass": "list-pagination"}}'
                  id="contactsCards"
                >
                  {/* Header */}
                  <div className="row align-items-center mb-4">
                    <div className="col">
                      {/* Form */}
                      <form>
                        <div className="input-group input-group-lg input-group-merge input-group-reverse">
                          <input
                            className="form-control list-search"
                            type="search"
                            placeholder="Search"
                          />
                          <span className="input-group-text">
                            <i className="fe fe-search" />
                          </span>
                        </div>
                      </form>
                    </div>
                    <div className="col-auto me-n3">
                      {/* Select */}
                      <form>
                        <div
                          className="choices"
                          data-type="select-one"
                          tabIndex={0}
                          role="listbox"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <div className="form-select form-select-sm form-control-flush">
                            <select
                              className="form-select form-select-sm form-control-flush form-control"
                              data-choices='{"searchEnabled": false}'
                              hidden
                              tabIndex={-1}
                              data-choice="active"
                            >
                              <option value="9 per page">9 per page</option>
                            </select>
                            <div className="choices__list choices__list--single">
                              <div
                                className="choices__item choices__item--selectable"
                                data-item
                                data-id={1}
                                data-value="9 per page"
                                data-custom-properties="null"
                                aria-selected="true"
                              >
                                9 per page
                              </div>
                            </div>
                          </div>
                          <div
                            className="choices__list dropdown-menu"
                            aria-expanded="false"
                          >
                            <div className="choices__list" role="listbox">
                              <div
                                className="choices__item dropdown-item choices__item--selectable is-highlighted"
                                data-select-text="Press to select"
                                data-choice
                                data-choice-selectable
                                data-id={1}
                                data-value="9 per page"
                                role="option"
                                aria-selected="true"
                              >
                                9 per page
                              </div>
                              <div
                                className="choices__item dropdown-item choices__item--selectable"
                                data-select-text="Press to select"
                                data-choice
                                data-choice-selectable
                                data-id={2}
                                data-value="All"
                                role="option"
                              >
                                All
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-auto">
                      {/* Dropdown */}
                      <div className="dropdown">
                        {/* Toggle */}
                        <button
                          className="btn btn-sm btn-white"
                          type="button"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="fe fe-sliders me-1" /> Filter{" "}
                          <span className="badge bg-primary ms-1 d-none">
                            0
                          </span>
                        </button>
                        {/* Menu */}
                        <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                          <div className="card-header">
                            {/* Title */}
                            <h4 className="card-header-title">Filters</h4>
                            {/* Link */}
                            <button
                              className="btn btn-sm btn-link text-reset d-none"
                              type="reset"
                            >
                              <small>Clear filters</small>
                            </button>
                          </div>
                          <div className="card-body">
                            {/* List group */}
                            <div className="list-group list-group-flush mt-n4 mb-4">
                              <div className="list-group-item">
                                <div className="row">
                                  <div className="col">
                                    {/* Text */}
                                    <small>Title</small>
                                  </div>
                                  <div className="col-auto">
                                    {/* Select */}
                                    <div
                                      className="choices"
                                      data-type="select-one"
                                      tabIndex={0}
                                      role="listbox"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="form-select form-select-sm">
                                        <select
                                          className="form-select form-select-sm form-control"
                                          name="item-title"
                                          data-choices='{"searchEnabled": false}'
                                          hidden
                                          tabIndex={-1}
                                          data-choice="active"
                                        >
                                          <option value="*">Any</option>
                                        </select>
                                        <div className="choices__list choices__list--single">
                                          <div
                                            className="choices__item choices__item--selectable"
                                            data-item
                                            data-id={1}
                                            data-value="*"
                                            data-custom-properties="null"
                                            aria-selected="true"
                                          >
                                            Any
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="choices__list dropdown-menu"
                                        aria-expanded="false"
                                      >
                                        <div
                                          className="choices__list"
                                          role="listbox"
                                        >
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable is-highlighted"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={1}
                                            data-value="*"
                                            role="option"
                                            aria-selected="true"
                                          >
                                            Any
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={2}
                                            data-value="Designer"
                                            role="option"
                                          >
                                            Designer
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={3}
                                            data-value="Developer"
                                            role="option"
                                          >
                                            Developer
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={4}
                                            data-value="Owner"
                                            role="option"
                                          >
                                            Owner
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={5}
                                            data-value="Founder"
                                            role="option"
                                          >
                                            Founder
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* / .row */}
                              </div>
                              <div className="list-group-item">
                                <div className="row">
                                  <div className="col">
                                    {/* Text */}
                                    <small>Lead scrore</small>
                                  </div>
                                  <div className="col-auto">
                                    {/* Select */}
                                    <div
                                      className="choices"
                                      data-type="select-one"
                                      tabIndex={0}TotalBusinessUsers
                                      role="listbox"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      <div className="form-select form-select-sm">
                                        <select
                                          className="form-select form-select-sm form-control"
                                          name="item-score"
                                          data-choices='{"searchEnabled": false}'
                                          hidden
                                          tabIndex={-1}
                                          data-choice="active"
                                        >
                                          <option value="*">Any</option>
                                        </select>
                                        <div className="choices__list choices__list--single">
                                          <div
                                            className="choices__item choices__item--selectable"
                                            data-item
                                            data-id={1}
                                            data-value="*"
                                            data-custom-properties="null"
                                            aria-selected="true"
                                          >
                                            Any
                                          </div>
                                        </div>
                                      </div>
                                      <div
                                        className="choices__list dropdown-menu"
                                        aria-expanded="false"
                                      >
                                        <div
                                          className="choices__list"
                                          role="listbox"
                                        >
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable is-highlighted"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={1}
                                            data-value="*"
                                            role="option"
                                            aria-selected="true"
                                          >
                                            Any
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={2}
                                            data-value="1/10"
                                            role="option"
                                          >
                                            1+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={3}
                                            data-value="2/10"
                                            role="option"
                                          >
                                            2+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={4}
                                            data-value="3/10"
                                            role="option"
                                          >
                                            3+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={5}
                                            data-value="4/10"
                                            role="option"
                                          >
                                            4+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={6}
                                            data-value="5/10"
                                            role="option"
                                          >
                                            5+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={7}
                                            data-value="6/10"
                                            role="option"
                                          >
                                            6+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={8}
                                            data-value="7/10"
                                            role="option"
                                          >
                                            7+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={9}
                                            data-value="8/10"
                                            role="option"
                                          >
                                            8+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={10}
                                            data-value="9/10"
                                            role="option"
                                          >
                                            9+
                                          </div>
                                          <div
                                            className="choices__item dropdown-item choices__item--selectable"
                                            data-select-text="Press to select"
                                            data-choice
                                            data-choice-selectable
                                            data-id={11}
                                            data-value="10/10"
                                            role="option"
                                          >
                                            10
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>{" "}
                                {/* / .row */}
                              </div>
                            </div>
                            {/* Button */}
                            <button
                              className="btn w-100 btn-primary"
                              type="submit"
                            >
                              Apply filter
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>{" "}
                  {/* / .row */}
                  {/* Body */}
                  <div className="list row">
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxOne"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxOne"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-1.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Dianna Smiley
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Designer</span> at{" "}
                              <span className="item-company">Twitter</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Twitter</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-danger-soft">
                                    1/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxTwo"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxTwo"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-2.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Ab Hadley
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Developer</span> at{" "}
                              <span className="item-company">Google</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Google</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-success-soft">
                                    8/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckBoxThree"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckBoxThree"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-3.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Adolfo Hess
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Owner</span> at{" "}
                              <span className="item-company">Google</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Google</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-success-soft">
                                    7/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxFour"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxFour"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-4.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Daniela Dewitt
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Designer</span> at{" "}
                              <span className="item-position">Twitch</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Twitch</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-warning-soft">
                                    4/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxFive"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxFive"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-5.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Miyah Myles
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Founder</span> at{" "}
                              <span className="item-company">Facebook</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Facebook</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-danger-soft">
                                    3/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckBoxSix"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckBoxSix"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-6.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Ryu Duke
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Owner</span> at{" "}
                              <span className="item-company">Netflix</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Netflix</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-warning-soft">
                                    6/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxSeven"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxSeven"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-7.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Glen Rouse
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Designer</span> at{" "}
                              <span className="item-position">Netflix</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Netflix</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-success-soft">
                                    9/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxEight"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxEight"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-1.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Miyah Myles
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Designer</span> at{" "}
                              <span className="item-company">Google</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Google</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-success-soft">
                                    10/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6 col-xl-4">
                      {/* Card */}
                      <div className="card">
                        <div className="card-body">
                          {/* Header */}
                          <div className="row align-items-center">
                            <div className="col">
                              {/* Checkbox */}
                              <div className="form-check form-check-circle">
                                <input
                                  className="form-check-input list-checkbox"
                                  type="checkbox"
                                  id="cardsCheckboxNine"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="cardsCheckboxNine"
                                />
                              </div>
                            </div>
                            <div className="col-auto">
                              {/* Dropdown */}
                              <div className="dropdown">
                                <a
                                  href="#"
                                  className="dropdown-ellipses dropdown-toggle"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i className="fe fe-more-vertical" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-end">
                                  <a href="#!" className="dropdown-item">
                                    Action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Another action
                                  </a>
                                  <a href="#!" className="dropdown-item">
                                    Something else here
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>{" "}
                          {/* / .row */}
                          {/* Image */}
                          <a
                            href="profile-posts.html"
                            className="avatar avatar-xl card-avatar"
                          >
                            <img
                              src="assets/img/avatars/profiles/avatar-2.jpg"
                              className="avatar-img rounded-circle"
                              alt="..."
                            />
                          </a>
                          {/* Body */}
                          <div className="text-center mb-5">
                            {/* Heading */}
                            <h2 className="card-title">
                              <a
                                className="item-name"
                                href="profile-posts.html"
                              >
                                Ryu Duke
                              </a>
                            </h2>
                            {/* Text */}
                            <p className="small text-muted mb-3">
                              <span className="item-title">Developer</span> at{" "}
                              <span className="item-company">Microsoft</span>
                            </p>
                            {/* Buttons */}
                            <a
                              className="btn btn-sm btn-white"
                              href="tel:1-123-456-7890"
                            >
                              <i className="fe fe-phone me-1" /> Call
                            </a>
                            <a
                              className="btn btn-sm btn-white"
                              href="mailto:john.doe@company.com"
                            >
                              <i className="fe fe-mail me-1" /> Email
                            </a>
                          </div>
                          {/* Divider */}
                          <hr className="card-divider mb-0" />
                          {/* List group */}
                          <div className="list-group list-group-flush mb-n3">
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Company</small>
                                </div>
                                <div className="col-auto">
                                  {/* Text */}
                                  <small>Microsoft</small>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                            <div className="list-group-item">
                              <div className="row">
                                <div className="col">
                                  {/* Text */}
                                  <small>Lead Score</small>
                                </div>
                                <div className="col-auto">
                                  {/* Badge */}
                                  <span className="item-score badge bg-warning-soft">
                                    6/10
                                  </span>
                                </div>
                              </div>{" "}
                              {/* / .row */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pagination */}
                  <div className="row g-0">
                    {/* Pagination (prev) */}
                    <ul className="col list-pagination-prev pagination pagination-tabs justify-content-start">
                      <li className="page-item">
                        <a className="page-link" href="#">
                          <i className="fe fe-arrow-left me-1" /> Prev
                        </a>
                      </li>
                    </ul>
                    {/* Pagination */}
                    <ul className="col list-pagination pagination pagination-tabs justify-content-center">
                      <li className="active">
                        <a className="page" href="#" data-i={1} data-page={9}>
                          1
                        </a>
                      </li>
                      <li>
                        <a className="page" href="#" data-i={2} data-page={9}>
                          2
                        </a>
                      </li>
                    </ul>
                    {/* Pagination (next) */}
                    <ul className="col list-pagination-next pagination pagination-tabs justify-content-end">
                      <li className="page-item">
                        <a className="page-link" href="#">
                          Next <i className="fe fe-arrow-right ms-1" />
                        </a>
                      </li>
                    </ul>
                  </div>
                  {/* Alert */}
                  <div
                    className="list-alert alert alert-dark alert-dismissible border fade"
                    role="alert"
                  >
                    {/* Content */}
                    <div className="row align-items-center">
                      <div className="col">
                        {/* Checkbox */}
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            id="cardAlertCheckbox"
                            type="checkbox"
                            defaultChecked
                            disabled
                          />
                          <label
                            className="form-check-label text-white"
                            htmlFor="cardAlertCheckbox"
                          >
                            <span className="list-alert-count">0</span> deal(s)
                          </label>
                        </div>
                      </div>
                      <div className="col-auto me-n3">
                        {/* Button */}
                        <button className="btn btn-sm btn-white-20">
                          Edit
                        </button>
                        {/* Button */}
                        <button className="btn btn-sm btn-white-20">
                          Delete
                        </button>
                      </div>
                    </div>{" "}
                    {/* / .row */}
                    {/* Close */}
                    <button
                      type="button"
                      className="list-alert-close btn-close"
                      aria-label="Close"
                    ></button>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-8">
              {/* Body */}

              {/* Footer */}
              <div id="earningsChart" className="card">
                <div className="card-header flex-wrap">
                  {/* Title */}
                  <h4 className="card-header-title">
                    Users <br />
                    {/* <small>
                      Last updated Today
                    </small> */}
                  </h4>

                  {yearList && yearList.length ? 
                  <div className="dropdown">
                    <button
                      className="btn btn-sm btn-white"
                      type="button"
                      data-bs-toggle="dropdown"
                      // data-bs-auto-close="outside"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      <i className="fe fe-sliders me-1"></i> Filter{" "}
                      <img src="images/chevron-down.svg" alt="" width={17} />{" "}
                      <span className="badge bg-primary ms-1 d-none">0</span>
                    </button>

                    <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                     <table className="table mb-0">
                        {/* <tr>
                          <td>
                            {" "}
                            <input type="radio" name="" id="mon" />{" "}
                            <label htmlFor="mon"> Monthly</label>{" "}
                          </td>
                        </tr> */}
                       {yearList.map(el=>
                        (<tr>
                          <td onClick={()=>getBarGraphData(el)}>
                            {" "}
                            <input type="radio" name="" id="yer" checked={el==selectedYear} />{" "}
                            <label htmlFor="yer" style={{cursor:"pointer"}}>{el}</label>{" "}
                          </td>
                        </tr>
                        ))}

                      </table>
                    </form>
                  </div> : null}
                </div>
                <div className="card-body">
                  {/* Chart */}
                  <BarChart width={900} height={300} data={barChartdata}>
                    <CartesianGrid strokeDasharray="1 1" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="users" fill="#9B7343" />
                  </BarChart>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4">
              <div className="card">
                <div className="card-header">
                  <h4 className="card-header-title">Ingredient Vs Recipe</h4>
                </div>

                <div className="card-body charts-w">
                    <PieChart
                      data={[
                        {
                          title: dashBoardData?.TotalIngredients,
                          value: dashBoardData?.TotalIngredients,
                          color: "#FFD188",
                          width: "200px",
                        },
                        {
                          title: dashBoardData?.TotalRecipes,
                          value: dashBoardData?.TotalRecipes,
                          color: "#9B7343",
                          width: "200px",
                        },
                      ]}
                    />

                    <div className="d-flex justify-content-around mt-3">
                      <div className="text-center d-flex align-items-center">
                        <div style={{ backgroundColor: '#FFD188', width: '15px', height: '15px' }}></div>
                        <div className="p-2">Ingredient</div>
                      </div>

                      <div className="text-center d-flex align-items-center">
                        <div style={{ backgroundColor: '#9B7343', width: '15px', height: '15px' }}></div>
                        <div className="p-2">Recipe</div>
                      </div>
                    </div>

                </div>
              </div>
            </div>
          </div>{" "}
          {/* / .row */}
        </div>
        )}
      </div>
    </>
  );
}

import { createContext, useContext, useState } from "react";

export const adminContext = createContext({
    adminDetail: null

});

export function AdminContextProvider({ children }) {
    const [adminDetail, setAdminDetail] = useState(null);
    const [countOfIngredients,setCountOfIngredients] = useState()

    return (
        <adminContext.Provider value={{ adminDetail, setAdminDetail ,countOfIngredients,setCountOfIngredients}}>
            {children}
        </adminContext.Provider>
    );
}

export function useAdminContext() {
    const { adminDetail, setAdminDetail,countOfIngredients,setCountOfIngredients } = useContext(adminContext);

    return { adminDetail, setAdminDetail,countOfIngredients,setCountOfIngredients };
}
import { React, useState, useEffect } from "react";
import { useAdminContext } from "../../../context/adminContext";
import { ImageUrl } from "../../../utils/BaseUrl";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";
import ReactPaginate from "react-paginate";
import moment from "moment";
import {
  adminGetAllIngredients,
  adminApproveIngredientById,
  adminGetIngredientById,
  adminRejectIngredientById,
  downloadRecipeApi,
  adminDeleteIngredient,
} from "../../../utils/ApiEndpointFunctions";
import { getIn } from "formik";
import { debounce } from "lodash";
import Ingredients from "./Ingredients";
import { calculateNextPageStart } from "../../../utils/HelperFuncitons";



const CommonIngredientTable = () => {
  const [loader, setLoader] = useState(false);
  const [allIngredients, setAllIngredients] = useState([]);
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [nutrientsState, setNutrientsState] = useState(null);
  const [miniralsState, setMiniralsState] = useState(null);
  const [vitaminsState, setVitaminsState] = useState(null);
  const [filter, setFilter] = useState()
  const [ingredientId, setIngredientId] = useState(0)
  const [downloadeLoader, setDownloadLoader] = useState(false)

  // const [download]
  // const [ingredientsDetails, setIngredientsDetails] = useState({})
  // const [currentPage, setCurrentPage] = useState(1);
  let data = useLocation();

  let allPaths = data.pathname.split('/').filter((item) => {
    return item !== "";
  });

  const navigate = useNavigate();
  const limit = 50;
  let currentPage = "";


  const handlePageClick = (event) => {
    currentPage = event.selected + 1;
    setSelectedPage(event.selected + 1);
    getIngredients(currentPage);
    // searchIngredients();
  };


  const getIngredients = async (page = "1") => {

    try {
      setLoader(true);


      const res = await adminGetAllIngredients(limit, page, search, filter);

      const ingrediatntsData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      // ingrediatntsData?.map((ingrediatntData)=> console.log(ingrediatntData, "kala"))
      // let tempNut = res?.data?.data?.proximates?.nutrients.filter((el)=> el.value !== 0 || "")
      // setNutrientsState(tempNut);
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setAllIngredients(ingrediatntsData);
      setLoader(false);
    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  };

  const handleDownloadIngredient = async (id) => {
    if (downloadeLoader) return;
    setIngredientId(id)

    setDownloadLoader(true);
    try {
      const res = await downloadRecipeApi("", id);
      if (res.status === 200 || res.status === 201) {
        setDownloadLoader(false);
        setIngredientId(0)
        window.open(`${ImageUrl + "/" + res.data.data}`, "_blank");
      }
    } catch (err) {
      setDownloadLoader(false)
      setIngredientId(0)
    }

  };

  const handleDeleteIngredient = async (id) => {
    try {
      const confirm = window.confirm("Are You Sure Want To Delete the Ingredient?")
      if (confirm) {
        setLoader(true);
        const res = await adminDeleteIngredient(id);
        if (res?.status === 200 && res?.data?.status === 1) {
          getIngredients()
        }
        setLoader(false)
      }

    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      setLoader(false)
      toast.error(error.message);
      setLoader(false);
    }

  }

  const approvedIngredient = async (id, name) => {
    const confirm = window.confirm(
      `Are You Sure, You want to approve Ingredient with this Name: ${name}`
    );

    if (confirm === true) {
      try {
        setLoader(true);
        const res = await adminApproveIngredientById(id);
        // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
        //  setAllIngredients(res?.data?.data);
        setLoader(false);
        if (res?.status === 200 && res?.data?.status === 1) {
          getIngredients();
          toast.success(res?.data?.message);
          //  localStorage.setItem("token", res?.data?.token);
          //  navigate("/dashboard")
          setLoader(false);
        }
      } catch (error) {
        // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
        toast.error(error.message);
        setLoader(false);
      }
    }
  };
  const rejectedIngredient = async (id, name) => {
    const confirm = window.confirm(
      `Are You Sure, You want to reject Ingredient with this Name: ${name}`
    );

    if (confirm === true) {
      try {
        setLoader(true);
        const res = await adminRejectIngredientById(id);
        // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
        //  setAllIngredients(res?.data?.data);
        setLoader(false);
        if (res?.status === 200 && res?.data?.status === 1) {
          getIngredients();
          toast.success(res?.data?.message);
          //  localStorage.setItem("token", res?.data?.token);
          //  navigate("/dashboard")
          setLoader(false);
        }
      } catch (error) {
        // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
        toast.error(error.message);
        setLoader(false);
      }
    }
  };


  const debouncedgetIngredients = debounce(getIngredients, 50);


  // searchIngredients

  let search1 = "";

  const handleChange = (event) => {


    if (event.target.name === "searchField") {

      search1 = event.target.value;
      // search1 = search1.split(" ").join("");
      search1 = search1.trim()
      // search1 = search1.split(" ").join('');
      search1 = encodeURI(search1)
      setSearch(search1);


    }
  };

  const debouncedOnChange = debounce(handleChange, 800);


  const searchIngredients = async () => {
    // console.log(search, "serch1 from search function state...........////")

    try {
      setLoader(true);
      const res = await adminGetAllIngredients(
        limit,
        selectedPage,
        search,
        filter
      );
      console.log(res?.data?.data, "search inng data......////");
      const ingrediatntsData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setAllIngredients(ingrediatntsData);
      setLoader(false);
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  const getIngredientsDetailes = async (idPass) => {
    try {
      setLoader(true);
      const res = await adminGetIngredientById(idPass);
      console.log(res?.data?.data, "dtr");
      // setIngredientsDetails(res?.data?.data);
      let tempNut = res?.data?.data?.proximates?.nutrients;
      tempNut = tempNut.filter((el) => el.value !== 0 || "");
      setNutrientsState(tempNut);

      let tempMin = res?.data?.data?.proximates?.minerals;
      tempMin = tempMin.filter((el) => el.value !== 0 || "");
      setMiniralsState(tempMin);

      let tempVit = res?.data?.data?.proximates?.vitamins;
      tempVit = tempVit.filter((el) => el.value !== 0 || "");
      setVitaminsState(tempVit);
      setLoader(false);
    } catch (error) {
      toast.error(error.message);
      setLoader(false);
    }
  };

  useEffect(() => {

    if (search) {

      searchIngredients();

    }

  }, [search, selectedPage]);

  useEffect(() => {
    if (filter) {

      debouncedgetIngredients();
    }

  }, [search, filter]);

  useEffect(() => {
    if (allPaths[1] === "approved-ingredients") {
      setFilter("approved");
    } else if (allPaths[1] === "pending-ingredients") {
      setFilter("pending");
    } else if (allPaths[1] === "rejected-ingredients") {
      setFilter("rejected");
    }
  }, [allPaths[1]])

  return (
    <div>
      {/* <Ingredients/> */}
      <>
        <div className="card">
          <div
            className=" d-flex justify-content-end my-3"
            style={{ margin: "" }}
          >
            <input
              className="form-search me-3 "
              type="text"
              name="searchField"
              id=""
              placeholder="Search by name"
              onChange={debouncedOnChange}
            />

            {/* <div className="dropdown show">
            <div className="dropdown me-3">
              <button
                className="btn btn-second dropdown-toggle"
                style={{ padding: "11px 20px" }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Filter
              </button>
              <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                <li>
                  <Link className="dropdown-item">Locum </Link>
                </li>
                <li>
                  <Link className="dropdown-item">Locum</Link>
                </li>
              </ul>
            </div>
          </div> */}
            <Link
              className="btn btn-second"
              type="button"
              aria-expanded="false"
              to="/ingredients/approved-ingredients/add-ingredient"
            >
              Add Ingredient
            </Link>
          </div>


          <div className="tab-content">
            <div
              className=" tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabIndex="0"
            >
              <div className="table-responsive card">
                <table className="table table-hover table align-middle  card-table table-striped-columns">
                  <thead>
                    <tr>
                      <th>
                        <span className="list-sort text-muted">S.No</span>
                      </th>
                      <th>
                        <span className="list-sort text-muted">Image</span>
                      </th>
                      <th style={{ width: "18%" }}>
                        <span className="list-sort text-muted">Name</span>
                      </th>
                      <th>
                        <span className="list-sort text-muted">Created Date</span>
                      </th>
                      <th>
                        <a className="list-sort text-muted" href>
                          Status
                        </a>
                      </th>
                      <th>
                        <a className="list-sort text-muted" href>
                          Created by
                        </a>
                      </th>
                      <th>
                        <span className="list-sort text-muted">View</span>
                      </th>
                      {filter !== "rejected" ? <th style={{ textAlign: "center" }}>
                        <span className="list-sort text-muted">Action</span>
                      </th> : ""}
                    </tr>
                  </thead>

                  {loader ? (
                    <div style={{ marginTop: "5px", marginLeft: "700px" }}>
                      <Spinwheel />
                    </div>
                  ) : (
                    <tbody>
                      {allIngredients.length > 0 ? (
                        allIngredients?.map((ingredient, index) => (
                          <tr className="">
                            <td scope="row table-hover ">{calculateNextPageStart(selectedPage,limit,index)}</td>
                            <td>
                              <img
                                src={
                                  ingredient?.thumbnailImage === "" ||
                                    ingredient?.thumbnailImage === "null"
                                    ? "/images/recipe2.png"
                                    : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${ingredient?.thumbnailImage}`
                                }
                                alt={ingredient?.name}
                                height={35}
                                width={35}
                                className="rounded-circle me-2"
                              />
                            </td>
                            {filter == "approved" ? <td
                              style={{ color: "#609FFF" }}
                              onClick={() => { navigate(`/ingredients/approved-ingredients/ingredient-details/${ingredient?.id}`, { state: { filter } }) }}
                            >
                              {" "}
                              <Link>{ingredient?.name}</Link>
                            </td> : <td
                              style={{ color: "#609FFF" }}
                              onClick={() => { navigate(`/ingredients/pending-ingredients/ingredient-details/${ingredient?.id}`, { state: { filter } }) }}
                            >
                              {" "}
                              <Link>{ingredient?.name}</Link>
                            </td>}
                            <td>{moment(ingredient?.createdAt).format("LL")}</td>
                            {/* <td>{ingredient?.isApproved === true ?  <span style={{ color: "green", fontWeight: "600" }}>Approved</span>: ingredient?.isApproved === false ? <span style={{ color: "red", fontWeight: "600" }}>Pending</span> : ("")}</td> */}
                            <td>{ingredient?.ingredientStatus}</td>
                            <td>{ingredient?.usersDetails?.userName || ingredient?.usersDetails?.email}</td>
                            <td>
                              <button
                                className="btn btn-dark btn-sm py-1 px-2 border rounded"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                onClick={() =>
                                  getIngredientsDetailes(ingredient?.id)
                                }
                              >
                                Chart
                              </button>
                            </td>

                            {filter !== "rejected" ? filter == "approved" ? <td className='d-flex' style={{ textAlign: "end" }}>
                              {ingredientId === ingredient?.id && downloadeLoader ?
                                <div style={{ marginRight: "5px" }}> <Spinwheel color="black !important" /></div> :

                                <span onClick={() => handleDownloadIngredient(ingredient?.id)}>

                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download me-3" viewBox="0 0 16 16" style={{ cursor: "pointer" }}>
                                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                                    <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                                  </svg>
                                </span>}
                              <Link className="btn btn-light btn-sm py-1 px-2 border rounded" to={`/ingredients/approved-ingredients/edit-ingredient/${ingredient?.id}`}>
                                Edit
                              </Link>
                              <span className="btn btn-light btn-sm py-1 px-2 border rounded " style={{ cursor: "pointer" }} onClick={() => handleDeleteIngredient(ingredient?.id)}>Delete</span>
                            </td> : <td style={{ textAlign: "end" }}>
                              <div className="dropdown">
                                <button
                                  className=" border-0 bg-white"
                                  type="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    className="bi bi-three-dots-vertical"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                  </svg>
                                </button>
                                <ul
                                  className="dropdown-menu"
                                  style={{ zIndex: "10" }}
                                >
                                  <li
                                    onClick={() =>
                                      approvedIngredient(
                                        ingredient?.id,
                                        ingredient?.name
                                      )
                                    }
                                  >
                                    <a className="dropdown-item" href="#">
                                      Approve
                                    </a>
                                  </li>
                                  <li>
                                    <Link className="dropdown-item" to={`/ingredients/approved-ingredients/edit-ingredient/${ingredient?.id}`}>
                                      Edit
                                    </Link>
                                  </li>
                                  <li onClick={() =>
                                    rejectedIngredient(
                                      ingredient?.id,
                                      ingredient?.name
                                    )
                                  }
                                  >
                                    <a className="dropdown-item" href="#">
                                      Reject
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </td> : ""}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td align="center" colspan="6" >
                            {/* <h1>Oppss..!</h1>   No, user available to show. */}
                            <img src="/images/noData.png" style={{ width: "200px" }} alt="" width="50%" />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>

          {totalCount > limit ? (
            <div className="d-flex justify-content-center">
              <ReactPaginate
                nextLabel="Next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={3}
                marginPagesDisplayed={1}
                pageCount={page}
                previousLabel="< Previous"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakLabel="..."
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName={"active"}
              // forcePage={selectedPage}
              />
            </div>
          ) : (
            ""
          )}
          {/* Pagination  */}
        </div>

        {/* <!-- Modal --> */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog  modal-dialog-centered"
            style={{ maxWidth: "440px", width: "100%" }}
          >
            <div
              className="modal-content "
              style={{ borderRadius: "0px", border: "1px solid #000" }}
            >
              <div className="modal-header-list position-relative">
                <h1
                  className="modal-title-list  mb-0 py-3 "
                  id="exampleModalLabel"
                >
                  Nutritional Information
                  {/* <button type="button" class="position-absolute rounded-circle  end-0 me-2"> <i class="bi bi-x"></i></button> */}
                  <span 
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    style={{cursor:"pointer"}}
                  >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-x-circle position-absolute  end-0 me-2 text-aligns-center" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                  </svg>
                  </span>
                </h1>
              </div>
              <div className="modal-body pb-0">
                {nutrientsState?.length > 0 ? <><div className=" heading d-flex justify-content-between align-items-center">
                  <h6>Nutrition</h6>
                  <h6>Per 100g</h6>
                </div>
                  <hr className="m-0 bottom-line" />
                  {nutrientsState?.map((nutrient) => (
                    <div className="row pt-3">
                      <div className="col-5">
                        <h6 className="fs-5 fw-bold">{nutrient?.name}</h6>
                      </div>
                      <div className="col-3">
                        <p className="fs-5">{nutrient?.unit}</p>
                      </div>
                      <div className="col-4">
                        <h6 className="text-center fs-5 fw-bold">
                          {nutrient?.value}
                        </h6>
                      </div>
                    </div>
                  ))} </> : ""}


                {miniralsState?.length > 0 ? <> <hr className="my-2 bottom-line" />
                  <div className=" heading d-flex justify-content-between align-items-center">
                    <h6>Minerals</h6>
                    <h6>Per 100g</h6>
                  </div>
                  {/* <hr className="my-2 bottom-line" /> */}
                  {miniralsState?.map((miniral) => (
                    <div className="row">

                      <div className="col-5">
                        <h6 className="fs-5 fw-bold">{miniral?.name}</h6>
                      </div>
                      <div className="col-3">
                        <p className="fs-5">{miniral?.unit}</p>
                      </div>
                      <div className="col-4">
                        <h6 className="text-center fs-5 fw-bold">
                          {miniral?.value}
                        </h6>
                      </div>
                    </div>
                  ))}</> : ""}

                {vitaminsState?.length > 0 ?

                  <><hr className="my-2 bottom-line" />
                    <div className=" heading d-flex justify-content-between align-items-center">
                      <h6>Vitamins</h6>
                      <h6>Per 100g</h6>
                      {console.log(vitaminsState, "vitaminsStatevitaminsState")}
                    </div>
                    {/* <hr className="my-2 bottom-line" /> */}
                    {vitaminsState?.map((vitamin) => (
                      <div className="row">

                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">{vitamin?.name}</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">{vitamin?.unit}</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">
                            {vitamin?.value}
                          </h6>
                        </div>
                      </div>
                    ))}</> : ""}
                {/* <div className="row">
                <div className="col-2 text-center">
               
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div> */}
                {/* <div className="row">
                <div className="col-2 text-center">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div> */}

              </div>
              <hr className="my-2 bottom-line" />
              <p className="p-3 text-dark m-0 card-bottom">
                % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br />
                A ratio value of 1 is used for volume to weight conversion during some analysis.<br />
                Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0.
              </p>
            </div>
          </div>
        </div>

        {/* <!-- Modal --> */}
      </>
    </div>
  )
}

export default CommonIngredientTable

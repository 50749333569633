import { React, useState, useEffect } from 'react'
import { useAdminContext } from '../../../context/adminContext';
import { ImageUrl } from '../../../utils/BaseUrl';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from "react-hot-toast";
import Spinwheel from '../../common/Spinwheel';
import ReactPaginate from 'react-paginate';
import moment from 'moment';
import { adminGetAllRecipes, adminApproveRecipetById, adminGetRecipeById, downloadRecipeApi } from "../../../utils/ApiEndpointFunctions";
import { debounce } from 'lodash';
import { calculateNextPageStart } from '../../../utils/HelperFuncitons';


const AllRecipes = () => {
  const { adminDetail, } = useAdminContext()
  const [allEssential, setAllEssential] = useState(null);
  const [removedata, setRemoveData] = useState(null);
  const [page, setPage] = useState(0);
  const [singleRecipeDetail, setSingleRecipeDetail] = useState()
  const [essentialSpin, setEssentialSpin] = useState(true)
  const [selectedPage, setSelectedPage] = useState(1);
  const [totalCount, setTotalCount] = useState(null)
  const [filter, setFilter] = useState("")
  const [downloadeLoader,setDownloadLoader] = useState(false)
  const [recipeId,setRecipeId] = useState(0)
  const limit = 50;
  let currentPage = ""


  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [allRecipes, setAllRecipes] = useState([]);
  const [search, setSearch] = useState("");



  const handlePageClick = (event) => {
    console.log(event.selected + 1, "seleted pagination............///")
    // setCurrentPage(event.selected + 1);
    currentPage = event.selected + 1;
    setSelectedPage(event.selected + 1);
    getRecipes(currentPage);
    // searchrecipes();
  };

  const getRecipes = async (page = "1") => {

    try {

      setLoader(true)
      const res = await adminGetAllRecipes(limit, page, search);
      console.log(res?.data, "responce ingrediatnts new userssssssssssssssssssss.....//");
      const recipesData = res?.data?.data
      const totalLength = res?.data?.totalLength
      setTotalCount(totalLength)
      // console.log(Math.ceil(totalLength/limit),'test')
      setPage(Math.ceil(totalLength / limit))
      setAllRecipes(recipesData);
      setLoader(false)

    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message)
      setLoader(false)
    }

  }

  const handleDownloadRecipe = async (id) => {
    if(downloadeLoader) return;
    setRecipeId(id)

    setDownloadLoader(true);
    try {
      const res = await downloadRecipeApi(id, "");
      if (res.status === 200 || res.status === 201) {
        setDownloadLoader(false);
        setRecipeId(0)
        window.open(`${ImageUrl + "/" + res.data.data}`, "_blank");
      }
    } catch (err) {
      setDownloadLoader(false)
      setRecipeId(0)
    }

  };


  console.log(singleRecipeDetail, "singlered")

  const approvedrecipe = async (id, name) => {

    const confirm = window.confirm(`Are You Sure, You want to approve recipe with this Name: ${name}`)

    if (confirm === true) {

      try {

        setLoader(true)
        const res = await adminApproveRecipetById(id);

        setLoader(false)
        if (res?.status === 200 && res?.data?.status === 1) {
          toast.success(res?.data?.message)

          setLoader(false)
        }

      } catch (error) {
        console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
        toast.error(error.message)
        setLoader(false)
      }
    }

  }




  useEffect(() => {

    if (search === "") {
      getRecipes()
      console.log("users effect")
    }
    // const data = JSON.stringify({ x: 5, y: 6 });
    // console.log(data, "users effect data");
    // const data1 = JSON.parse(data);
    // console.log(data1, "users effect data1");
  }, [search])

  useEffect(() => {
    console.log("From All recipes Component.")
  }, [])



  // searchrecipes

  let search1 = ""


  const handleChange = (event) => {
    console.log(event.target.name, "name................................................//////////")

    if (event.target.name === "searchField") {
      console.log(event.target.value, "value................................................//////////")
      search1 = event.target.value
      search1 = search1.trim()
      // search1 = search1.split(" ").join('');
      search1 = encodeURI(search1)
      console.log(search1, "searchField")
      setSearch(search1)
      // searchRecipes(search1)

    }

  }

  const debouncedOnChange = debounce(handleChange, 800);

  console.log(search, "serch1 from search function state...........////")

  const handleRecipeChart = async (id) => {
    try {
      const res = await adminGetRecipeById(id);
      if (res.status === 200 || res.status === 201) {
        setSingleRecipeDetail(res.data.data)
      }
    } catch (err) {
      console.log(err)
    }
  }
  const searchRecipes = async () => {

    // console.log(search, "serch1 from search function state...........////")

    try {


      setLoader(true)
      const res = await adminGetAllRecipes(limit, 1, search, filter);
      setAllRecipes(res?.data?.data);
      const recipesData = res?.data?.data
      const totalLength = res?.data?.totalLength
      setTotalCount(totalLength)
      setPage(Math.ceil(totalLength / limit))
      setAllRecipes(recipesData);
      setLoader(false)

    } catch (error) {
      toast.error(error.message)
      setLoader(false)
    }
  }



  useEffect(() => {

    // if(search){

    searchRecipes(search)


    // }


  }, [search, filter])





  return (
    <>
      <div className="card">
        <div className=" d-flex justify-content-end my-3" style={{ margin: '' }}>
          <input
            className="form-search me-3 "
            type="text"
            name="searchField"
            id=""
            placeholder="Search"
            onChange={debouncedOnChange}
          />
          {/*          
          <div className="dropdown show">
            <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "11px 20px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter</button>
              <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                <li><Link className="dropdown-item"  >Locum </Link></li>
                <li><Link className="dropdown-item" >Locum</Link></li>
              </ul>
            </div>
          </div> */}
          <div className="dropdown show">
            <div className="dropdown me-3">
              <button
                className="btn btn-second dropdown-toggle"
                style={{ padding: "11px 20px" }}
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {filter ? filter?.toUpperCase() : "All"}
              </button>
              <ul
                className="dropdown-menu"
                style={{ marginTop: "-10px" }}
              >
                <li onClick={() => setFilter("")}>
                  <Link className="dropdown-item">ALL</Link>
                </li>
                <li onClick={() => setFilter("private")}>
                  <Link className="dropdown-item">Private</Link>
                </li>
                <li onClick={() => setFilter("public")}>
                  <Link className="dropdown-item">Public</Link>
                </li>
                <li onClick={() => setFilter("community")}>
                  <Link className="dropdown-item">Community</Link>
                </li>
              </ul>
            </div>
          </div>

          <Link
            className="btn btn-second"
            type="button"
            aria-expanded="false"
            style={{ marginRight: "10px" }}
            to="/recipes/create-recipe"
          >
            Create Recipe
          </Link>
        </div>

        {allRecipes && console.log(allRecipes, "allrecipes from State...................................//")}
        <div className='tab-content'>

          <div className=" tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabIndex="0">
            <div className="table-responsive card">
              <table className="table table-hover table align-middle  card-table table-striped-columns">
                <thead>
                  <tr>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        S.No
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Image
                      </span>
                    </th>
                    <th style={{ width: "18%" }}>
                      <span
                        className="list-sort text-muted"
                      >
                        Name
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        Created Date
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        CREATED BY
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        STATUS
                      </span>
                    </th>
                    <th>
                      <span
                        className="list-sort text-muted"
                      >
                        View
                      </span>
                    </th>
                    {/* <th style={{textAlign: "end"}}>
                    <span
                      className="list-sort text-muted"
                    >
                      Action
                    </span>
                  </th> */}
                  </tr>
                </thead>
                {loader ? <div style={{ marginTop: "5px", marginLeft: "700px" }}><Spinwheel /></div> :
                  <tbody>
                    {allRecipes.length > 0 ? allRecipes?.map((recipe, index) => (
                      <tr className=''>
                        <td>{calculateNextPageStart(selectedPage,limit,index)}</td>
                        <td className='ps-5'> <img
                          src={recipe?.thumbnailImage === ("") || recipe?.thumbnailImage === ("null") ? "/images/recipe2.png" : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${recipe?.thumbnailImage}`}
                          alt={recipe?.name}
                          height={35}
                          width={35}
                          className="rounded-circle me-2"
                        /></td>
                        <td style={{ color: "#609FFF" }} onClick={() => { navigate(`/recipes/recipe-details/${recipe.id}`) }} > <Link>{recipe?.name}</Link></td>
                        <td>{recipe?.dateTime}</td>
                        <td>{recipe?.usersDetails?.userName || recipe?.usersDetails?.email}</td>
                        <td>
                          {recipe?.isPublished && filter !== "community" ? <span className="badge rounded-pill" style={{ background: "#91c788" }}><b>Public</b></span> : ""}
                          {recipe?.isPublished ? "" : <span className="badge rounded-pill bg-light"><b>Private</b></span>}
                          {recipe?.isShared && filter !== "public" ? <span className="badge rounded-pill bg-primary"><b>Community</b></span> : ""}
                        </td>
                        <td className='d-flex'>
                        {recipeId === recipe?.id && downloadeLoader ?
                        <div style={{ marginRight: "5px"}}> <Spinwheel color="black !important" /></div>
                        :
                        <span onClick={()=>handleDownloadRecipe(recipe?.id)}> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download me-3" viewBox="0 0 16 16" style={{ cursor: "pointer" }}>
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                          <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                        </svg></span>}
                        <button className='btn btn-dark btn-sm py-1 px-2 border rounded' data-bs-toggle="modal" data-bs-target="#exampleModal" onClick={() => handleRecipeChart(recipe.id)} >Chart</button></td>
                        {/* <td style={{textAlign: "end"}}>
                        <div className="dropdown">
                          <button className=" border-0 bg-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                              <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                            </svg>
                          </button>
                          <ul className="dropdown-menu" style={{ zIndex: "10" }}>
                            <li ><a className="dropdown-item" href="#">Approve</a></li>
                            <li><a className="dropdown-item" href="#">Reject</a></li>
                          </ul>
                        </div>
                      </td> */}
                      </tr>

                    )) : (<tr>
                      <td align="center" colspan="6" >
                        {/* <h1>Oppss..!</h1>   No, user available to show. */}
                        <img src="/images/noData.png" style={{ width: "200px" }} alt="" width="50%" />
                      </td>
                    </tr>)}
                  </tbody>
                }
              </table>
            </div>
          </div>
        </div>
      </div>
      {totalCount > 10 ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            subContainerClassName={"pages pagination"}
          // forcePage={selectedPage}
          />


        </div>) : ("")}
      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog " style={{ maxWidth: "520px", width: "100%" }}>
          <div className="modal-content " style={{ borderRadius: "0px", border: "1px solid #000" }}>
            <div className="modal-header-list">
              <h1 className="modal-title-list  mb-0 py-3 " id="exampleModalLabel">Nutritional Information</h1>
            </div>
            <div className="modal-body pb-0">
              {/* <div className='row'>

  
              </div>
              <hr className='m-0 bottom-line' /> */}
              {Boolean(singleRecipeDetail?.proximatesDetails?.nutrients?.length) ?
                <>
                  <div className='row'>
                    <div className='col-4'>
                      <h4><b>Nutrients</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>Per 100g</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>per {singleRecipeDetail?.totalQuantity}</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>serving</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>NRV</b></h4>
                    </div>
                    <hr className='m-0 bottom-line' />
                  </div>
                  {singleRecipeDetail?.proximatesDetails?.nutrients?.filter(l => l.value !== 0 || l.value !== null)?.map((v) => {
                    return (
                      <div className='row pt-3'>
                        <div style={{ wordBreak: "break-all" }} className='col-2'>
                          <h6 className='fs-5 fw-bold'>{v.name}</h6>
                        </div>
                        <div className='col-2  text-center'>
                          <p className='fs-5'>{v.unit}</p>
                        </div>
                        <div style={{ wordBreak: "break-all" }} className='col-2'>
                          <h6 className='text-center fs-5 fw-bold'>{v?.hundredValue ? v?.hundredValue?.toString()?.split(".")?.length > 1 ? Number(v?.hundredValue)?.toFixed(2) : v?.hundredValue : v?.value?.toString()?.split(".")?.length > 1 ? Number(v?.value)?.toFixed(2) : v?.value}</h6>
                        </div>
                        <div style={{ wordBreak: "break-all" }} className='col-2'>
                          <h6 className='text-center fs-5 fw-bold'>{v?.value?.toString()?.split(".")?.length > 1 ? Number(v.value)?.toFixed(2) : v?.value}</h6>
                        </div>
                        <div style={{ wordBreak: "break-all" }} className='col-2'>
                          <h6 className='text-center fs-5 fw-bold'>{v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}</h6>
                        </div>
                        <div style={{ wordBreak: "break-all" }} className='col-2'>
                          <h6 className='text-center fs-5 fw-bold'>{v?.nrvValue > 0 ? v?.nrvValue : ""}</h6>
                        </div>
                      </div>
                    )
                  })}
                </>
                : ""
              }
              {Boolean(singleRecipeDetail?.proximatesDetails?.vitamins?.length) ?
                <>
                  <hr className=' bottom-line' />
                  <div className='row'>
                    <div className='col-4'>
                      <h4><b>Vitamins</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>Per 100g</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>per {singleRecipeDetail?.totalQuantity}</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>serving</b></h4>
                    </div>
                    <div className='col-2 text-center'>
                      <h4><b>NRV</b></h4>
                    </div>

                  </div>
                  {singleRecipeDetail?.proximatesDetails?.vitamins?.filter(l => l.value !== 0 || l.value !== null)?.map((v) => {
                    return (
                      <div className='row pt-3'>
                        <div className='col-2'>
                          <h6 className='fs-5 fw-bold'>{v.name}</h6>
                        </div>
                        <div className='col-2 text-center'>
                          <p className='fs-5'>{v.unit}</p>
                        </div>
                        <div className='col-2 text-center'>
                          <h6 className='fs-5 fw-bold'>{v?.hundredValue ? v?.hundredValue?.toString()?.split(".")?.length > 1 ? Number(v?.hundredValue)?.toFixed(2) : v?.hundredValue : v?.value?.toString()?.split(".")?.length > 1 ? Number(v?.value)?.toFixed(2) : v?.value}</h6>
                        </div>
                        <div className='col-2 text-center'>
                          <h6 className='fs-5 fw-bold'>{v?.value?.toString()?.split(".")?.length > 1 ? Number(v.value)?.toFixed(2) : v?.value}</h6>
                        </div>
                        <div className='col-2'>
                          <h6 className='fs-5 fw-bold'>{v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}</h6>
                        </div>
                        <div className='col-2'>
                          <h6 className='fs-5 fw-bold'>{v?.nrvValue > 0 ? v?.nrvValue : ""}</h6>
                        </div>
                      </div>
                    )
                  })}
                </> : ""
              }

              {Boolean(singleRecipeDetail?.proximatesDetails?.minerals?.length) ? <>
                <hr className=' bottom-line' />
                <div className='row'>
                  <div className='col-4'>
                    <h4><b>Minerals</b></h4>
                  </div>
                  <div className='col-2 text-center'>
                    <h4><b>Per 100g</b></h4>
                  </div>
                  <div className='col-2 text-center'>
                    <h4><b>per {singleRecipeDetail?.totalQuantity}</b></h4>
                  </div>
                  <div className='col-2 text-center'>
                    <h4><b>serving</b></h4>
                  </div>
                  <div className='col-2 text-center'>
                    <h4><b>NRV</b></h4>
                  </div>

                </div>
                {singleRecipeDetail?.proximatesDetails?.minerals?.filter(l => l.value !== 0 || l.value !== null)?.map((v) => {
                  return (
                    <div className='row pt-3'>
                      <div className='col-2'>
                        <h6 className='fs-5 fw-bold'>{v.name}</h6>
                      </div>
                      <div className='col-2  text-center'>
                        <p className='fs-5'>{v.unit}</p>
                      </div>
                      <div className='col-2 text-center'>
                        <h6 className='fs-5 fw-bold'>{v?.hundredValue ? v?.hundredValue?.toString()?.split(".")?.length > 1 ? Number(v?.hundredValue)?.toFixed(2) : v?.hundredValue : v?.value?.toString()?.split(".")?.length > 1 ? Number(v?.value)?.toFixed(2) : v?.value}</h6>
                      </div>
                      <div className='col-2 text-center'>
                        <h6 className='fs-5 fw-bold'>{v?.value?.toString()?.split(".")?.length > 1 ? Number(v.value)?.toFixed(2) : v?.value}</h6>
                      </div>
                      <div className='col-2'>
                        <h6 className='fs-5 fw-bold'>{v?.nrvValue > 0 ? v?.nrvPercentage + "%" : ""}</h6>
                      </div>
                      <div className='col-2'>
                        <h6 className='fs-5 fw-bold'>{v?.nrvValue > 0 ? v?.nrvValue : ""}</h6>
                      </div>
                    </div>
                  )
                })}
              </> : ""
              }
              <hr className='m-0 bottom-line' />
              {/* <div className='row pt-3'>                
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>KJ</p>
                </div>
                <div className='col-4'>
                  <h6 className='fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <div className='row'>
                
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Fat of which</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>g</p>
                </div>
                <div className='col-4'>
                  <h6 className='fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <div className='row'>
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Proteint</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>g</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <hr className='my-2 bottom-line' />
              <div className='row'>
                
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>Kcal</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <div className='row'>
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>g</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <hr className='my-2 bottom-line' />
              <div className='row'>
                
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>Kcal</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <div className='row'>
               
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>KJ</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <div className='row'>
                
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>KJ</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div>
              <div className='row'>
              
                <div className='col-5'>
                  <h6 className='fs-5 fw-bold'>Energy</h6>
                </div>
                <div className='col-3'>
                  <p className='fs-5'>KJ</p>
                </div>
                <div className='col-4'>
                  <h6 className='text-center fs-5 fw-bold'>295</h6>
                </div>
              </div> */}
            </div>
            <p className='p-3 text-dark m-0 card-bottom'>
            % values are based on a 2000 calories diet. Your daily values may be higher or lower depending on your energy needs.<br />
            A ratio value of 1 is used for volume to weight conversion during some analysis.<br />
            Some of the ingredients and/or recipes on this database are Crown copyright data drawn from CoFID and licensed under the Open Government Licence v3.0.
          </p>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllRecipes
import React, { useEffect, useState } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link } from "react-router-dom";
import { ImageUrl } from "../../../utils/BaseUrl";
import {
  adminProfileUpdate,
  getAdminDetails,
  uploadImage,
} from "../../../utils/ApiEndpointFunctions";
import { useFormik } from "formik";
import { IngredientSchema, profileInitialValue } from "../../common/Validation";

export default function Profiledetails() {
  const [userData, setUserData] = useState();
  const [isEdit, setIsEdit] = useState(true);
  const [image, setImage] = useState();
  const { values, handleChange, handleSubmit, setValues, setFieldValue } =
    useFormik({
      initialValues: {
        firstName: "",
        lastName: "",
        email: "",
        profilePic: "",
        password: "",
        confirmPassWord: "",
      },
      onSubmit: async (val) => {
        const res = await adminProfileUpdate(val);
        if (res.status == 200) {
          alert("Profile Updated successfully");
        }
      },
      validationSchema: IngredientSchema,
    });
  const getProfileData = async () => {
    try {
      const res = await getAdminDetails();
      if (res.status === 200 || res.status === 201) {
        setUserData(res.data.data);
        setFieldValue("firstName", res.data.data.firstName);
        setFieldValue("lastName", res.data.data.lastName);
        setFieldValue("profilePic", res.data.data.profilePic);
        setFieldValue("email", res.data.data.email);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // const profileFormik = useFormik({
  //   initialValues: profileInitialValue,
  //   onSubmit: handleSubmit,
  //   validationSchema: IngredientSchema,
  // })
  const onFileChange = async (e) => {
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    const type = file.type.split("/")[1];
    const imageType = ["jpeg", "jpg", "png"];
    const validImageType = imageType.includes(type);
    if (!validImageType) {
      alert("upload a valid image : jpeg, png, jpg");
      return;
    } else {
      if (file.size > 1000001) {
        alert("image size is more than 1 MB");

        return;
      } else {
        // const formdata = new FormData();
        // formdata.append("file", file);
        // const response = await uploadBannerImageApi(formdata);
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("type", "profileImage");
        const response = await uploadImage("/users/fileUpload", formdata);
        const data = response.data;
        // console.log(data,"asdfasdfasdfasdfasdfasd")
        setFieldValue("profilePic", data.data);
        setImage(data.data);
        //to set the banner image in the next div
        // let fr = new FileReader();
        // fr.readAsDataURL(file);
        // fr.onload = function () {
        //   setBannerData(fr.result);
        // };
      }
    }
  };

  console.log(
    `${
      image
        ? "con 1"
        : values.profilePic
        ? "condi 2"
        : "url(/images/dummy-image.jpg)"
    }`
  );
  // console.log(userData ,"dkdkdkdkkd")
  useEffect(() => {
    getProfileData();
  }, []);
  console.log(values, "asdfasdfasdfasdfasdfasd");
  console.log(image, "asdfasdfasdfasdfasdfasd");
  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content vh-100 vh_md-auto overflow-y-a">
        {/* HEADER */}
        <div className="header">
          <div className="container-fluid">
            <div className="row mt-6">
              <div className="col-lg-7 col-md-10 col-12 mx-auto card p-5 rounded">
                <div className="row">
                  <div className="col-md-12 positon-relative">
                    <div
                      className=" text-center"
                      style={{
                        position: "absolute",
                        top: "-60px",
                        left: "37%",
                      }}
                    >
                      <input
                        id="profilePic"
                        name={`profilePic`}
                        type="file"
                        accept="image/png, image/jpeg"
                        className="form-control"
                        onChange={onFileChange}
                        hidden={true}
                      />
                      <label
                        className=""
                        htmlFor="profilePic"
                        style={{
                          width: "100px",
                          height: "100px",
                          border: "2px solid #91c788",
                          backgroundSize: "cover",
                          cursor: "pointer",
                          borderRadius: "50px",
                          backgroundImage: `${
                            values.profilePic
                              ? "url(" +
                                encodeURI(`${ImageUrl}` + values.profilePic) +
                                ")"
                              : "url(/images/dummy-image.jpg)"
                          }`,
                        }}
                      ></label>
                      <h1 className="mb-1 mt-2">{userData?.firstName}</h1>

                      <p>{userData?.email}</p>
                    </div>
                    <div
                      style={{ position: "absolute", right: "0", top: "-12px" }}
                    >
                      <button
                        type="button"
                        className="btn btn-second me-3"
                        style={{ padding: "10px 15px" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          fill="currentColor"
                          class="bi bi-pencil-square  text-white"
                          viewBox="0 0 16 16"
                          onClick={() => {
                            setIsEdit(false);
                            alert("you can edit the profile");
                          }}
                        >
                          <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                          <path
                            fill-rule="evenodd"
                            d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
                          />
                        </svg>
                      </button>
                    </div>
                    <h2 className="mt-7">Account Details</h2>
                    <hr />
                    <label className="form-label" htmlFor="first">
                      First Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstName"
                      disabled={isEdit}
                      onChange={handleChange}
                      value={values.firstName}
                      id="first"
                      placeholder="Username"
                    />
                    <br />
                    <label className="form-label" htmlFor="last">
                      Last Name
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      name="lastName"
                      disabled={isEdit}
                      onChange={handleChange}
                      value={values.lastName}
                      id="last"
                      placeholder="Username"
                    />
                    <br />
                    <label className="form-label" htmlFor="email">
                      Email
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      name="email"
                      onChange={handleChange}
                      disabled={isEdit}
                      value={values.email}
                      id="email"
                      placeholder="Email"
                    />
                    <br />
                    <label className="form-label" htmlFor="pass">
                      Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      disabled={isEdit}
                      value={values.password}
                      id="pass"
                      placeholder="Password"
                    />
                    <br />
                    {/* <label className="form-label" htmlFor="passc">
                      Password
                    </label>
                    <input
                      className="form-control"
                      type="password"
                      name="password"
                      onChange={handleChange}
                      disabled={isEdit}
                      id="passc"
                      value={values.confirmPassWord}
                      placeholder="Password"
                    /> */}
                  </div>
                </div>
                <div className="text-end">
                  <button
                    type="button"
                    onClick={handleSubmit}
                    className="btn-custom mt-4"
                    style={{ width: "fit-content" }}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

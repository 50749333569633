import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const BreadCrumb = (props) => {
    let data = useLocation();
    let allPaths = data.pathname.split('/').filter((item) => {
        return item !== "";
    });

    allPaths = allPaths.filter((item) => {
        return isNaN(Number(item))
    });


    const allValues = allPaths.map((item) => {
        return item.split('-').join(" ")
    })
    // console.log(allPaths.slice(0, 2).join("/"))
    return (
        <div>
            <nav className="mb-5">
                <ol className="breadcrumb">

                    {
                        allPaths && allPaths.map((item, index, arr) => {
                            return <>{index == 0 ?

                                <li key={`34234-${index}`} className="breadcrumb-item"><Link to={`/${props?.status?arr.slice(0, index + 1)[0]:arr.slice(0, index + 2).join("/")}`} className="breadcrumb-item" > <i className="fe fe-home"></i> {allValues[index][0].toUpperCase() + allValues[index].slice(1)}</Link> </li> :

                                <li key={`3423411-${index}`} className="breadcrumb-item active" aria-current="page">
                                    

                                    {allPaths?.length - 1 == index ? <span className="breadcrumb-item active" aria-current="page">

                                        {allValues[index][0].toUpperCase() +
                                            allValues[index].slice(1)}
                                    </span> : <Link to={`/${arr.slice(0, index + 1).join("/")}`} >


                                        {allValues[index][0].toUpperCase() +
                                            allValues[index].slice(1)}


                                    </Link>}


                                </li>

                            }

                            </>
                        }

                        )
                    }


                    {/* { isDetails?<li className="breadcrumb-item active" aria-current="page">
                    Ingredients Details
                </li>:""} */}
                </ol>
            </nav>
            
        </div>
    )
}

export default BreadCrumb

import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { useAdminContext } from '../../context/adminContext';
import { getAdminDetails } from '../../utils/ApiEndpointFunctions';
import { ImageUrl } from '../../utils/BaseUrl';

const Header = () => {
  const [adminInfo,setAdminInfo] = useState();
  const data= useLocation();
  const allPaths =   data.pathname.split('/');


 const {setAdminDetail} = useAdminContext();



  const adminDetail=async ()=>{
    const {data} = await getAdminDetails()
    setAdminInfo(data.data);
    setAdminDetail(data.data) 
  }
  const token = localStorage.getItem("token")
  useEffect(()=>{
    if(token){
      adminDetail()
    }
  },[token])
  return (
    <>  
   {  token&&<div className="main-content  overflow-y-a">
       <div className="header">
          <div className="container-fluid">
            {/* Body */}
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                  {/* Pretitle */}
                  <h6 className="header-pretitle">Overview</h6>
                  {/* Title */}
                  <h1 className="header-title">{allPaths[1][0]?.toUpperCase() +
        allPaths[1]?.slice(1).split('-').join(" ")}</h1>
                </div>
                <div className="col-auto">
                  {/* Button */}
                  <div className="dropdown">
                    {/* <Link
                      className="btn btn-second"
                      type="button"
                      aria-expanded="false"
                      style={{ marginRight: "10px" }}
                      to="/ingredients/approved-ingredients/add-ingredient"
                    >
                      Add Ingredient
                    </Link> */}

                    {/* <span className="btn btn-second me-3 " onClick={() => { navigate("/addessential") }}>Import Ingredient</span>
                    <span className="btn btn-second me-3 " onClick={() => { navigate("/addessential") }}>Add Ingredient</span> */}
                    <button
                      className="btn btn-second dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={`${
                          adminInfo?.profilePic
                            ? ImageUrl + "/" + adminInfo?.profilePic
                            : process.env.PUBLIC_URL +
                              "/images/person-circle.svg"
                        }`}
                        alt=""
                        height={24}
                        width={24}
                        className="rounded-circle me-2"
                      />
                      {adminInfo?.firstName+" "+adminInfo?.lastName}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={"/profiledetails"}>
                          Profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>{" "}
              {/* / .row */}
            </div>{" "}
            {/* / .header-body */}
          </div>
        </div> 
    </div>}
    </>
  )
}

export default Header

import React from 'react'
import { BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'


import Forgotpassword from '../components/unauthorized/signin/Forgotpassword'
import Resetpassword from '../components/authorized/resetpassword/Resetpassword'
import Dashboard from '../components/authorized/dashboard/Dashboard'

import Login from '../pages/Login'
import Users from '../components/authorized/users/Users'

import Profiledetails from '../components/authorized/profiles/Profiledetails'
import Ingredients from '../components/authorized/ingredients/Ingredients'
import AllIngredients from '../components/authorized/ingredients/AllIngredients'
import IngredientDetails from '../components/authorized/ingredients/IngredientDetails'
import Recipes from '../components/authorized/recipes/Recipes'
import AllRecipes from '../components/authorized/recipes/AllRecipes'
import RecipeDetails from '../components/authorized/recipes/RecipeDetails'
import AddRecipe from '../components/authorized/recipes/AddRecipe'
import AddIngredients from '../components/authorized/recipes/AddIngredients'
import Header from '../components/common/Header'
import Sidebar from '../Layout/Sidebar'
import HideMineralsAndVitamins from '../components/authorized/hideMineralsAndVitamins/HideMineralsAndVitamins'
import CommonIngredient from '../components/authorized/ingredients/CommonIngredient'
import Reports from '../pages/Reports'
import CommonIngredientTable from '../components/authorized/ingredients/CommonIngredientTable'
import UserLink from '../components/authorized/users/UserLink'

function PrivateRoute({ children }) {
  const auth = localStorage.getItem("token");
  return auth ? children : <Navigate to="/" />;
}

function PublicRoute({ children }) {
  const auth = localStorage.getItem("token");
  return !auth ? children : <Navigate to="/dashboard" />;
}

export default function Mainroute() {


    
    return (
      <BrowserRouter>
      <div>


       <Sidebar  />
      <Header/>
      
        <Routes>
          <Route  exact path="/users" element={<PrivateRoute><UserLink /></PrivateRoute>} >
          <Route exact path="active-users" element={<PrivateRoute><Users /></PrivateRoute>} />
          <Route exact path="deleted-users" element={<PrivateRoute><Users /></PrivateRoute>} />
          </Route>

          <Route exact path='/Profiledetails' element={<PrivateRoute><Profiledetails /></PrivateRoute>} />
          <Route path="/ingredients/approved-ingredients/add-ingredient" element={<PrivateRoute><CommonIngredient /></PrivateRoute>} />
          <Route path="/recipes/create-recipe" element={<PrivateRoute><AddRecipe /></PrivateRoute>} />
          <Route path="/ingredients/approved-ingredients/ingredient-details/:id" element={<PrivateRoute><IngredientDetails /></PrivateRoute>} />
          <Route path="/ingredients/pending-ingredients/ingredient-details/:id" element={<PrivateRoute><IngredientDetails /></PrivateRoute>} />
          <Route path="/ingredients/rejected-ingredients/ingredient-details/:id" element={<PrivateRoute><IngredientDetails /></PrivateRoute>} />
          <Route path="/recipes/details/:id" element={<PrivateRoute><RecipeDetails /></PrivateRoute>} />
          <Route path="/recipes/recipe-details/:id" element={<PrivateRoute><AddIngredients /></PrivateRoute>} />
          <Route path="/ingredients/approved-ingredients/edit-ingredient/:id" element={<PrivateRoute><CommonIngredient /></PrivateRoute>} />
          <Route path="/ingredients/pending-ingredients/edit-ingredient/:id" element={<PrivateRoute><CommonIngredient /></PrivateRoute>} />
         
          <Route path="*" element={<PrivateRoute><Dashboard/></PrivateRoute>} />


           <Route exact path='ingredients/'element={<PrivateRoute><Ingredients /></PrivateRoute>}>
           <Route exact path='approved-ingredients' element={<PrivateRoute><CommonIngredientTable /></PrivateRoute>} />
           <Route exact path='pending-ingredients' element={<PrivateRoute><CommonIngredientTable /></PrivateRoute>} />
           <Route exact path='rejected-ingredients' element={<PrivateRoute><CommonIngredientTable /></PrivateRoute>} />
           <Route path="*" element={<PrivateRoute><AllIngredients /></PrivateRoute>} />
          </Route>


           <Route exact path='minerals-vitamins'element={<PrivateRoute><HideMineralsAndVitamins /></PrivateRoute>}/>
           <Route exact path='/reports'element={<PrivateRoute><Reports /></PrivateRoute>}/>
           <Route exact path='recipes/'element={<PrivateRoute><Recipes /></PrivateRoute>}>
           <Route exact path='' element={<PrivateRoute><AllRecipes /></PrivateRoute>} />
           <Route path="*" element={<PrivateRoute><AllRecipes /></PrivateRoute>} />
          </Route>
          
          <Route exact path="/forgotpassword" element={<Forgotpassword />} />
          <Route exact path="/resetpassword" element={<Resetpassword />} />

          <Route exact path="/login" element={<PublicRoute><Login /></PublicRoute>} />
            <Route path="/*" element={<PublicRoute><Login /></PublicRoute>}/>
            <Route exect path="/"element={<PublicRoute><Login /></PublicRoute>} />
        </Routes>
        </div>

      </BrowserRouter>
    )}


import React, { useState } from 'react';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import Logo from "../logo.svg"
import { useLocation } from 'react-router-dom';


export default function Sidebar() {
    const navigate = useNavigate();
    const location = useLocation()
    const token = localStorage.getItem("token")
    const handleLogout = () => {
        localStorage.removeItem("token");
        
        window.location.reload();
        navigate("/");
    }

    const path = location.pathname
    const { id } = useParams() 

    return (
       <>{token&&<>
            <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
                <div className="container-fluid position-relative">
                    {/* Toggler */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#000" className="bi bi-list" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button>
                    {/* Brand */}
                    <Link className="navbar-brand mb-4" to="/dashboard">
                        <img src={Logo} className="navbar-brand-img mx-auto pe-3 " alt="..." />
                    </Link>

          {/* Collapse */}
          <div className="collapse navbar-collapse" id="sidebarCollapse">
            {/* Form */}
            {/* <form className="mt-4 mb-3 d-md-none">
                            <div className="input-group input-group-rounded input-group-merge input-group-reverse">
                                <input className="form-control" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-text">
                                    <span className="fe fe-search" />
                                </div>
                            </div>
                        </form> */}
                        {/* Navigation */}
                        <ul className="navbar-nav mb-5">
                            <li className={`nav-item ${path.includes("dashboard") ?  "active" : "" }`}>
                                <Link to="/dashboard" className="nav-link ms-3" role="button" aria-expanded="true" aria-controls="sidebarDashboards">
                                    Dashboard
                                </Link>

                            </li>
                           
                            <li className={`nav-item ${path.includes("user") ?  "active" : "" || path.includes("users") ?  "active" : "" || path.includes("profile") ?  "active" : "" }`}>
                                <Link to="/users/active-users" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                                    Users 
                                  
                                </Link>
                            </li>
                            {/* <li className={`nav-item ${path == '/ingredients/approved-ingredients' ?  "active" : "" || path == '/ingredients/pending-ingredients' ? "active" : "" || path == '/ingredients/approved-ingredients/add-ingredient' ? "active" : "" || path == `/ingredients/approved-ingredients/edit-ingredient/${id}` ? "active" : "" ||  path == `/ingredients/rejected-ingredients` ? "active" : "" || path == `/ingredients/ingredient-details/${id}` ? "active" : ""}`}>
                                <Link to="/ingredients/approved-ingredients" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                                    Ingredients 
                                </Link>
                            </li> */}

                            <li className={`nav-item ${path.includes("ingredients") ?  "active" : "" || path.includes("ingredient") ?  "active" : ""  }`}>
                                <Link to="/ingredients/approved-ingredients" className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarPages">
                                    Ingredients 
                                </Link>
                            </li>
                            {/* <li className={`nav-item ${path == '/recipes'?"active":"" ||  path == `/recipes/details/${id}` ? "active" : ""  || path == '/recipes/create-recipe'?"active":""}`  }>
                                <Link className="nav-link ms-3 " to="/recipes">
                                    Recipes
                                </Link>
                            </li> */}

                            <li className={`nav-item ${path.includes("recipes") ?  "active" : "" || path.includes("recipe") ?  "active" : ""  }`}>
                                <Link className="nav-link ms-3 " to="/recipes">
                                    Recipes
                                </Link>
                            </li>
                            <li className={`nav-item ${path.includes("minerals") ?  "active" : "" || path.includes("minerals") ?  "active" : ""  }`}>
                                <Link className="nav-link ms-3 " to="/minerals-vitamins">
                                Manage Nutrients
                                </Link>
                            </li>
                            <li className={`nav-item ${path.includes("reports") ?  "active" : "" || path.includes("reports") ?  "active" : ""  }`}>
                                <Link className="nav-link ms-3 " to="/reports">
                                Reports
                                </Link>
                            </li>
                            {/* <li className="nav-item">
                                <Link className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarAuth" href >
                                    Users management
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link className="nav-link ms-3" role="button" aria-expanded="false" aria-controls="sidebarAuth" href >
                                    Page Management
                                </Link>
                            </li>  */}
            </ul>

            <div className="logout">
              <Link
                to="/"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalout"
                className="mb-4 mt-3 "
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="bi bi-box-arrow-left"
                  viewBox="0 0 16 16"
                >
                  <path
                    fillRule="evenodd"
                    d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z"
                  />
                  <path
                    fillRule="evenodd"
                    d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                  />
                </svg>{" "}
                Log Out
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Log out popup code start here */}
      <div
        className="modal fade"
        id="exampleModalout"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body pe-0 ps-0 block-user">
              <div className="logout-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <g transform="rotate(180 12 12)">
                    <g
                      id="feLogout0"
                      fill="none"
                      fillRule="evenodd"
                      stroke="none"
                      strokeWidth="1"
                    >
                      <g id="feLogout1" fill="currentColor">
                        <path
                          id="feLogout2"
                          d="M3 5c0-1.1.9-2 2-2h8v2H5v14h8v2H5c-1.1 0-2-.9-2-2V5Zm14.176 6L14.64 8.464l1.414-1.414l4.95 4.95l-4.95 4.95l-1.414-1.414L17.176 13H10.59v-2h6.586Z"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <h1 className="mb-0">Log Out?</h1>
              <p>Are you sure you want to log out?</p>
            </div>
            <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
              <button
                type="button"
                className="btn-custom w-auto"
                onClick={handleLogout}
              >
                LOG OUT
              </button>
              <button
                type="button"
                className="btn-custom btn-custom-ops w-auto text-white"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                CANCEL
              </button>
            </div>
          </div>
        </div>
      </div>
    </>}
    </> 
  );
}

import React, { useState, useEffect } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  AddEssentialIntialValue,
  essentialValidation,
  updateRecipeIngredientInitialValue,
  updateRecipeIngredientSchema,
} from "../../common/Validation";
import { useFormik } from "formik";
import { addEssential, adminGetAllIngredients, createRecepieIngredient, uploadImage } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../context/adminContext";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import { adminGetRecipeById } from "../../../utils/ApiEndpointFunctions";
import Select from 'react-select';
import BreadCrumb from "../../common/BreadCrumb";

export default function RecipeDetails() {
  // const { adminDetail, } = useAdminContext()
  // const [signInWheel, setSignInWheel] = useState(false)
  const [loader, setLoader] = useState(false);
  const [recipeDetails, setRecipeDetails] = useState({});

  const navigate = useNavigate();

  const { id } = useParams();
  console.log(
    id,
    "id from  I Details.......................................//"
  );

  const getRecipeDetails = async () => {
    try {
      setLoader(true);
      const res = await adminGetRecipeById(id);
      console.log(
        res?.data?.data,
        "recipe details userssssssssssssssssssss.....//"
      );
      setRecipeDetails(res?.data?.data);
      setLoader(false);
    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  };

  useEffect(() => {
    getRecipeDetails();
    // console.log("users effect");
    // const data = JSON.stringify({ x: 5, y: 6 });
    // console.log(data, "users effect data");
    // const data1 = JSON.parse(data);
    // console.log(data1, "users effect data1");
  }, []);

  const [isBlockVisible, setBlockVisible] = useState(false);

  const toggleBlockVisibility = () => {
    setBlockVisible(!isBlockVisible);
  };




 
  const [getAllIngredents, setGetAllIngredents] = useState();
  const [handleInputChange, setHandleInputChange] = useState("");
  const [allName,setAllName] = useState([])
  const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState({ imageName: "", fileSize: "" })

  // const [selectedOption, setSelectedOption] = useState(null);
  // const [unit,setUnit] = useState('l')
  // const [quantity,setquantity] = useState('')
  // const [notes,setNotes] = useState('')


  const [finalIngredientsArray, setFinalIngredientsArray] = useState([])
  
  const formik = useFormik({
    initialValues: updateRecipeIngredientInitialValue,
    onSubmit: updateRecipe,
    validationSchema: updateRecipeIngredientSchema

  })

 
  const getAllIngredients = async () => {

    const res = await adminGetAllIngredients(100, 1, handleInputChange, 'approved');

    const options = res.data?.data.map(item => ({
      value: item.name,
      label: item.name,
      id: item.id,
    }));

    setGetAllIngredents(options);
  }

 
  useEffect(() => {
    getAllIngredients();
  }, [handleInputChange])

  useEffect(() => {
    getRecipeDetails();

  }, []);

 
 


// console.log(formik)
  function updateRecipe() {

    setFinalIngredientsArray([...finalIngredientsArray, {
      id: formik.values.ingredientIds.id,
      quantity:formik.values.ingredientIds.quantity ,
      quantityUnit: formik.values.ingredientIds.unit,
      notes: formik.values.ingredientIds.notes,
      name:formik.values.ingredientIds.value,
      image:formik.values.ingredientIds.thumbnailImage
    }])
    

    let docs = document.getElementById("close-img")
    docs.click()
    formik.setFieldValue("ingredientIds.id","")
   
    formik.setFieldValue("ingredientIds.value", "")
    formik.setFieldValue("ingredientIds.quantity", "")
    formik.setFieldValue("ingredientIds.unit", "l")
    formik.setFieldValue("ingredientIds.notes", "")

  }

  const updateIngredient =async ()=>{
    const res = await createRecepieIngredient({id,ingredientIds:finalIngredientsArray})
    if(res.status == 200){
      alert("Ingredient added successfully")
    }
  }


  const selectHandleChange = (e) => {
    // console.log(e)
    formik.setFieldValue("ingredientIds.id", e.id)
    formik.setFieldValue("ingredientIds.value", e.value)
  }
  const onFileChange = async (e) => {

    let file = e.target.files[0];
    if (!file) {
      return
    }
    setImage((e) => {
      return { ...e, imageName: file.name }
    });
    const type = file.type.split("/")[1];
    const imageType = ["jpeg", "jpg", "png"];
    const validImageType = imageType.includes(type);
    if (!validImageType) {
      alert("upload a valid image : jpeg, png, jpg");
      return;
    } else {
      if (file.size > 1000001) {
        alert("image size is more than 1 MB")

        return;
      } else {
        const formdata = new FormData();
        formdata.append("file", file);
        formdata.append("type", "recipe")
        const response = await uploadImage('/users/fileUpload', formdata);
        const data = response.data;

        formik.setFieldValue("ingredientIds.thumbnailImage",data.data)
        setImage((e) => {
          return { ...e, fileSize: data.fileSize }
        });
        // //to set the banner image in the next div
        // let fr = new FileReader();
        // fr.readAsDataURL(file);
        // fr.onload = function () {
        //   setBannerData(fr.result);
        // };

      }


    }

  };






  return (
    <>
      {/* <Sidebar /> */}
      {/* {recipeDetails &&
        console.log(
          recipeDetails,
          "recipeDetails from Details Page.........................//"
        )} */}
      <div className="main-content">
        {/* HEADER */}
        
        {/* / .header */}
        {/* CARDS */}
        <div className="container-fluid">
        <BreadCrumb status={true}/>
          {/* <nav className="mb-5">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/recipes">
                  <i className="fe fe-home"></i> Recipes
                </Link>
              </li>
              <li className="breadcrumb-item">
                <Link
                  to={`/recipes/details/${id}`}
                  className="breadcrumb-item active"
                >
                  Recipe Details
                </Link>
              </li>
               
            </ol>
          </nav> */}
          <h2 className="report m-0">Recipe Information</h2>
          
          <hr style={{ marginTop: "15px" }} />
          {loader ? (
            <div style={{ marginTop: "5px", marginLeft: "700px" }}>
              <Spinwheel />
            </div>
          ) : (
            <div className="row mb-5">
              <div className="col-xl-4 pt-3" style={{wordBreak: "break-word"}}>
                <div className="d-flex justify-content-between align-items-center" >
                <h3><b>{recipeDetails?.name}</b></h3>
                <a href="" class="btn btn-second"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-pencil-square" viewBox="0 0 16 16">
  <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"/>
  <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"/>
</svg></a>
                </div>
                <p>
                  <span>Created by : </span> 
                  <span>{recipeDetails?.usersDetails?.email}</span>
                </p>
                <p>
                  <span>Note : </span> <span>{recipeDetails?.note}</span>
                </p>
                <img
                  // src="/images/recipe2.png"
                  // src={`https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${recipeDetails?.thumbnailImage}`}

                  src={
                    recipeDetails?.thumbnailImage === "" ||
                    recipeDetails?.thumbnailImage === "null" ||
                    recipeDetails?.thumbnailImage ===
                      "https://www.linkedin.com/"
                      ? "/images/recipe2.png"
                      : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${recipeDetails?.thumbnailImage}`
                  }
                  alt=""
                  width="290"
                  height="160"
                  className="img-thumbnail d-block mb-2"
                />
                <small>
                  You can search and 'add ingredients ' to your meal from the
                  section given below
                </small>

                {recipeDetails &&
                  recipeDetails?.ingredientDetails?.map((item) => (
                    <div className="patato-mash mt-3">
                      <div className="d-flex mt-3 mb-2 align-items-center">
                        <div>
                          <img
                            src={`${encodeURI(
                              `${ImageUrl}` + item.thumbnailImage
                            )}`}
                            alt=""
                            width="30"
                            height="30"
                            className="rounded-circle me-3"
                          />
                        </div>
                        <div className="d-flex">
                          <h3 className="mb-0">{item.name} </h3>{" "}
                          <span className="ms-3">{item.quantity +" " + item.quantityUnit}</span>
                        </div>
                      </div>
                     <p className="mb-0">{item.notes}</p>
                    </div>
                  ))}
                <br />
                <button
                  className="btn btn-second mt-4"
                  onClick={toggleBlockVisibility}
                >
                  View
                </button>
                <button
                  className="btn btn-second mt-4 mx-4"
                  data-bs-toggle="modal"
                  data-bs-target="#addIngredientModal"
                >
                  Add ingredient
                </button>
              </div>
              <div className="col-xl-4  pt-3">
                {isBlockVisible && (
                  <div
                    style={{
                      padding: "5px",
                      boxShadow: "0px 3px 6px #00000029",
                      display: "block",
                      borderRadius: "12px 12px 0 0",
                      border: "1px solid #000",
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th colSpan={6} style={{ backgroundColor: "#000" }}>
                            <h1
                              style={{
                                color: "#fff",
                                textAlign: "center",
                                paddingTop: "15px",
                              }}
                            >
                              Nutritional Information
                            </h1>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ height: "10px" }}></td>
                        </tr>
                        <tr>
                          <td>
                            <strong>Nutrients</strong>
                          </td>
                          <td />
                          <td />
                          <td align="center">
                            <strong>(199g)</strong>
                          </td>
                          <td />
                          <td />
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <hr style={{ border: "6px solid #000" }} />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={3}>
                            <h3 style={{ margin: "0 auto" }}>
                              Calories per serving
                            </h3>
                          </td>
                          <td colSpan={3}>
                            <h1
                              style={{
                                margin: "0 auto",
                                textAlign: "end",
                                paddingRight: "10px",
                              }}
                            >
                              142
                            </h1>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <hr className="bottom-line" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Nutrients</b>
                          </td>
                          <td />
                          <td>
                            <b>per 100g</b>
                          </td>
                          <td>
                            <b>per 199g</b>
                          </td>
                          <td>
                            <b>serving</b>
                          </td>
                          <td align="center">
                            <b>NRV</b>
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <hr className="bottom-line" />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <b>Energy</b>
                          </td>
                          <td>KJ</td>
                          <td>295</td>
                          <td>588</td>
                          <td>7%</td>
                          <td>8400</td>
                        </tr>
                        <tr>
                          <td />
                          <td>Kcal</td>
                          <td>71</td>
                          <td>142</td>
                          <td>7%</td>
                          <td>2000</td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <hr className="bottom-line" />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={6}>
                            <small>
                              * Persent Daily Values are based on a 2,000
                              calorie diet. Your Daily Values may be higher or
                              lower depending on your energy needs
                            </small>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
              </div>
              <div className="col-xl-4 pt-3">
                <div className="patato-mash-report" style={{borderColor: "#000"}}>
                  <div className="pb-3 pt-5 px-4">
                    <div className="d-flex  mb-2 align-items-center">
                      <div>
                        <img
                          src="/images/break.jpg"
                          alt=""
                          width="30"
                          height="30"
                          className="rounded-circle me-3"
                        />
                      </div>
                      <div>
                        <h3 className="mb-0">Potato Mash with Butter </h3>
                      </div>
                    </div>
                    <p className="mb-0">
                      Note : Lorem ipsum dolor sit amet, consectetur adipiscing
                      elit, sed do
                    </p>
                  </div>
                  <div className="card-body p-0">
                    <div className="modal-header-list">
                      <h1
                        className="modal-title-list  mb-0 py-3 "
                        id="exampleModalLabel"
                      >
                        Nutritional Information
                      </h1>
                    </div>
                    <div className=" p-2">
                      <div className=" heading d-flex justify-content-between align-items-center">
                        <h3><b>Nutrition</b></h3>
                        <h3><b>Per 100g</b></h3>
                      </div>
                      <hr className="m-0 bottom-line" />
                      <div className="row pt-3">
                        
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">KJ</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <div className="row">
                        
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">
                            Fat of which
                          </h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">g</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Proteint</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">g</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <hr className="my-2 bottom-line" />
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">Kcal</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">g</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <hr className="my-2 bottom-line" />
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">Kcal</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">KJ</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">KJ</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <h6 className="fs-5 fw-bold">Energy</h6>
                        </div>
                        <div className="col-3">
                          <p className="fs-5">KJ</p>
                        </div>
                        <div className="col-4">
                          <h6 className="text-center fs-5 fw-bold">295</h6>
                        </div>
                      </div>
                      <hr className="my-2 bottom-line" />
                      <p className=" text-dark m-0 card-bottom">
                        * Present daily values are based on a 2,000 calorie
                        diet. Your daily values may be higher or lower depanding
                        on your Energy
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* modal */}
      <div
        className="modal fade"
        id="addIngredientModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="false"
      >
        <form>
          <div
            className="modal-dialog modal-dialog-centered "
            style={{ width: "420px" }}
          >
            <div className="modal-content">
              <div>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{fontSize: "20px"}}></button>
              </div>
              <div className="modal-body">
                <a href="" className="close-img" id="close-img" data-bs-dismiss="modal">
                  <img src="./assets/images/close.svg" alt="" srcSet="" />
                </a>
                <div className="text-center">
                  <img src="./assets/images/bkimg.PNG" width="45" alt="" />
                </div>
                <h4 className="text-center" >Add Ingredients </h4>
                <div className="d-flex justify-content-between">
                      <label className="upload">
                        <span> Upload Recipe image Here</span>
                        <input
                          type="file"
                          name="myImage"
                          accept="image/png, image/gif, image/jpeg"
                          style={{ display: "none" }}
                          onChange={onFileChange}
                        />
                      </label>
                    </div>
                    <div className="d-flex justify-content-between after-upload-img mt-2">
                      <div className="me-3">
                        <img
                          src={`${encodeURI(
                            `${ImageUrl}` + formik.values.ingredientIds.thumbnailImage
                          )}`}
                          width="44"
                          height="44"
                          alt=""
                          className="rounded"
                        />
                      </div>
                      <div style={{ textAlign: "left", width: "100%" }}>
                        <h4 className="mb-0">{image?.imageName || "Image Name Here"}</h4>
                        <h5>{image?.fileSize || "Image size"}</h5>
                      </div>
                      <div>
                        <a href="">X</a>
                      </div>
                    </div>
                <div className="position-relative mt-4">
                  <Select

                    className="form-control"
                    id="select-ingredent"
                    placeholder="Ingredeints "
                    isSearchable={true}
                    defaultValue={"a"}
                    name='ingredientIds.id'
                    value={
                      formik.values.ingredientIds.value === "" 
                        ? null
                        : { label: formik.values.ingredientIds.value, value: formik.values.ingredientIds.id }
                    }
                    onChange={(e) => selectHandleChange(e)}
                    onInputChange={setHandleInputChange}
                    options={getAllIngredents}
                  />

                  {/* <select
                    className="form-control"
                    type="text"
                    name="name"
                    // value={modalData.id}
                    // onChange={handleIngredeintSelect}
                    // value={createRecepieFormik?.values.name}

                    id="name"
                    placeholder="Breakfast Recipe 1"
                  > */}
                  {/* <option value="" hidden>
                      -
                    </option> */}
                  {/* {ingredientData?.map((v) => {
                      return <option value={v.id}>{v.name}</option>;
                    })} */}
                  {/* </select> */}
                  <label htmlFor="name" className="input-label">
                    Select Ingredient
                  </label>

                  {formik.touched.ingredientIds?.id &&
                    formik.errors.ingredientIds?.id ? (
                    <div className="req-error">
                      {formik.errors.ingredientIds?.id}
                    </div>) : ""}
                </div>

                <div className="row mt-4">
                  <div className="col-5">
                    <select className="form-select" name="" id="">
                      <option value="ml">ml</option>
                      <option value="l">g</option>
                    </select>
                  </div>
                  <div className="col-7">
                    <input
                      className="form-control"
                      type="text"
                      name="servingSize"
                      value={"dd"}
                      // onChange={(e) =>
                      //   setModalData({ ...modalData, quantity: e.target.value })
                      // }
                      //   onChange={(e) => {
                      //     handleChange(e);
                      //   }}
                      //   value={createRecepieFormik.values.servingSize.data || ""}
                      id=""
                      placeholder="Serving size"
                    />
                  </div>
                </div>

                <label className="form-label mt-3" htmlFor="add">
                  Add recipe note.
                </label>
                <textarea
                  className="form-control"
                  name="note"
                  //   onChange={(e) => {
                  //     globalHandleChange(e, createRecepieFormik);
                  //   }}
                  //   value={createRecepieFormik.values.note || ""}
                  // value={modalData.notes}
                  // onChange={(e) => setModalData({ ...modalData, notes: e.target.value })}
                  // onChange={handleNote}
                  id=""
                  cols="30"
                  rows="3"
                >
                  Enter Notes here Max 500 words.
                </textarea>
                <div className="text-center">
                  <button
                    className="btn_custome mt-4"
                    type="button"
                    // onClick={handleIngredients}
                  >
                    Add Ingredient
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

import React, { useEffect, useState } from 'react'
import { getshowAndHideVitamisAndMinerals, showAndHideVitamisAndMinerals } from '../../../utils/ApiEndpointFunctions'
import Spinwheel from '../../common/Spinwheel'

const HideMineralsAndVitamins = () => {
    const [minerals,setMinerals] = useState() 
    const [vitamins,setVitamins] = useState() 
    const [getStatus,setGetStatus]  = useState()
    const [loading,setLoading] = useState(true)

    const updateStatus = async()=>{

      const response = await showAndHideVitamisAndMinerals({isMinerals:minerals,isVitamins:vitamins})
      if(response.status == 200){
        alert("Status updated successfully")
      }
    }

    const getTheStatus = async ()=>{
      const response = await getshowAndHideVitamisAndMinerals()
      console.log(response,"sdfasdfasdfasdf")
      if( response.status == 200){
        setGetStatus(response.data.data)
        setMinerals(response.data.data[0].isMinerals)
        setVitamins(response.data.data[0].isVitamins)
        setLoading(false)
      }
    }
    useEffect(()=>{
      getTheStatus()
    },[])
  return (
    <>
    
   {loading?<div style={{ marginTop: "5px", textAlign:'center' }}><Spinwheel /></div>: <div className="main-content vh-100 vh_md-auto overflow-y-a">
    <div>
      <span className='d-flex'><h2 className='mx-4 mb-0'>Minerals</h2> 
      <input className='input' type="checkbox" id="switch" checked={minerals} onChange={()=>setMinerals (!minerals)}/><label for="switch" className='swith'>Toggle</label>
      {/* <input class="form-check-input" type="checkbox" checked={minerals} onChange={()=>setMinerals (!minerals)} id="flexCheckDefault"></input> */}
      </span>
<br />
      <span className='d-flex'><h2 className='mx-4 mb-0'>Vitamins</h2> 
      <input className='input' type="checkbox" id="switchtwo" checked={vitamins} onChange={()=>setVitamins(!vitamins)} /><label for="switchtwo" className='swith'>Toggle</label>
      {/* <input class="form-check-input" type="checkbox" checked={vitamins} onChange={()=>setVitamins(!vitamins)} id="flexCheckDefault"></input> */}
      </span>
    </div>
    <br />
    <button className='btn btn-second mx-4' onClick={updateStatus}>Save</button>
    </div>}
    </>
  )
}

export default HideMineralsAndVitamins

import React, { useEffect, useState } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, 
  // NavLink, Outlet, 
  useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import Spinwheel from "../../common/Spinwheel";
import ReactPaginate from "react-paginate";
import {
  adminDeleteUser,
  adminGetAllDeletedUserList,
  adminGetAllUserList,
} from "../../../utils/ApiEndpointFunctions";
// import BreadCrumb from "../../common/BreadCrumb";
import { debounce } from "lodash";
import { useSearchParams  } from 'react-router-dom';
import { calculateNextPageStart, capitalizeWords, getPlanColor } from "../../../utils/HelperFuncitons";
import moment from "moment";
export default function Users() {
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);

  // const [allIngredients, setAllIngredients] = useState([]);
  const [userStatus, setUserStatus] = useState("Active");
  const [deleteUserId, setDeleteUserId] = useState({id:"",status:true});
  const [selectedPage, setSelectedPage] = useState(1);
  const [search, setSearch] = useState("");
  const [totalCount, setTotalCount] = useState(null);
  const [page, setPage] = useState(0);
  const [filter, setFilter] = useState("all");
  // const [currentPage, setCurrentPage] = useState(1);
  let data = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  // const navigate = useNavigate();
  let allPaths = data.pathname.split('/').filter((item) => {
      return item !== "";
  });

  const limit = 50;
  let currentPage = "";

  let search1 = "";

  const handleChange = (event) => {

    if (event.target.name === "searchField") {
      setSearchParams({'page': 1})
      search1 = event.target.value;
      // search1 = search1.split(" ").join("");
      search1 = search1.trim()
      // search1 = search1.split(" ").join('');
      search1 = encodeURI(search1)
      setSearch(search1);

    }
  };
  const debouncedOnChange = debounce(handleChange, 800);

  const handlePageClick = (event) => {
    // console.log(event.selected + 1, "seleted pagination............///");
    // setCurrentPage(event.selected + 1);
    currentPage = event.selected + 1;
    // setSelectedPage(event.selected + 1);
    // if(data.pathname === '/users/deleted-users'){ 
    //   getDeletedUsers(currentPage)

    // }
    // else{
    //     getUsers(currentPage)

    //   }
    setSearchParams({'page': currentPage})
    // searchIngredients();
  };

  async function getUsers() {
    try {
      let page = searchParams.get('page')
      page = page ? page : 1
      setLoader(true);
      const res = await adminGetAllUserList(limit, page, search, filter);
      const usersData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setLoader(false);
      // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
      setUsers(res?.data?.data);
      setLoader(false);
      // if (res?.status === 200 && res?.data?.status === 1) {
      //     toast.success("User Login successfully.")
      //     localStorage.setItem("token", res?.data?.token);
      //     navigate("/dashboard")
      //     setLoader(false)
      // }
    } catch (error) {
      // console.log(error, "eroor jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  }
  async function getDeletedUsers() {
    try {
      let page = searchParams.get('page')
      page = page ? page : 1
      setLoader(true);
      const res = await adminGetAllDeletedUserList(limit, page, search, filter);
      const usersData = res?.data?.data;
      const totalLength = res?.data?.totalLength;
      setTotalCount(totalLength);
      setPage(Math.ceil(totalLength / limit));
      setUsers(usersData);
      setLoader(false);
      // console.log(res?.data?.data, "userssssssssssssssssssss.....//");
      setUsers(res?.data?.data);
      setLoader(false);
      // if (res?.status === 200 && res?.data?.status === 1) {
      //     toast.success("User Login successfully.")
      //     localStorage.setItem("token", res?.data?.token);
      //     navigate("/dashboard")
      //     setLoader(false)
      // }
    } catch (error) {
      // console.log(error, "eroor jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message);
      setLoader(false);
    }
  }

  const deleteUser = async () => {
    let docs = document.getElementById("deleteModal");
    docs.click();
    const res = await adminDeleteUser(deleteUserId);
    // console.log(res,"asdfasdfasdf")
    setLoader(true);
    if (res.status == 200) {
      alert(`${res.data.message} successfully`);
      setLoader(false);
      if(deleteUserId.status){
        getDeletedUsers()
      }else{

        getUsers()
      }
      
    }
  };

  // useEffect(() => {
    // const data = JSON.stringify({ x: 5, y: 6 });
    // console.log(data, "users effect data");
    // const data1 = JSON.parse(data);
    // console.log(data1, "users effect data1");
  // }, [search, filter]);

  // const searchUsers = async () => {
  //   // console.log(search, "serch1 from search function state...........////")

  //   try {

  //     setLoader(true);
  //     const res = await adminGetAllUserList(
  //       limit,
  //       selectedPage,
  //       search,
  //       filter
  //     );
  //     // console.log(res?.data?.data, "search inng data......////");
  //     const usersData = res?.data?.data;
  //     const totalLength = res?.data?.totalLength;
  //     setTotalCount(totalLength);
  //     setPage(Math.ceil(totalLength / limit));
  //     setUsers(usersData);
  //     setLoader(false);
  //   } catch (error) {
  //     toast.error(error.message);
  //     setLoader(false);
  //   }
  // };

  useEffect(() => {
    // if (search || filter != "all") {
      // if(search === ""){
      //   setFilter('all')
      // }
      if (search || filter) {
      // console.log("clg 1111111111111");
      // searchUsers();
      if (allPaths[1] === "active-users") {
        getUsers();
      }
      else if (allPaths[1] === "deleted-users") {
        getDeletedUsers();
      };
    }
    if(searchParams.get('page')){
      setSelectedPage(Number(searchParams.get('page')))
    }
    // if (search === "" && filter === "all") {
      
    //   getUsers();
    //   console.log("users effect");
    // }
  }, [search, searchParams.get('page'), filter]);

  // console.log("xvbjkdhfdhu gajendra");
  // useEffect(() => {
  //   if (search === "") {
  //     setFilter("all");
  //   }
  // }, [search]);


  useEffect(() => {
    if (allPaths[1] === "active-users") {
      getUsers();
      setUserStatus("Active");
    }
    
    else if (allPaths[1] === "deleted-users") {
      getDeletedUsers();
      setUserStatus("Deleted");
    };
    setFilter('all')
    setSearch('')

  }, [allPaths[1]])

  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        {/* HEADER */}
        {/* <div className="header">
          <div className="container-fluid">
           
            <div className="header-body">
              <div className="row align-items-end">
                <div className="col">
                   
                  <h6 className="header-pretitle">Overview</h6>
                   
                  <h1 className="header-title">Users</h1>
                </div>
                <div className="col-auto">
                  
                  <div className="dropdown">
                    <button
                      className="btn btn-second dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src="images/profiledp.png"
                        alt=""
                        height={24}
                        width={24}
                      />
                      Miller Davis
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <a className="dropdown-item" href="#">
                      Profile
                        </a>
                      </li>
    
                    </ul>
                  </div>
                </div>
              </div> 
             
            </div>  
          </div>
        </div>  */}
        {/* / .header */}
        {/* CARDS */}
         
          <div className="row">
           
<div className="card">
            <div className="d-flex justify-content-between flex-wrap align-items-center mt-3 mt-lg-0">
              <div className="d-flex">
                <h2 className="report" style={{marginRight:"0.7rem"}}>{userStatus} Users List</h2>
                
                <span>({totalCount})</span>
              </div>
              <div style={{ display: "-webkit-inline-box" }}>
                <div className="position-relative my-3 d-flex">
                  <input
                    className="form-search me-3"
                    type="search"
                    name="searchField"
                    id=""
                    // placeholder="Search by email"
                    placeholder="email/ username/ full name"
                    onChange={debouncedOnChange}
                    // value = {search}
                  />
                  <div className="dropdown show">
                    <div className="dropdown me-3">
                      <button
                        className="btn btn-second dropdown-toggle"
                        style={{ padding: "11px 20px" }}
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {filter ? filter?.toUpperCase() : "Filter"}
                      </button>
                      <ul
                        className="dropdown-menu"
                        style={{ marginTop: "-10px" }}
                      >
                        <li onClick={() => setFilter("all")}>
                          <Link className="dropdown-item">All Users</Link>
                        </li>
                        <li onClick={() => setFilter("personal")}>
                          <Link className="dropdown-item">Personal Users</Link>
                        </li>
                        <li onClick={() => setFilter("business")}>
                          <Link className="dropdown-item">Business Users</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                {/* <div className="dropdown">
                                <button
                                    className="btn btn-sm btn-white"
                                    type="button"
                                    data-bs-toggle="dropdown"
                                    data-bs-auto-close="outside"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <i className="fe fe-sliders me-1"></i> Filter{" "}
                                    <img src="images/chevron-down.svg" alt="" width={17} />{" "}
                                    <span className="badge bg-primary ms-1 d-none">0</span>
                                </button>

                                <form className="dropdown-menu dropdown-menu-end dropdown-menu-card">
                                    <table className="table mb-0">
                                        <tr>
                                            <td>
                                                {" "}
                                                <input type="radio" name="" id="mon" />{" "}
                                                <label htmlFor="mon"> Monthly</label>{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {" "}
                                                <input type="radio" name="" id="yer" />{" "}
                                                <label htmlFor="yer"> Yearly</label>{" "}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                {" "}
                                                <input type="radio" name="" id="day" />{" "}
                                                <label htmlFor="day"> Daily</label>{" "}
                                            </td>
                                        </tr>
                                    </table>
                                </form>

                            </div> */}
              </div>
            </div>
          <div className="table-responsive card">
            <table className="table table-md table-hover card-table">
              <thead>
                <tr>
                  <th>
                    <span className="list-sort text-muted">S.No</span>
                  </th>
                  <th>
                    <span className="list-sort text-muted">User Name</span>
                  </th>
                  <th style={{ width: "18%" }}>
                    <span className="list-sort text-muted">Email ID</span>
                  </th>
                  <th>
                    <span className="list-sort text-muted">Contact</span>
                  </th>
                  <th>
                    <span className="list-sort text-muted">Plan</span>
                  </th>
                  <th>
                    <span className="list-sort text-muted">Plan Validity</span>
                  </th>
                  <th style={{ textAlign: "end" }}>
                    <span className="list-sort text-muted">Action</span>
                  </th>
                </tr>
              </thead>

              {loader ? (
                <div style={{ marginTop: "5px", marginLeft: "700px" }}>
                  <Spinwheel />
                </div>
              ) : (
                <tbody className="list fs-base">
                  {users.length > 0 ? (
                    users?.map((user, index) => (
                      <tr>
                        {/* <td>{selectedPage > 1 ? index== 9 ? String(selectedPage) + '0' :  String(selectedPage-1) + (index + 1) : index + 1}</td> */}
                        <td>{calculateNextPageStart(selectedPage,limit,index)}</td>
                        <td>
                          {/* Avatar */}
                          <div className="avatar avatar-xs align-middle me-2">
                            <img
                              className="avatar-img rounded-circle"
                              src={
                                user?.profilePic === "" ||
                                user?.profilePic === "null"
                                  ? "/images/profiledp.png"
                                  : `https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${user?.profilePic}`
                              }
                              alt="..."
                            />
                          </div>
                          {user?.userName}
                        </td>
                        <td>{user?.email}</td>
                        <td>{user?.phoneNumber}</td>
                        <td>
                          {/* {user?.accountType === "personal" ? (
                            <span style={{ color: "green", fontWeight: "600" }}>
                              Personal
                            </span>
                          ) : user?.accountType === "business" ? (
                            <span
                              style={{ color: "purple", fontWeight: "600" }}
                            >
                              Business
                            </span>
                          ) : (
                            ""
                          )} */}
                          {<span style={{ color: getPlanColor(user?.userSubscriptions?.[0]?.planType), fontWeight: "600" }}>
                            {capitalizeWords(user?.userSubscriptions?.[0]?.planType)?.replace("_"," ")}
                          </span> }
                        </td>
                        <td>{moment(user?.userSubscriptions?.[0]?.startDate).format("ll")+" - "+moment(user?.userSubscriptions?.[0]?.expireDate).format("ll")}</td>
                        <td className="text-end">                                               
                              {/* {<buttton
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  setDeleteUserId({id:user?.id,status:true});
                                }}
                              >
                                Delete user
                              </buttton>  } */}

                              {userStatus=="Deleted" ?<div
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                className="btn btn-sm btn-success"
                                onClick={() => {
                                  setDeleteUserId({id:user?.id,status:true});
                                }}
                              >
                                Activate user
                              </div>:""}
                              {userStatus=="Active" ?<div
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                className="btn btn-sm btn-danger"
                                onClick={() => {
                                  setDeleteUserId({id:user?.id,status:false});
                                }}
                              >
                                Delete user
                              </div>:""}



                              {/* <div className="dropdown"> */}
                            {/* <a
                              className="dropdown-ellipses dropdown-toggle"
                              href="#"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              <i className="fe fe-more-vertical" />
                            </a> */}
                            {/* <div className="dropdown-menu dropdown-menu-end"> */}
                              {/* <Link to="/usersedit" className="dropdown-item">
                                Edit
                              </Link>
                              <Link to="/" className="dropdown-item">
                                Remove
                              </Link>
                              <Link
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal"
                                className="dropdown-item"
                              >
                                Block user
                              </Link>
                              <Link to="#!" className="dropdown-item">
                                Other
                              </Link> */}
                              {/* <Link to="/usersedit" className="dropdown-item">
                                Edit
                              </Link>
                              <Link to="/" className="dropdown-item">
                                Remove
                              </Link> */}
                             
                             {/* {userStatus=="Deleted" ?<div
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                className="dropdown-item"
                                onClick={() => {
                                  setDeleteUserId({id:user?.id,status:true});
                                }}
                              >
                                Activate user
                              </div>:""} */}
                              {/* {userStatus=="Active" ?<div
                                to=""
                                data-bs-toggle="modal"
                                data-bs-target="#deleteModal"
                                className="dropdown-item"
                                onClick={() => {
                                  setDeleteUserId({id:user?.id,status:false});
                                }}
                              >
                                Delete user
                              </div>:""} */}
                              {/* <Link to="#!" className="dropdown-item">
                                Other
                              </Link> */}
                            {/* </div>
                          </div> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td align="center" colspan="6">
                        {/* <h1>Oppss..!</h1>   No, user available to show. */}
                        <img
                          src="/images/noData.png"
                          style={{ width: "200px" }}
                          alt=""
                          width="50%"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              )}
            </table>
          </div>
          {totalCount > 10 ? (
        <div className="d-flex justify-content-center">
          <ReactPaginate
            nextLabel="Next >"
            onPageChange={handlePageClick}
            pageRangeDisplayed={3}
            marginPagesDisplayed={1}
            pageCount={page}
            previousLabel="< Previous"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName={"active"}
            forcePage={searchParams.get('page')? Number(searchParams.get('page'))-1 : 0}
          />
        </div>
      ) : (
        ""
      )}
        </div>
      </div>

     

      {/* block user popup code start here */}
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        aria-labelledby="deleteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-sm modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="btn-close block-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body pe-0 ps-0 block-user">
              <img src="/images/blockuser.png" alt="" width={202} srcset="" />
              <h1 className="mb-0">
                Are you sure you want to
                <br /> {deleteUserId.status?"restore":"delete"} this User
              </h1>
            </div>
            <div className="modal-footer pe-0 ps-0 border-0 justify-content-center pt-0 mb-3">
              <button
                type="button"
                className="btn-custom w-auto"
                onClick={deleteUser}
              >
                CONFIRM
              </button>
            </div>
          </div>
        </div>
      </div>
       
      </div>
    </>
  );
}

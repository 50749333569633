import { useFormik } from "formik";
import React, { useState } from "react";
import { reportInitialValue, reportSchema } from "../components/common/Validation";
import { adminGenerateReport } from "../utils/ApiEndpointFunctions";
import { ImageUrl } from "../utils/BaseUrl";
import Spinwheel from "../components/common/Spinwheel";

export default function Reports() {

  const today = new Date().toISOString().split('T')[0]
  const [loader,setLoader] = useState(false)
  const convertDate = (e)=>{
    const parts = e.split('-');
  
    // Create a Date object using the parts in the "yy", "mm", and "dd" order
    const date = new Date(`20${parts[0]}`, parts[1] - 1, parts[2]);
    
    // Extract the day, month, and year components
    const day = date.getDate();
    const month = date.getMonth() + 1; // Note: Month is zero-based, so add 1
    const year = date.getFullYear() % 100; // Get the last two digits of the year
  
    // Format the components as strings with leading zeros if needed
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedYear = year < 10 ? `0${year}` : year;
    return `${formattedMonth }-${formattedDay}-${formattedYear}`
  }
  async function handleSubmit() {
    setLoader(true)
    let { reportType, startDate, endDate } = formik.values;

    startDate = convertDate(startDate)
    endDate = convertDate(endDate)
    const { data } = await adminGenerateReport(reportType, startDate, endDate)

    if (!data.data) {
      alert(data.message)
    }
    const url = ImageUrl + "/" + data.data;
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = url;

    // Specify the file name for the downloaded file
    link.download = `users_report_${startDate}_${endDate}.csv`;

    // Trigger a click event on the link to start the download
    link.click();
    setLoader(false)
  }

  const formik = useFormik({
    initialValues:reportInitialValue,
    onSubmit:handleSubmit,
    validationSchema:reportSchema
  })

  return (
    <>
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4">
            <label className="form-label" htmlFor="type">Select Type</label>
              <select className="form-select" value={formik.values.reportType}  onChange={(e)=>formik.setFieldValue("reportType",e.target.value)}  name="reportType" id="type">
                <option value="users">Users</option>
                <option value="ingredients">Ingredients</option>
                <option value="recipes">Recipes</option>
              </select>

              {formik.touched.reportType && formik.errors.reportType ? (<div className='req-error'>{formik.errors.reportType}</div>) : null}
                                        
            </div>
            <div className="col-md-3 mt-3 mt-md-0">
                <label className="form-label" htmlFor="start">Start date</label>
                <input className="form-control" type="date" value={formik.values.startDate} name="startDate" onChange={(e)=>formik.setFieldValue("startDate",e.target.value)}id="start" placeholder="Start date" max={today}/>
                {formik.touched.startDate && formik.errors.startDate ? (<div className='req-error'>{formik.errors.startDate}</div>) : null}
            </div>
            <div className="col-md-3 mt-3 mt-md-0">
                <label className="form-label" htmlFor="end">End date</label>
            <input className="form-control" type="date" name="endDate" value={formik.values.endDate} onChange={(e)=>formik.setFieldValue("endDate",e.target.value)} id="end" placeholder="End date" max={today} />
            {formik.touched.endDate && formik.errors.endDate ? (<div className='req-error'>{formik.errors.endDate}</div>) : null}
            </div>
            <div className="col-md-2">
                <button className="btn btn-second" disabled={loader} onClick={formik.handleSubmit}style={{marginTop: "2rem"}}>{loader?<Spinwheel/>:"Submit"}</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

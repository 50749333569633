 
import React, { useEffect, useState } from "react";
import Sidebar from "../../../Layout/Sidebar";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  adminIngredientInitial,
  adminIngredientSchema,
} from "../../common/Validation";
import { useFormik, FieldArray, Formik } from "formik";
import {
  adminEditIngredient,
  adminUploadImages,
  adminGetIngredientById,
  adminGetAllProximates,
  adminAddIngredient
} from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useAdminContext } from "../../../context/adminContext";
import Spinwheel from "../../common/Spinwheel";
import { ImageUrl } from "../../../utils/BaseUrl";
import { join } from "lodash";
import BreadCrumb from "../../common/BreadCrumb";
import { array } from "yup";
import { openImageInNewTab } from "../../../utils/HelperFuncitons";

export default function CommonIngredient() {
  const { adminDetail } = useAdminContext();
  const [signInWheel, setSignInWheel] = useState(false);
  const [loader, setLoader] = useState(false);
  const [referenceImages, setReferenceImages] = useState([]);
  const [thumbnailImage, setThumbnailImage] = useState("");
  const [frontImage, setFrontImage] = useState("");
  const [backImage, setBackImage] = useState("");
  const [proximates, setProximates] = useState(null);
  const [Minerals, setMinerals] = useState([])
  // const [index, setIndex] = useState(null)
  const [ingredientDetails, setIngredientsDetails] = useState({})
  const [linkData,setLinkData] = useState()

  const navigate = useNavigate();
  const formData = new FormData();

  const imagesData = [];
  const minerals = [];
  //   let proximatesStringified = {}
  let data = useLocation();

//   console.log()

  const { id } = useParams();


  const getProximateData = async () => {

    const res = await adminGetAllProximates();
    // const proximatesData = res?.data?.data;
    // console.log(res?.data?.data, "proximatesDataproximatesData111")
    return res?.data?.data;
  }
	
 

  const handleLinks = () => {
    if(linkData?.length>0){


      essentialFormik.setFieldValue("referenceLink", [
        ...essentialFormik.values.referenceLink,
        linkData,
      ]);
    }
      setLinkData("");
    };


const removeData = (index)=>{
  let data = essentialFormik.values.referenceLink.filter((ele,ind)=> ind!==index);
  essentialFormik.setFieldValue("referenceLink", 
    data
 );

}






  const getIngredientDetails = async () => {

    try {
// console.log("we are in11111111111")
      let data = await getProximateData()
      setLoader(true)
      const res = await adminGetIngredientById(id);
      res?.data?.data?.proximates?.minerals.forEach((element, ind, array) => {

        data.minerals.find((item) => {
          if (item.name == element.name && item.unit == element.unit) {
            item.value = element.value
          }
        })
      });
      res?.data?.data?.proximates?.vitamins.forEach((element, ind, array) => {

        data.vitamins.find((item) => {
          if (item.name == element.name && item.unit == element.unit) {
            item.value = element.value
          }
        })
      });
      res?.data?.data?.proximates?.nutrients.forEach((element, ind, array) => {

        data.nutrients.find((item) => {
          if (item.name == element.name && item.unit == element.unit) {
            item.value = element.value
          }
        })
      });
      let prox = res?.data?.data;
      prox.proximates = data;
    //   console.log(res?.data?.data, "res?.data?.datares?.data?.data")
      setIngredientsDetails(res?.data?.data);
      essentialFormik.setFieldValue("name", res?.data?.data?.name);
      essentialFormik.setFieldValue("ingredientQuantity", res?.data?.data?.ingredientQuantity);
      essentialFormik.setFieldValue("ingredientUnit", res?.data?.data?.ingredientUnit);
      essentialFormik.setFieldValue("caloriePerUnit", res?.data?.data?.caloriePerUnit);
      essentialFormik.setFieldValue("brandName", res?.data?.data?.brandName);
      // essentialFormik.setFieldValue("referenceLink", res?.data?.data?.referenceLink?res?.data?.data?.referenceLink:[res?.data?.data?.referenceLink]);
      essentialFormik.setFieldValue("referenceImages", res?.data?.data?.referenceImages);
      essentialFormik.setFieldValue("frontImage", res?.data?.data?.frontImage);
      essentialFormik.setFieldValue("backImage", res?.data?.data?.backImage);
      essentialFormik.setFieldValue("thumbnailImage", res?.data?.data?.thumbnailImage);


      if(
        typeof res?.data?.data.referenceLink !== 'object'
       ){
        essentialFormik.setFieldValue("referenceLink",[res?.data?.data.referenceLink])
  
      }else{
         essentialFormik.setFieldValue("referenceLink",res?.data?.data.referenceLink)
  
       }
      // essentialFormik.setFieldValue("proximates", res?.data?.data?.proximates);
      //   essentialFormik.setFieldValue("proximates", ingredientDetails?.proximates);
      //   essentialFormik.setFieldValue("proximates", ingredientDetails?.proximates);
    //   console.log(res?.data?.data?.proximates, "proximatesDataproximatesData")


      // res?.data?.data?.proximates?.minerals


      essentialFormik.setFieldValue("proximates", data);
      // console.log(data,"elementelement")


      setLoader(false);
      
    } catch (error) {
      // console.log(error, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error.message)
      setLoader(false)
    }

  }

  const getAllProximates = async () => {
    try {
      setLoader(true);
      const res = await adminGetAllProximates();
       
      const proximatesData = res?.data?.data;
     
      
      essentialFormik.setFieldValue("proximates", proximatesData);
      setLoader(false);
      
    } catch (error) {
      
      toast.error(error.message);
      setLoader(false);
    }
  };


  const handleChange = async (event, name = null, type = null, index = null) => {
    // console.log(event.target.type, "name, Name, name, Name")
    if (event.target.type == "file") {

      // console.log(index, "index//////////////")
      const files = event.target.files;
       
      if (files.length == 0) return;
      //   const file = event.target.file; 
      //   files[`${i}`]

      for (let i = 0; i < files.length; i++) {
        // if(i > 1){
        //     break;
        // }else{ 
            let split = files[i].type.split("/")[1]
            
            if(split !== "jpg" && split !== "png" && split !== "jpeg"){
            return toast.error("File type not allowed   ")
            }
        formData.append(`file`, files[i]);
        // }
      }

      formData.append("folderName", "recipesAndIngredients");
      
      const res = await adminUploadImages(formData);

      const resImages = res?.data?.data;
      resImages?.map((image) => {
        imagesData.push(image?.data);
      });
      // } 
      if (event.target.name === "referenceImages") {
        setReferenceImages(imagesData);

        essentialFormik.setFieldValue("referenceImages", imagesData);
      }

      if (event.target.name === "thumbnailImage") {
        setThumbnailImage(imagesData[0]);
        essentialFormik.setFieldValue("thumbnailImage", imagesData[0]);
      }

      if (event.target.name === "frontImage") {
        setFrontImage(imagesData[0]);
        essentialFormik.setFieldValue("frontImage", imagesData[0]);
      }

      if (event.target.name === "backImage") {
        setBackImage(imagesData[0]);
        essentialFormik.setFieldValue("backImage", imagesData[0]);
      }

    }



    // console.log(event.target.name,'event target name')
    if (event.target.name == `${name}${index}`) {
      //  const e = proximates?.Minerals.find(element => element.name == name)

      const split = event.target.name.split(`${name}`)[1]
      // console.log(event.target.name, "event name")

      // console.log(e.name, "proxomate minerals")
      // const value = JSON.stringify(event.target.value);
      if (event.target.name === `${name}${index}`) {
        const value = parseFloat(event.target.value);
        // let obj = {name:e.name,value: value,unit:e.unit}
        // console.log(typeof value, "3456789o",value)
         let minTemp = [], vitTemp = [], nutTemp = [];
        if (type === "minirals") {
          if (value < 0 || value == null) return;
        //   let temp1 = ingredientDetails?.proximates?.minerals
           
        //   temp1[split].value = value
        //   let tempProximates1 = ingredientDetails?.proximates
        //   tempProximates1['minerals'] = temp1
        //   setIngredientsDetails({ ...ingredientDetails, proximates: tempProximates1 })
            // console.log(essentialFormik,"essentialFormikessentialFormik")

            
            
            essentialFormik.setFieldValue(`proximates.minerals.${split}.value`,value)         
            
           
        }



        if (type === "vitamins") {
          if (value < 0 || value == null) return;

        //   let temp2 = ingredientDetails?.proximates?.vitamins
        //   temp2[split].value = value
        //   let tempProximates2 = ingredientDetails?.proximates
        //   tempProximates2['vitamins'] = temp2
        //   setIngredientsDetails({ ...ingredientDetails, proximates: tempProximates2 })
        essentialFormik.setFieldValue(`proximates.vitamins.${split}.value`,value)         
          // localStorage.setItem("vitamins", vitTemp);


          // proxiData.vit = vitTemp
          // proxiData = {...proxiData.nut, ...proxiData.min, vit: vitTemp}
        }

        if (type === "nutrients") {
          if (value < 0 || value == null) return;
        //   let temp3 = ingredientDetails?.proximates?.nutrients
        //   temp3[split].value = value
        //   let tempProximates3 = ingredientDetails?.proximates
        //   tempProximates3['nutrients'] = temp3
        //   setIngredientsDetails({ ...ingredientDetails, proximates: tempProximates3 })
           essentialFormik.setFieldValue(`proximates.nutrients.${split}.value`,value)      



        }

      }
    }

  }




  useEffect(() => {
    // console.log(data.pathname,"data.pathnamedata.pathname")
    if(data.pathname == "/ingredients/approved-ingredients/add-ingredient"){
      getAllProximates();
    }else{
      getIngredientDetails()

    }

  }, [])

  //   useEffect(()=>{
  //     stringifyProximatesData()
  //   },[])
  // console.log(referenceImages, "state of Images fom post ingrdiat................///////")

  const handleSubmit = async (values) => { 
    // const formData = new FormData()
    // console.log(values, "valuse from esentialslllllllllllll.....///")
    // console.log(values);
    // console.log(essentialFormik.handleChange, "handleChange......................///////////////////");


    // const tempMin = values.proximates.minerals.map(el=>JSON.stringify(el))
    // const tempVit = values.proximates.vitamins.map(el=>JSON.stringify(el))
    // const tempNut = values.proximates.nutrients.map(el=>JSON.stringify(el))

    // let proximatesString = {
    //   minerals:tempMin,
    //   vitamins:tempVit,
    //   nutrients:tempNut
    // } 
    const payload = {
      id: id,
      name: values.name,
      ingredientQuantity: values.ingredientQuantity,
      ingredientUnit: values.ingredientUnit,
      caloriePerUnit: values.caloriePerUnit,
      referenceLink: values.referenceLink,
      referenceImages: values.referenceImages,
      frontImage: values.frontImage,
      backImage: values.backImage,
      thumbnailImage: values.thumbnailImage,
      brandName: values?.brandName,
      // proximates : ((proximates?.Minerals || proximates?.Vitamins || proximates?.Nutrients).filter((el)=>el.value != 0))
      proximates: values.proximates
    };
    try {
      let res ="";
      setLoader(true);
      if(data.pathname == "/ingredients/approved-ingredients/add-ingredient") 
    {
        res = await adminAddIngredient(payload);
    }else{
         res = await adminEditIngredient(payload);
      
    }
      //  res = await adminEditIngredient(payload);
      if (res?.status === 200 && res?.data?.status === 1) {
        toast.success(res?.data?.message);
        navigate("/ingredients/approved-ingredients");
        setLoader(false);
      }
      // else {
      //     console.log(res, "jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      //     toast.error(res?.data?.message)
      //     setResetSpinWheel(false)
      // }
    } catch (error) {
      console.log(error.response.data.message, "error.response.data, jhwdwdidfdhbchdbchdebdhcbudihccudhu");
      toast.error(error?.response?.data?.message);
      setLoader(false);
    }
  };
   
  const essentialFormik = useFormik({
    initialValues: adminIngredientInitial,
    onSubmit: handleSubmit,
    validationSchema: adminIngredientSchema,
  });

  // console.log(
  //   referenceImages,
  //   "handleChange   reference Images......................///////////////////"
  // );
  console.log(essentialFormik.values,"asdfasdfasdfas")
  
   return (
    <>
      {/* {JSON.stringify(essentialFormik?.values?.proximates?.minerals)}
    {JSON.stringify(essentialFormik?.values?.proximates?.vitamins)} {JSON.stringify(essentialFormik?.values?.proximates?.nutrients)} */}
      {/* {console.log(proximates, "comming....  proximates..................////")} */}
      {/* {console.log(Minerals, "comming....  set minirals..................////")} */}

      {/* <Sidebar /> */}
      {/* {console.log(proximateData,'testtesttest')} */}
      <div className="main-content vh-100 vh_md-auto ">
        {/* HEADER */}

        {/* / .header */}
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <BreadCrumb />
            {/* <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/ingredients/approved-ingredients">
                    <i className="fe fe-home"></i> Ingredients
                  </Link> */}
            {/* </li> */}
            {/* <li className="breadcrumb-item">
                                    <Link to="/users" className="breadcrumb-item">
                                        Pending Ingredients
                                    </Link>
                                </li> */}
            {/* <li className="breadcrumb-item active" aria-current="page">
                  Edit Ingredient
                </li>
              </ol>
            </nav> */}
            <h2 className="report  m-0">Food Label Information</h2>
            <hr style={{ marginTop: "15px" }} />
            <div className="col-lg-6">

              <form action="">
                <br />
                <label htmlFor="first1" className="form-label">
                  Ingredient Name
                </label>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="text"
                    id="first1"
                    placeholder="Ingredient Name"
                    name="name"
                    value={essentialFormik?.values?.name}
                    onChange={essentialFormik.handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />
                  {essentialFormik.touched.name &&
                    essentialFormik.errors.name ? (
                    <div className="req-error">
                      {essentialFormik.errors.name}
                    </div>
                  ) : null}
                </div>

                <br />
                <label htmlFor="first" className="form-label">
                  Ingredient Quantity
                </label>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="text"
                    id="first"
                    disabled
                    placeholder="Ingredient Quantity"
                    name="ingredientQuantity"
                    value={essentialFormik.values.ingredientQuantity}
                    onChange={essentialFormik.handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  {essentialFormik.touched.ingredientQuantity &&
                    essentialFormik.errors.ingredientQuantity ? (
                    <div className="req-error">
                      {essentialFormik.errors.ingredientQuantity}
                    </div>
                  ) : null}
                </div>
                <br />
                <label htmlFor="second" className="form-label">
                  Ingredient Unit
                </label>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="text"
                    id="second"
                    disabled
                    placeholder="Ingredient Unit"
                    name="ingredientUnit"
                    value={essentialFormik.values.ingredientUnit}
                    onChange={essentialFormik.handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  {essentialFormik.touched.ingredientUnit &&
                    essentialFormik.errors.ingredientUnit ? (
                    <div className="req-error">
                      {essentialFormik.errors.ingredientUnit}
                    </div>
                  ) : null}
                </div>
                <br />
                <label htmlFor="third" className="form-label">
                  Calorie Per Unit
                </label>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="number"
                    id="third"
                    placeholder="Calorie Per Unit"
                    name="caloriePerUnit"
                    value={essentialFormik.values.caloriePerUnit}
                    onChange={essentialFormik.handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  {essentialFormik.touched.caloriePerUnit &&
                    essentialFormik.errors.caloriePerUnit ? (
                    <div className="req-error">
                      {essentialFormik.errors.caloriePerUnit}
                    </div>
                  ) : null}
                </div>
                <br />
                <label htmlFor="seventh" className="form-label">
                Reference Brand or Brand Name
                </label>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="url"
                    id="seventh"
                    placeholder="Reference Link"
                    name="brandName"
                    value={essentialFormik.values.brandName}
                    onChange={essentialFormik.handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  {/* {essentialFormik.touched.referenceLink &&
                    essentialFormik.errors.referenceLink ? (
                    <div className="req-error">
                      {essentialFormik.errors.referenceLink}
                    </div>
                  ) : null} */}
                </div>
                <br />
                <label htmlFor="seventh" className="form-label">
                  Reference Link:
                </label>
                <div className="position-relative">
                  <input
                    className="form-control"
                    type="url"
                    id="seventh"
                    placeholder="Reference Link"
                    name="referenceLink"
                    value={linkData}
                    onChange={(e) => setLinkData(e.target.value)}
                    onBlur={essentialFormik.handleBlur}
                  />
                   <span onClick={handleLinks} className="plus-sy">
              Add 
            </span>


                  {essentialFormik.touched.referenceLink &&
                    essentialFormik.errors.referenceLink ? (
                    <div className="req-error">
                      {essentialFormik.errors.referenceLink}
                    </div>
                  ) : null}
                </div>
        <div className="d-flex flex-column mb-4">
         {
           essentialFormik?.values?.referenceLink?.map((v,index)=>{
             return <span style={{position:"relative"}} onClick={()=>removeData(index)}><span style={{position:"absolute",top:0,right:0}}>x</span><span style={{margin:"3px"}} >{v}</span></span>
           })
         }
         </div>
                <br />

                <label htmlFor="forth" className="form-label">
                  Front Image
                </label>
                <div className="position-relative mb-3">
                  <input
                    className="form-control"
                    type="file"
                    id="forth"
                    name="frontImage"
                    placeholder="Reference Link"
                    onChange={handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-paperclip input-icon  "
                    for="inputGroupFile02"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                  {essentialFormik.touched.frontImage &&
                    essentialFormik.errors.frontImage ? (
                    <div className="req-error">
                      {essentialFormik.errors.frontImage}
                    </div>
                  ) : null}
                </div>
                {essentialFormik?.values?.frontImage && essentialFormik?.values?.frontImage !== "null" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      height: "75px",
                      width: "200",
                      border: "1px solid Black",
                      background: "white",
                    }}
                  >
                    <img
                      style={{
                        marginTop: "10px",
                        marginLeft: "5px",
                        height: "50px",
                        width: "50px",
                      }}
                      alt="image"
                      src={`https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${essentialFormik?.values?.frontImage}`}
                    />
                  </div>
                )}

                <br />

                <label htmlFor="forth" className="form-label">
                  Back Image
                </label>
                <div className="position-relative mb-3">
                  <input
                    className="form-control"
                    type="file"
                    id="forth"
                    name="backImage"
                    placeholder="Reference Link"
                    onChange={handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-paperclip input-icon  "
                    for="inputGroupFile02"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                  {essentialFormik.touched.backImage &&
                    essentialFormik.errors.backImage ? (
                    <div className="req-error">
                      {essentialFormik.errors.backImage}
                    </div>
                  ) : null}
                </div>
                {essentialFormik?.values?.backImage && essentialFormik?.values?.backImage!=="null" && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      height: "75px",
                      width: "200",
                      border: "1px solid Black",
                      background: "white",
                    }}
                  >
                    <img
                      style={{
                        marginTop: "10px",
                        marginLeft: "5px",
                        height: "50px",
                        width: "50px",
                      }}
                      alt="image"
                      src={`https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${essentialFormik?.values?.backImage}`}
                    />
                  </div>
                )}

                <br />

                <label htmlFor="forth" className="form-label">
                  Thumbnail Image
                </label>
                <div className="position-relative mb-3">
                  <input
                    className="form-control"
                    type="file"
                    id="forth"
                    // value={JSON.stringify(essentialFormik?.values?.thumbnailImage)}
                    name="thumbnailImage"
                    placeholder="Reference Link"
                    onChange={handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-paperclip input-icon  "
                    for="inputGroupFile02"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                  {essentialFormik.touched.thumbnailImage &&
                    essentialFormik.errors.thumbnailImage ? (
                    <div className="req-error">
                      {essentialFormik.errors.thumbnailImage}
                    </div>
                  ) : null}
                </div>
                {essentialFormik?.values?.thumbnailImage && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      height: "75px",
                      width: "200",
                      border: "1px solid Black",
                      background: "white",
                    }}
                  >
                    <img
                      style={{
                        marginTop: "10px",
                        marginLeft: "5px",
                        height: "50px",
                        width: "50px",
                      }}
                      alt="image"
                      src={`https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${essentialFormik?.values?.thumbnailImage}`}
                    />
                  </div>
                )}

                {/* <div className="d-flex justify-content-between align-items-center">
                                    <div className="position-relative">
                                        {<img src={essentialFormik.values?.logo ? ImageUrl + essentialFormik.values?.logo : process.env.PUBLIC_URL + "/images/logo.png"} width="44px" height="44px" alt="Selected file" />}

                                    </div>
                                   
                                   
                                   
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        
                                        </svg>
                                    </div>
                                </div> */}
                <br />
                <label htmlFor="fifth" className="form-label">
                  Reference Images
                </label>
                <div className="position-relative">
                  <input
                    className="form-control mb-3"
                    multiple
                    accept="image/png, image/jpeg"
                    type="file"
                    id="fifth"
                    placeholder="Reference Images"
                    name="referenceImages"
                    onChange={handleChange}
                    onBlur={essentialFormik.handleBlur}
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-paperclip input-icon  "
                    for="inputGroupFile02"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                  </svg>
                  {essentialFormik.touched.referenceImages &&
                    essentialFormik.errors.referenceImages ? (
                    <div className="req-error">
                      {essentialFormik.errors.referenceImages}
                    </div>
                  ) : null}
                </div>
                {essentialFormik?.values?.referenceImages.length >= 1 ? (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "20px",
                      height: "75px",
                      width: "200",
                      border: "1px solid Black",
                      background: "white",
                    }}
                  >
                    {essentialFormik?.values?.referenceImages?.map((image) => (
                      <img
                        style={{
                          marginTop: "10px",
                          marginLeft: "5px",
                          height: "50px",
                          width: "50px",
                        }}
                        alt="image"
                        src={`https://calorie-finder.s3.eu-west-2.amazonaws.com/calorie/${image}`}
                      />
                    ))}
                  </div>
                ) : null}

                {/* <div className="d-flex justify-content-between align-items-center">
                                    <div className="position-relative">
                                        {<img src={essentialFormik.values?.logo ? ImageUrl + essentialFormik.values?.logo : process.env.PUBLIC_URL + "/images/logo.png"} width="44px" height="44px" alt="Selected file" />}

                                    </div>
                                    <div className="">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
                                        </svg>
                                    </div>
                                </div>
                                <br />
                                <label htmlFor="first" className="form-label">Refrence/proof (paste link here)</label>
                                <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="proof" name="phone" value={essentialFormik.values.phone} onChange={essentialFormik.handleChange} onBlur={essentialFormik.handleBlur} />

                                    {essentialFormik.touched.phone && essentialFormik.errors.phone ? (<div className='req-error'>{essentialFormik.errors.phone}</div>) : null}
                                </div>
                                <br />
                                <label htmlFor="first" className="form-label">Notes</label>
                                <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="Lorem" name="phone" value={essentialFormik.values.phone} onChange={essentialFormik.handleChange} onBlur={essentialFormik.handleBlur} />

                                    {essentialFormik.touched.phone && essentialFormik.errors.phone ? (<div className='req-error'>{essentialFormik.errors.phone}</div>) : null}
                                </div>
                                <br />
                                <label htmlFor="first" className="form-label">Source code</label>
                                <div className="position-relative"><input className="form-control" type="text" id="first" placeholder="Lorem" name="phone" value={essentialFormik.values.phone} onChange={essentialFormik.handleChange} onBlur={essentialFormik.handleBlur} />

                                    {essentialFormik.touched.phone && essentialFormik.errors.phone ? (<div className='req-error'>{essentialFormik.errors.phone}</div>) : null}
                                </div>
                                <br />
                                <label htmlFor="first" className="form-label">Food group</label>
                                <div className="input-group mb-3">

                                    <select className="form-select" id="inputGroupSelect01">
                                        <option selected>Lorem</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </select>
                                </div> */}


                {essentialFormik.touched.proximates &&
                  essentialFormik.errors.proximates ? (
                  <div className="req-error">
                    {essentialFormik.errors.proximates}
                  </div>
                ) : null}


              </form>
              <br />
            </div>

            <div className="col-lg-6">
              <h4 className="mt-3">Proximaties</h4>
              <div className="proximaties">
                <u>Nutrients</u>
                {essentialFormik?.values?.proximates?.nutrients?.map((nutrient, index) => (
                  <div className="row" key={index}>

                    <div className="col-4 mt-2">
                      <label htmlFor="name">{nutrient?.name}</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        disabled
                      /> */}
                    </div>

                    <div className="col-4 mt-2">
                      {/* <label htmlFor="name">Value</label> */}
                      <input
                        type="number"
                        step=".01"
                        className="form-control"
                        name={`${nutrient?.name}${index}`}
                        id=""
                        value={nutrient?.value}
                        onChange={(e) => handleChange(e, `${nutrient?.name}`, "nutrients", index)}
                        onBlur={essentialFormik.handleBlur}
                      // onClick={()=>{setIndex(index)}}
                      />
                    </div>

                    <div className="col-4 mt-2">
                      <label htmlFor="name">{nutrient?.unit}</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        disabled
                      /> */}
                    </div>
                  </div>
                ))}
              </div>

              <div className="proximaties">
                <u>Vitamins</u>
                {essentialFormik?.values?.proximates?.vitamins?.map((vitamin, index) => (
                  <div className="row" key={index}>

                    <div className="col-4 mt-2">
                      <label htmlFor="name">{vitamin?.name}</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        disabled
                      /> */}
                    </div>

                    <div className="col-4 mt-2">
                      {/* <label htmlFor="name">Value</label> */}
                      <input
                        type="number"
                        step=".01"
                        className="form-control"
                        name={`${vitamin?.name}${index}`}
                        id=""
                        value={vitamin?.value}
                        onChange={(e) => handleChange(e, `${vitamin?.name}`, `vitamins`, index)}
                        onBlur={essentialFormik.handleBlur}
                      // onClick={()=>{setIndex(index)}}
                      />
                    </div>

                    <div className="col-4 mt-2">
                      <label htmlFor="name">{vitamin?.unit}</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        disabled
                      /> */}
                    </div>
                  </div>
                ))}
              </div>

              <div className="proximaties">
                <u>Minerals</u>
                {essentialFormik?.values?.proximates?.minerals?.map((mineral, index) => (
                  <div className="row" key={index}>

                    <div className="col-4 mt-2">
                      <label htmlFor="name">{mineral?.name}</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        disabled
                        placeholder={mineral?.name}
                      /> */}
                    </div>

                    <div className="col-4 mt-2">
                      {/* <label htmlFor="name">Value</label> */}
                      <input
                        type="number"
                        className="form-control"
                        step=".01"
                        name={`${mineral?.name}${index}`}
                        id=""
                        value={mineral?.value}
                        onChange={(e) => handleChange(e, `${mineral?.name}`, "minirals", index)}
                        onBlur={essentialFormik.handleBlur}
                      // onClick={()=>{setIndex(index)}}
                      />
                    </div>

                    <div className="col-4 mt-2">
                      <label htmlFor="name">{mineral?.unit}</label>
                      {/* <input
                        type="text"
                        className="form-control"
                        name=""
                        id=""
                        disabled
                        placeholder={mineral?.unit}
                      /> */}
                    </div>

                    {/* </div> */}
                    {/* })} */}
                  </div>
                ))}
              </div>
            </div>
            <div className="d-flex justify-content-end mt-1 mb-5">
              <button
                onClick={ essentialFormik.handleSubmit}
                type="submit"
                className="btn-custom w-auto mt-2"
              >
                {signInWheel ? (
                  <Spinwheel sizeclassName="spin-small" />
                ) : (
                  (data.pathname == "/ingredients/approved-ingredients/add-ingredient") 
    ?"Add Ingredient" :
                  "Update Ingredient"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

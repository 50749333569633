import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { forgetPasswordSchema, forgetPasswordIntial } from "../../common/Validation"
import { forgetPassword, variedOtp } from "../../../utils/ApiEndpointFunctions";
import toast from "react-hot-toast";
import { useState } from "react";
import Spinwheel from "../../common/Spinwheel";
import { debounce } from "lodash";

const Forgotpassword = () => {

    const [confirmSpin, setConfirmSpin] = useState(false)
    const [activeOtp, setActiveOtp] = useState(false)
    const [otpSpin, setOtpSpin] = useState(false);
    const navigate = useNavigate();
    const sentOtpHandler = async (e) => {
        e.preventDefault();
        let { email } = formik.values
        if (!formik.errors.email) {
            setOtpSpin(true)
            const res = await forgetPassword({ email })
            if (res.status === 200 && res.data?.status === 1) {
                toast.success("Forget password Otp sent on mail")
                setActiveOtp(true)
                setOtpSpin(false)
            } else {
                toast.error(res.data?.message)
                setOtpSpin(false)
            }
        }
    }
    const sendAgainOtp = debounce(sentOtpHandler,500)
    const handleSubmit = async (values) => {
        setConfirmSpin(true)
        try {
            const res = await variedOtp(values)
            if (res.status === 200) {
                toast.success(res.data?.message)
                setConfirmSpin(false)
                navigate("/resetpassword", { state: res.data?.data })
            }
        } catch {
            toast.error("incorrect OTP")
            setConfirmSpin(false)
        }
    }

    const formik = useFormik({
        initialValues: forgetPasswordIntial,
        onSubmit: handleSubmit,
        validationSchema: forgetPasswordSchema
    });
    document.body.classList.add("body-bg");
    return (
        <>
            <main>
                <section className="section-bg">
                    <div className="container-fluid">
                        <div className="row top-bg">
                            <img
                                className="left-mask-bg d-none d-xl-block"
                                src="images/Mask Group 650.png"
                                alt=""
                            />
                            <img
                                className="right-mask-bg d-none d-xl-block"
                                src="images/Mask Group 650.png"
                                alt=""
                            />
                            <div className="d-flex justify-content-center">
                                <img
                                    className="bottom-mask-bg d-none d-xl-block"
                                    src="images/Mask Group 650.png"
                                    alt=""
                                />
                            </div>
                            <div className="col-xl-3 col-lg-5 col-md-7 col-sm-12 mx-auto">
                                <div className="d-grid text-center">
                                    {/* <p>rajeevkumar</p> */}
                                    <div className="d-flex justify-content-center">
                                        <img
                                            src="images/logo.png"
                                            alt="logo"
                                            height={104}
                                            width={104}
                                        />
                                    </div>
                                    <br />
                                    <br />
                                    <h2 className="sign-in">Forgot Password</h2>
                                    <p>
                                        Enter the email address associated with <br /> your account.
                                    </p>
                                    <br />
                                    <form action onSubmit={formik.handleSubmit}>
                                        <label
                                            className="form-label text-start d-block"
                                            htmlFor="email"
                                        >
                                            Email address
                                        </label>
                                        <div className="position-relative">
                                            <input
                                                className="form-control"
                                                type="email"
                                                name="email"
                                                id="email"
                                                placeholder="Enter your email"
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-envelope-fill input-icon"
                                                viewBox="0 0 16 16"
                                            >
                                                <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
                                            </svg>
                                            {formik.touched.email && formik.errors.email ? (<div className='req-error'>{formik.errors.email}</div>) : null}
                                        </div>
                                        <br />
                                        <div className={`${activeOtp ? '' : 'd-none'}`}>
                                            <label
                                                className="form-label text-start d-block"
                                                htmlFor="pass"
                                            >
                                                OTP
                                            </label>
                                            <input
                                                className="form-control"
                                                type="text"
                                                name="otp"
                                                id="pass"
                                                placeholder="Enter OTP here.."
                                                value={formik.values.otp}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                            />
                                            <br />
                                        </div>

                                        {formik.touched.otp && formik.errors.otp ? (<div className='req-error'>{formik.errors.otp}</div>) : null}
                                        <div className={`text-end ${activeOtp ? '' : 'd-none'}`}>
                                            <div className="text-decoration-underline" style={{ color: "#9B7343", cursor: "pointer" }} onClick={sendAgainOtp} >
                                                Send Again
                                            </div>
                                        </div>
                                        <br />
                                        <br />
                                        {
                                            activeOtp ?
                                                <button type="submit" className="btn-custom" disabled={confirmSpin} >{confirmSpin ? <Spinwheel sizeClass="spin-small" /> : "CONFIRM"}</button>
                                                : <button onClick={sentOtpHandler} className="btn-custom" disabled={otpSpin} > {otpSpin ? <Spinwheel sizeClass="spin-small" /> : "SEND OTP"}</button>
                                        }
                                        <br />
                                        <br />
                                        <Link to="/" className="forgot-back">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={16}
                                                height={16}
                                                fill="currentColor"
                                                className="bi bi-arrow-left"
                                                viewBox="0 0 16 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                                                />
                                            </svg>{" "}
                                            Back to log in
                                        </Link>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Forgotpassword;

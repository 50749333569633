import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link, useNavigate, NavLink, Outlet } from "react-router-dom";
import { useAdminContext } from "../../../context/adminContext";
import Sidebar from "../../../Layout/Sidebar";
import { ImageUrl } from "../../../utils/BaseUrl";
import Spinwheel from "../../common/Spinwheel";
import AllIngredients from "./AllIngredients";
import PendingIngredients from "./PendingIngredients";
import toast from "react-hot-toast";
import { adminGetAllIngredients, adminGetDashboardData } from "../../../utils/ApiEndpointFunctions";
import BreadCrumb from "../../common/BreadCrumb";

const Ingredients = () => {
  const { adminDetail } = useAdminContext();
  const [allEssential, setAllEssential] = useState(null);
  const [removedata, setRemoveData] = useState(null);
  const [essentialSpin, setEssentialSpin] = useState(true);
  const [selectedPage, setSelectPage] = useState(1);
  const [dashboardData,setDashboardData] = useState()
  const navigate = useNavigate();
  const limit = 10;



  const getUsers = async () => {
     try{
 
            //  setLoader(true)
             const res = await adminGetDashboardData();
             console.log(res?.data, "dashBoard data serssssssssssssssssssss.....//");
             setDashboardData(res?.data);
            //  setLoader(false)
             // if (res?.status === 200 && res?.data?.status === 1) {
             //     toast.success("User Login successfully.")
             //     localStorage.setItem("token", res?.data?.token);
             //     navigate("/dashboard")
             //     setLoader(false)
             // } 
            
         }catch(error){
             console.log(error, "eroor jhwdwdidfdhbchdbchdebdhcbudihccudhu");
             toast.error(error.message)
            //  setLoader(false)
         }
     
     }
 
 
     useEffect(()=>{
         getUsers();
     }, [])
 
  return (
    <>
      {/* <Sidebar /> */}
      <div className="main-content">
        {/* HEADER */}
        {/* <div className="header bg-white">
          <div className="container-fluid">
           
            <div className="header-body ">
              <div className="row align-items-end">
                <div className="col">
                  
                  <h6 className="header-pretitle">Overview</h6>
                   
                  <h1 className="header-title">Ingredients</h1>
                </div>
                <div className="col-auto">
                  
                  <div className="dropdown">
                    <button
                      className="btn btn-second dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={`${
                          adminDetail?.profileImage
                            ? ImageUrl + adminDetail?.profileImage
                            : process.env.PUBLIC_URL +
                              "/images/person-circle.svg"
                        }`}
                        alt=""
                        height={24}
                        width={24}
                        className="rounded-circle me-2"
                      />
                      {adminDetail?.name}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to={"/profiledetails"}>
                          Profile
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> 
               
            </div>
           
          </div>
        </div>{" "} */}
        {/* / .header */}
        {/* CARDS */}
        <div className="container-fluid">
          <div className="row">
            <BreadCrumb/>
            {/* <nav className="mb-5">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">
                    <i className="fe fe-home"></i> Dashboard
                  </Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Ingredients
                </li>
              </ol>
            </nav> */}
            <div className="d-flex justify-content-between  flex-wrap">
              <ul
                className="nav nav-pills mb-3"
                id="pills-tab"
                role="tablist"
                style={{ zIndex: "1" }}
              >
                <NavLink
                  className="nav-link"
                  exact
                  activeClassName="active" // Add the active class here
                  to="/ingredients/approved-ingredients"
                >
                  Approved Ingredients ({dashboardData?.TotalApprovedIngredients})
                </NavLink>
                        
                <NavLink
                  className="nav-link"
                  exact
                  activeClassName="active"
                  to="/ingredients/pending-ingredients"
                >
                  Pending Ingredients ({dashboardData?.TotalPendingIngredients})
                </NavLink>

                <NavLink
                  className="nav-link"
                  exact
                  activeClassName="active"
                  to="/ingredients/rejected-ingredients"
                >
                  Reject Ingredients  ({dashboardData?.TotalRejectedIngredients})
                </NavLink>
              </ul>
              <div style={{ display: "-webkit-inline-box" }}>
                {/* <button type='button' className='btn btn-second me-3' style={{ padding: "11px 15px" }} onClick={() => { navigate("/addessential") }}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-pencil-square " viewBox="0 0 16 16">
                    <path d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z" />
                    <path fill-rule="evenodd" d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z" />
                  </svg>
                </button> */}
                {/* <div className="position-relative">
                  <input
                    className="form-search me-3 "
                    type="text"
                    name="searchField"
                    id=""
                    placeholder="Search"
                    onChange={handleChange}
                  />
                  <img
                    className="users-search h2"
                    src="images/Icon feather-search.svg"
                    alt=""
                  />
                </div> */}
                {/* <div className="dropdown show">
                  <div className="dropdown me-3"><button className="btn btn-second dropdown-toggle" style={{ padding: "11px 20px" }} type="button" data-bs-toggle="dropdown" aria-expanded="false">Filter</button>
                    <ul className="dropdown-menu" style={{ marginTop: "-10px" }}>
                      <li><Link className="dropdown-item"  >Locum </Link></li>
                      <li><Link className="dropdown-item" >Locum</Link></li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <hr /> */}

            <Outlet />
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div
        className="modal fade"
        id="modalforall"
        tabIndex="-1"
        aria-labelledby="modalforallLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog " style={{ width: "390px" }}>
          <div className="modal-content " style={{ borderRadius: "0px" }}>
            <div className="modal-header-list">
              <h1 className="modal-title-list  mb-0 py-3 " id="modalforallLabel">
                Nutritional Information
              </h1>
            </div>
            <div className="modal-body pb-0">
              <div className=" heading d-flex justify-content-around align-items-center">
                <h6>Nutrition</h6>
                <h6>Per 100g</h6>
              </div>
              <hr className="m-0 bottom-line" />
              <div className="row pt-3">
                <div className="col-2 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-escape"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                    <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                  </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-escape"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                    <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                  </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Fat of which</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">g</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-escape"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                    <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                  </svg>
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Proteint</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">g</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <hr className="my-2 bottom-line" />
              <div className="row">
                <div className="col-2 text-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> */}
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">Kcal</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> */}
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">g</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <hr className="my-2 bottom-line" />
              <div className="row">
                <div className="col-2 text-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> */}
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">Kcal</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> */}
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> */}
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
              <div className="row">
                <div className="col-2 text-center">
                  {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-escape" viewBox="0 0 16 16">
                                  <path d="M8.538 1.02a.5.5 0 1 0-.076.998 6 6 0 1 1-6.445 6.444.5.5 0 0 0-.997.076A7 7 0 1 0 8.538 1.02Z" />
                                  <path d="M7.096 7.828a.5.5 0 0 0 .707-.707L2.707 2.025h2.768a.5.5 0 1 0 0-1H1.5a.5.5 0 0 0-.5.5V5.5a.5.5 0 0 0 1 0V2.732l5.096 5.096Z" />
                                </svg> */}
                </div>
                <div className="col-3">
                  <h6 className="text-center fs-5 fw-bold">Energy</h6>
                </div>
                <div className="col-2">
                  <p className="fs-5">KJ</p>
                </div>
                <div className="col-5">
                  <h6 className="text-center fs-5 fw-bold">295</h6>
                </div>
              </div>
            </div>
            <p className="p-3 text-dark m-0 card-bottom">
              * Present daily values are based on a 2,000 calorie diet. Your
              daily values may be higher or lower depanding on your Energy
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ingredients;
